import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';

interface Location {
  latitude: number;
  longitude: number;
  address?: string;
}

@Component({
  selector: 'app-location-input',
  templateUrl: './location-input.component.html',
  styleUrls: ['./location-input.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: LocationInputComponent
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: LocationInputComponent
    }
  ],
})
export class LocationInputComponent implements OnInit, AfterViewInit, ControlValueAccessor, Validator {

  center: google.maps.LatLngLiteral = {lat:9.016274526428973, lng: 38.79074495617295};
  zoom = 12;
  selectedLocation: Location | null = null;
  mapVisible = false;
  @ViewChild('locationAutocomplete') placeInput!: ElementRef<HTMLInputElement>;
  geocoder = new google.maps.Geocoder();
  private placeAutocomplete!: google.maps.places.Autocomplete;

  onChange = (location: any) => {}
  onTouched = () => {}
  disabled = false;
  touched = false;

  constructor() { }

  ngOnInit(): void {
  }
  
  ngAfterViewInit(): void {
    this.placeInput.nativeElement.addEventListener('input', () => {
      this.markAsTouched();
    })
    this.placeAutocomplete = new google.maps.places.Autocomplete(this.placeInput.nativeElement, {
      componentRestrictions: { country: 'et' }
    });
    this.placeAutocomplete.setFields(['geometry', 'formatted_address']);
    this.placeAutocomplete.addListener('place_changed', () => {
      const place = this.placeAutocomplete.getPlace();
      this.selectedLocation = {
        address: place.formatted_address,
        latitude: place.geometry!.location!.lat(),
        longitude: place.geometry!.location!.lng(),
      };
      this.onChange(this.selectedLocation)
    });
  }

  selectLocation(event: google.maps.MapMouseEvent) {
    this.markAsTouched();
    this.center = event.latLng!.toJSON();
    // this.selectedLocation = {
    //   latitude: this.center.lat,
    //   longitude: this.center.lng,
    //   address: '',
    // };
    this.geocoder.geocode({ location: this.center }).then(response => {
      // console.log({response})
      if (response.results[0]) {
        this.selectedLocation = {
          latitude: this.center.lat,
          longitude: this.center.lng,
          address: response.results[0].formatted_address,
        }
        this.onChange(this.selectedLocation)
        // this.selectedLocation!.address = response.results[0].formatted_address;
      }
    })
  }

  writeValue(location: any): void {
    if (location.latitude && location.longitude) {
      this.selectedLocation = {
        latitude: location.latitude,
        longitude: location.longitude,
        address: location.address || ''
      }
    }
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
  validate(control: AbstractControl<any, any>): ValidationErrors | null {
    const location = control.value;
    if (location && location.latitude && location.longitude) {
      return null
    }
    return {
      invalidLocation: {
        location
      }
    };
  }
  // registerOnValidatorChange?(fn: () => void): void {
  //   throw new Error('Method not implemented.');
  // }


  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }
}
