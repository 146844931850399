
<nz-page-header *ngIf="!fullscreen"
    class="site-page-header"
    [nzBackIcon]="icon"
    [nzTitle]="title"
    [nzSubtitle]="subtitle">

  <!-- <nz-page-header-content> -->
    <!-- <ul  nz-menu nzMode="horizontal">
      <li nz-menu-item nzSelected>
        <span nz-icon nzType="mail"></span>
        Navigation One
      </li>
      <li nz-menu-item nzDisabled>
        <span nz-icon nzType="appstore"></span>
        Navigation Two
      </li>
  </ul> -->
  <!-- </nz-page-header-content> -->

    <nz-page-header-extra *ngIf="isLoggedIn()">

        <nz-space>
            <!-- <button *nzSpaceItem nz-button title="Search" nzType="link">
                <span nz-icon nzType="search"></span>
            </button> -->
            <nz-badge *nzSpaceItem [nzCount]="5">
                <button nz-button title="Notification" nzType="link" class="ant-btn-icon-only" routerLink="notifications">
                    <span nz-icon nzType="bell"></span>
                </button>
            </nz-badge>
            <button *nzSpaceItem
              nz-button nzType="link" nzNoAnimation
              nz-dropdown
              [nzDropdownMenu]="menu"
              nzPlacement="bottomRight">
                <img
                    src="../assets/profile-picture-placeholder.svg"
                    alt="Profile picture"
                    width="24px"
                    height="24px"
                    style="border-radius: 50%; margin-right: 8px;"
                    [src]="getUser().image">
                {{ getUser().name }}
            </button>
        </nz-space>
        <nz-dropdown-menu #menu="nzDropdownMenu">
          <ul nz-menu>
            <li nz-menu-item routerLink="/profile">
              <span nz-icon nzType="edit" style="margin-right: 10px"></span>
              Edit Profile
            </li>
            <li nz-menu-item (click)="logout()">
              <span nz-icon nzType="arrow-right" style="margin-right: 10px"></span>
              Logout
            </li>
          </ul>
        </nz-dropdown-menu>
    </nz-page-header-extra>
</nz-page-header>
<ng-template #title>
    <span style="color: white;">Shop Admin</span>
</ng-template>
<ng-template #subtitle>
  <ul nz-menu nzMode="horizontal" class="menu">
    <li nz-menu-item routerLink="products" [nzMatchRouter]="true">
      <!-- <span nz-icon nzType="mail"></span> -->
      Products
    </li>
    <ul nz-menu-item routerLink="orders" [nzMatchRouter]="true">
      <li nz-submenu nzTitle="Orders">
        <ul>
          <li nz-menu-item routerLink="order-stats" [nzMatchRouter]="true">
            Order Stats
          </li>
          <li nz-menu-item routerLink="delivery-prices" [nzMatchRouter]="true">
            Delivery Prices
          </li>
        </ul>
      </li>
    </ul>
    <li nz-menu-item routerLink="customers" [nzMatchRouter]="true">
      Customers
    </li>
    <li nz-menu-item routerLink="users" [nzMatchRouter]="true">
      Users
    </li>
    <li nz-menu-item routerLink="resources" [nzMatchRouter]="true">
      Resources
    </li>
    <li nz-menu-item routerLink="branches" [nzMatchRouter]="true">
      Branches
    </li>
    <li nz-menu-item routerLink="settings" [nzMatchRouter]="true">
      Settings
    </li>
  </ul>
</ng-template>
<ng-template #icon>
    <img src="../assets/logo.svg" alt="logo" nz-icon/>
</ng-template>

<ng-template let-order="data" #newOrder>
  <div class="ant-notification-notice-message">
    New Order
  </div>
  <div class="ant-notification-notice-description">
    Order number '<a routerLink="/orders">{{order.code}}</a>' received from customer {{ order.customer.name }}!
  </div>
</ng-template>

<ng-template let-order="data" #editedOrder>
  <div class="ant-notification-notice-message">
    Order Edited
  </div>
  <div class="ant-notification-notice-description">
    Order number '<a routerLink="/orders">{{order.code}}</a>' has been edited.
  </div>
</ng-template>

<ng-template let-order="data" #cancelledOrder>
  <div class="ant-notification-notice-message">
    Order Cancelled
  </div>
  <div class="ant-notification-notice-description">
    Order number '<a routerLink="/orders">{{order.code}}</a>' has been cancelled.
  </div>
</ng-template>

<ng-template let-product="data" #depletedProduct>
  <div class="ant-notification-notice-message">
    Out of Stock Request
  </div>
  <div class="ant-notification-notice-description">
    {{product.requestedQuantity}} '<a routerLink="/products">{{product.product.name}}</a>' out of stock request by {{product.customer.name}}.
    <!-- Order number '<a routerLink="/orders">{{product.name}}</a>' has been cancelled. -->
  </div>
</ng-template>

<ng-template let-customer="data" #newCustomer>
  <div class="ant-notification-notice-message">
    New Customer
  </div>
  <div class="ant-notification-notice-description">
    New customer '<a routerLink="/customers">{{ customer.name }}</a>' registered!
  </div>
</ng-template>

<ng-template let-order="data" #slipAttached>
  <div class="ant-notification-notice-message">
    Slip Attached
  </div>
  <div class="ant-notification-notice-description">
    Order number '<a routerLink="/orders">{{order.code}}</a>' has new slip attached.
  </div>
</ng-template>

<router-outlet></router-outlet>
