export interface Branch {
    id: number;
    type: BranchType;
    name: string;
    description: string;
    phoneNumbers: string[];
    location: Location;
    employees: Employee[];
    createdAt: string;
}

export interface  Employee {
    name: string;
    position: string;
    photo?: string;
    description?: string;
}

export interface Location {
    latitude: number;
    longitude: number;
    address?: string;
}

export enum BranchType {
    SHOWROOM = "SHOWROOM",
    SHOP = "SHOP",
    WAREHOUSE = "WAREHOUSE",
}