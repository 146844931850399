<form nz-form [formGroup]="form" nzLayout="vertical" (ngSubmit)="onSubmit()">
    <div style="padding: 24px 24px 0px;">
        <nz-form-item>
            <nz-form-label nzRequired>Name</nz-form-label>
            <nz-form-control nzHasFeedback [nzErrorTip]="nameError">
                <nz-input-group>
                    <input nz-input formControlName="name" name="name" type="text" placeholder="Name">
                </nz-input-group>
                <ng-template #nameError let-control>
                    <ng-container *ngIf="control.hasError('required')">Please input name!</ng-container>
                </ng-template>
            </nz-form-control>
        </nz-form-item> 
        <nz-form-item>
            <nz-form-label nzRequired>Type</nz-form-label>
            <nz-form-control>
                <nz-select formControlName="type" nzPlaceHolder="Type" >
                    <nz-option *ngFor="let type of types" 
                        [nzValue]="type" 
                        [nzLabel]="getTypeLabel(type) | titlecase">
                    </nz-option>
                </nz-select>
            </nz-form-control>
        </nz-form-item>
        <!-- phone numbers -->
        <div formArrayName="phoneNumbers" class="ant-form-item">
            <nz-form-label>Phone Numbers</nz-form-label>
            <div *ngFor="let phoneNumberFormControl of phoneNumbers.controls; let i = index" style="display: flex; justify-content: space-between;">
                <nz-form-item style="flex-grow: 1; margin-right: 20px;">
                    <nz-form-control [nzErrorTip]="phoneError">
                        <input nz-input [formControl]="phoneNumberFormControl" name="PhoneNumber" type="text" pattern="\+?[0-9]{10,12}" placeholder="Phone Number"/>
                        <ng-template #phoneError let-control>
                            <ng-container *ngIf="control.hasError('required')">Please input phone Number</ng-container>
                            <ng-container *ngIf="control.hasError('minlength')">Phone Number must be at least 10 characters!</ng-container>
                        </ng-template>
                    </nz-form-control>
                </nz-form-item> 
                <button nz-button type="button" (click)="removePhoneNumber(i)"><span nz-icon nzType="minus"></span></button>
            </div>
            <div>
                <button nz-button type="button" (click)="addPhoneNumber()">
                    <span nz-icon nzType="plus"> </span>Add Phone
                </button>
            </div>
            
        </div>
        <!-- location -->
        <app-location-input formControlName="location"></app-location-input>
        <!-- employees -->
        <div formArrayName="employees" class="ant-form-item">
            <nz-form-label>Employees</nz-form-label>
            <div class="employee-form" *ngFor="let employeeForm of employees.controls; let i = index">
                <button type="button" nz-button nzType="link" nzDanger (click)="removeEmployee(i)"><span nz-icon nzType="close"></span></button> 
                <div class="ant-form-item-label"> Employee {{i + 1}}</div>
                <div [formGroup]="employeeForm">
                    <nz-form-item>
                        <nz-form-control [nzErrorTip]="employeeNameError">
                            <input nz-input formControlName="name" name="Name" type="text" placeholder="Name"/>
                            <ng-template #employeeNameError let-control>
                                <ng-container *ngIf="control.hasError('required')">Please input name</ng-container>
                                <ng-container *ngIf="control.hasError('minlength')">Name must be at least 5 characters!</ng-container>
                            </ng-template>
                        </nz-form-control>
                    </nz-form-item> 
                    <nz-form-item>
                        <nz-form-control [nzErrorTip]="employeePositionError">
                            <input nz-input formControlName="position" name="Position" type="text" placeholder="Position"/>
                            <ng-template #employeePositionError let-control>
                                <ng-container *ngIf="control.hasError('required')">Please input job position</ng-container>
                                <ng-container *ngIf="control.hasError('minlength')">Position must be at least 2 characters!</ng-container>
                            </ng-template>
                        </nz-form-control>
                    </nz-form-item> 
                    <nz-form-item>
                        <nz-form-control [nzErrorTip]="employeeDescriptionError">
                            <input nz-input formControlName="description" name="Description" type="text" placeholder="Description"/>
                            <ng-template #employeeDescriptionError let-control>
                                <ng-container *ngIf="control.hasError('required')">Please input description</ng-container>
                                <ng-container *ngIf="control.hasError('minlength')">Description must be at least 10 characters!</ng-container>
                            </ng-template>
                        </nz-form-control>
                    </nz-form-item>
                    <!-- employee photo -->
                    <app-image-input formControlName="photo"></app-image-input>
                </div> 
            </div>
            <div>
                <button nz-button type="button" (click)="addEmployee()">
                    <span nz-icon nzType="plus"> </span>Add Employee
                </button>
            </div>
            
        </div>
        <nz-form-item>
            <nz-form-label nzRequired>Description</nz-form-label>
            <nz-form-control nzHasFeedback [nzErrorTip]="descriptionError">
                <textarea nz-input formControlName="description" name="description" type="text" placeholder="Description" rows="6"></textarea>
                <ng-template #descriptionError let-control>
                    <ng-container *ngIf="control.hasError('required')">Please input description!</ng-container>
                    <ng-container *ngIf="control.hasError('minlength')">Description must be at least 10 characters!</ng-container>
                </ng-template>
            </nz-form-control>
        </nz-form-item> 
    </div>
    <div class="footer">
        <button nz-button nzType="default" type="button" (click)="onCancel()">Cancel</button>
        <button nz-button nzType="primary" type="submit" [nzLoading]="isSubmitLoading" [disabled]="form.invalid">Submit</button>
    </div>
</form>