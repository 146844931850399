import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzUploadChangeParam, NzUploadFile } from 'ng-zorro-antd/upload';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-import-stock-form',
  templateUrl: './import-stock-form.component.html',
  styleUrls: ['./import-stock-form.component.css']
})
export class ImportStockFormComponent implements OnInit {

  fileList: NzUploadFile[] = [];
  downloadStockUrl = environment.baseUrl + 'products/download-stock';
  downloadStockTemplateUrl = environment.baseUrl + 'uploads/product_imports/template.xlsx';

  onFileRemoved: (file: NzUploadFile) => boolean | Observable<boolean> = () => {
    this.fileUrl = '';
    return true;
  }

  beforeUpload: (file: NzUploadFile, fileList: NzUploadFile[]) => boolean | Observable<boolean> = (file: NzUploadFile, fileList: NzUploadFile[]) => {
    return (file.uid === fileList[0].uid)
  }

  isSubmitLoading = false;

  fileUrl = '';

  @Output() formSubmitted: EventEmitter<boolean> = new EventEmitter();
  @Output() formCancelled: EventEmitter<void> = new EventEmitter();

  constructor(private apollo: Apollo, private msg: NzMessageService) { }

  ngOnInit(): void {
  }

  getUploadUrl(): string {
    return environment.baseUrl + 'upload'
  }

  getHeaders() {
    return {
      Source: 'product_import'
    }
  }

  handleChange(e: NzUploadChangeParam): void {
    const status = e.file.status;

    if (status === 'done') {
      console.log(`${e.file.name} file uploaded successfully.`);
      const response = e.file.response
      if (response && response.success) {
        this.fileUrl = response.url;
        this.msg.success(`${e.file.name} file uploaded successfully.`);
      }   
    } else if (status === 'error') {
      console.error(`${e.file.name} file upload failed.`);
      this.msg.error(`${e.file.name} file upload failed.`);
    }
  }

  onSubmit(): void {
    this.isSubmitLoading = true;
    const importSub = this.apollo.mutate({
      mutation: importStockMutation,
      variables: {
        url: this.fileUrl
      }
    }).subscribe(result => {
      this.isSubmitLoading = false;
      this.formSubmitted.emit(true);
      if(result.data)
        this.msg.success(result.data?.importProducts.message);
      importSub.unsubscribe();
    }, err => {
      this.isSubmitLoading = false;
      console.error({err});
      this.msg.error(err.message)
      importSub.unsubscribe();
    })
  }

  onCancel(): void{
    this.formCancelled.emit();
  }
}

const importStockMutation = gql<{ importProducts: {success: boolean, message:string}}, {url:string}>`
  mutation ImportProducts($url: String!) {
    importProducts(url: $url) {
      success
      message
    }
  }
`