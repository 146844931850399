import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Apollo, gql } from 'apollo-angular';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzUploadChangeParam, NzUploadFile } from 'ng-zorro-antd/upload';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Category } from '../product';
import { categoriesQuery } from '../product-form/product-form.component';

@Component({
  selector: 'app-category-form',
  templateUrl: './category-form.component.html',
  styleUrls: ['./category-form.component.css']
})
export class CategoryFormComponent implements OnInit {

  @Input() category: Category | null = null;

  form = this.fb.nonNullable.group({
    image: ['', []],
    name: ['', [Validators.required]],
  })
  isSubmitLoading = false;

  imageUploading = false;
  fileList: NzUploadFile[] = [];
  onFileRemoved: (file: NzUploadFile) => boolean | Observable<boolean> = () => {
    this.form.controls.image.setValue('');
    return true;
  }

  @Output() formSubmitted: EventEmitter<boolean> = new EventEmitter();
  @Output() formCancelled: EventEmitter<void> = new EventEmitter();

  constructor(private fb: FormBuilder, private apollo: Apollo, private msg: NzMessageService) { }

  ngOnInit(): void {
    if(this.category) {
      this.form.setValue({
        name: this.category.name,
        image: this.category.image || ''
      })
      if (this.category.image) {
        this.fileList = [
          {
            uid: '1',
            name: this.category.name,
            status: 'done',
            url: this.category.image,
          }
        ]
      }
    }

  }

  getUploadUrl(): string {
    return environment.baseUrl + 'upload'
  }

  getHeaders() {
    return {
      Source: 'products'
    }
  }
  
  handleChange(e: NzUploadChangeParam): void {
    switch (e.file.status) {
      case 'uploading':
        this.imageUploading = true;
        break;
      case 'done':
        // Get this url from response.
        this.imageUploading = false;
        const response = e.file.response
        if (response && response.success) {
          this.form.controls.image.setValue(response.url);
        }
        break;
      case 'error':
        this.msg.error('Network error');
        this.imageUploading = false;
        break;
    }
  }
  
  onSubmit(): void {
    this.isSubmitLoading = true;
    if (this.form.invalid) {
      this.isSubmitLoading = false;
       return;
    } 
    if(this.category) {
      const editCategorySub = this.apollo.mutate({
        mutation: editCategoryMutation,
        variables: {
          id: this.category.id,
          name: this.form.controls.name.value,
          image: this.form.controls.image.value || null,
        },
      }).subscribe(result => {
        this.isSubmitLoading = false;
        this.formSubmitted.emit(true);
        this.msg.success('Category edited')
        editCategorySub.unsubscribe();
      }, err => {
        this.isSubmitLoading = false;
        this.msg.error(err.message)
        console.error({err})
        editCategorySub.unsubscribe();
      })
      return
    }
    const addCategorySub = this.apollo.mutate({
      mutation: addCategoryMutation,
      variables: {
        name: this.form.controls.name.value,
        image: this.form.controls.image.value,
      },
      update: (store, result) => {
        // Read the data from our cache for this query.
        let data = store.readQuery({ query: categoriesQuery });
        // Add our product from the mutation to the end.
        if (!data) {
          data =  {
            categories: []
          }
        } 
        const categories = [...data?.categories, result.data?.addCategory];
        
        // Write our data back to the cache.
        store.writeQuery({ query: categoriesQuery, data: { categories } });
      }
    }).subscribe(result => {
      this.isSubmitLoading = false;
      this.formSubmitted.emit(true);
      this.msg.success('Category added')
      addCategorySub.unsubscribe();
    }, err => {
      this.isSubmitLoading = false;
      this.msg.error(err.message)
      console.error({err})
      addCategorySub.unsubscribe();
    })
  }
  
  onCancel(): void{
    this.formCancelled.emit();
  }
}

const addCategoryMutation = gql<{ addCategory: Category }, { name: string, image: string }>`
  mutation AddCategory($name: String!, $image: String) {
    addCategory(name: $name, image: $image) {
      id,
      name,
      image
    }
  }
`

const editCategoryMutation = gql<{ editCategory: Category }, { id: number, name: string, image: string | null }>`
  mutation EditCategory($id: Int!, $name: String!, $image: String) {
    editCategory(id: $id, name: $name, image: $image) {
      id,
      name,
      image,
    }
  }
`
