import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Product } from 'src/app/products/product';

@Component({
  selector: 'app-product-item',
  templateUrl: './product-item.component.html',
  styleUrls: ['./product-item.component.css']
})
export class ProductItemComponent implements OnInit {

  @Input() product!: Product;
  @Output() editProduct: EventEmitter<void> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
 
  }
  onEditButtonClicked(): void {
    this.editProduct.emit();
  }

}
