import { Component, OnInit } from '@angular/core';
import {EMPTY, map, Observable} from "rxjs";
import {Order, OrderItem, Slip} from "../../orders/order";
import {Customer} from "../customer";
import {Apollo, gql} from "apollo-angular";
import {ActivatedRoute} from "@angular/router";
import {DomSanitizer} from "@angular/platform-browser";

export const OrdersQuery = gql<{ orders: Order[] }, {customerId: number}>`
  query GetOrders($customerId: Float!) {
    ordersByCustomer(customerId: $customerId){
      id,
      code,
      createdAt,
      customer {
        id,
        name,
        phoneNumber
      },
      isDelivery,
      deliveryLocation {
        address,
        latitude,
        longitude
      },
      status,
      acceptedBy,
      acceptedAt,
      completedBy,
      completedAt,
      rejectedBy,
      rejectedAt,
      totalCost,
      orderItems {
        id,
        product {
          id,
          name,
          quantity,
          price,
          code,
        },
        quantity,
        acceptedBy,
        acceptedAt,
        completedBy,
        completedAt,
        rejectedBy,
        rejectedAt,
        rejectionReason,
      },
      slips {
        image,
        isValid,
        payment {
          amount,
          paidOn,
          remark,
        },
      },
      totalPaid,
      note,
      rejectionReason,
      cancellationReason,
    }
  }
`

export const customerQuery = gql<{ customers: Customer }, { id: number }>`
  query GetCustomer($id: Float!) {
    customer(id: $id){
      id,
      name,
      companyName,
      phoneNumber,
      image,
      tin,
    }
  }
`

@Component({
  selector: 'app-customer-detail',
  templateUrl: './customer-detail.component.html',
  styleUrls: ['./customer-detail.component.css']
})
export class CustomerDetailComponent implements OnInit {

  orders$: Observable<Order[]> = EMPTY;
  customer$: Observable<Customer> = EMPTY;
  expandSet = new Set<number>();
  validatedSlipVisible: boolean = false;
  slipToView: any;

  constructor(private route: ActivatedRoute,
              private apollo: Apollo,
              public sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id');
    if (!id) return;
    this.customer$ = this.apollo.watchQuery({
      query: customerQuery,
      variables: {
        id: +id
      }
    }).valueChanges.pipe(map(result => {
      // @ts-ignore
      return result.data.customer;
    }));

    this.orders$ = this.apollo.watchQuery({
      query: OrdersQuery,
      variables: {
        customerId: +id
      }
    }).valueChanges.pipe(
      map(result =>  {
        // @ts-ignore
        let orders = result.data?.ordersByCustomer;
        return [...orders].sort((a,b) => b.id - a.id);
      }),
    );
  }

  getItemsString(items: OrderItem[]) {
    return items.map(i => i.quantity + ' ' + i.product.name).reduce((pv, cv) => pv + ', ' + cv);
  }

  onExpandChange(id: number, checked: boolean): void {
    if (checked) {
      this.expandSet.add(id);
    } else {
      this.expandSet.delete(id);
    }
  }

  getDeliveryLocation(order: Order) {
    if (order.deliveryLocation?.address) {
      return order.deliveryLocation?.address
    }
    const latLng = order.deliveryLocation?.latitude + ',<br>' + order.deliveryLocation?.longitude;
    return `<a href="https://www.google.com/maps/search/?api=1&query=${latLng}" target="_blank">${latLng}</a>`
  }

  getStatusTooltip(order: Order) {
    return order.status === 'REJECTED' ? order.rejectionReason || '' : order.status === 'CANCELLED' ? order.cancellationReason || '' : '';
  }

  openViewSlipDialog(slip: Slip) {
    this.slipToView = slip;
    this.validatedSlipVisible = true;
  }

  onSlipViewCancelled() {
    this.validatedSlipVisible = false;
    setTimeout(() => { this.slipToView = null; },1000);
  }
}
