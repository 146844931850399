import { Component, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Apollo, gql } from 'apollo-angular';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { filter, Subscription } from 'rxjs';
import { AuthService } from './auth/auth.service';
import { Customer } from './customers/customer';
import { customersQuery } from './customers/customer-list/customer-list.component';
import { Order, Product } from './orders/order';
import { ordersQuery } from './orders/order-list/order-list.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnDestroy {
  title = 'x-shop-admin';
  routeDataSub: Subscription;
  fullscreen = false;
  newOrderSub!: Subscription;
  editOrderSub!: Subscription;
  cancelOrderSub!: Subscription;
  productDepletedSub!: Subscription;
  newCustomerSub!: Subscription;
  slipAttachedSub!: Subscription;

  @ViewChild('newOrder', { static: false }) newOrderTemplate?: TemplateRef<{}>;
  @ViewChild('editedOrder', { static: false }) editedOrderTemplate?: TemplateRef<{}>;
  @ViewChild('cancelledOrder', { static: false }) cancelledOrderTemplate?: TemplateRef<{}>;
  @ViewChild('depletedProduct', { static: false }) depletedProductTemplate?: TemplateRef<{}>;
  @ViewChild('newCustomer', { static: false }) newCustomerTemplate?: TemplateRef<{}>;
  @ViewChild('slipAttached', { static: false }) slipAttachedTemplate?: TemplateRef<{}>;

  constructor(
    private route: ActivatedRoute, 
    private router: Router, 
    private apollo: Apollo, 
    private notification: NzNotificationService,
    private authService: AuthService,
  ) { 
    this.routeDataSub = this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe(
          () => {
            this.fullscreen = this.route.snapshot.firstChild?.data['fullscreen'];
          }
      );

    if (this.authService.isLoggedIn() && this.authService.getUser()) {
      // TODO: Get notification count
      const userId = this.authService.getUser()!.id;
      
      this.newOrderSub = this.apollo.subscribe({
        query: NEW_ORDER_SUBSCRIPTION,
        variables: {
          id: userId
        }
      }).subscribe((result) => {      
        if (result.data?.newOrder) {
          const order = result.data?.newOrder
          this.notification.template(this.newOrderTemplate!, { nzData: order })
          // this.notification.blank('New Order', `Order number ${order.code} received from customer ${order.customer.name}!`)
          const audio = new Audio('assets/notification.mp3');
			    audio.play();

          // update the cache for orders

          // Read the data from our cache for this query.
          const data = this.apollo.client.readQuery({query: ordersQuery})
          // Add new order to the end.
          let orders;
          if (data) {
            orders = [...data?.orders, result.data?.newOrder];
          } else {
            orders = [result.data?.newOrder]
          }
          // Write our data back to the cache.
          this.apollo.client.writeQuery({ query: ordersQuery, data: { orders } });

          const notification = new Notification('New Order', { 
            body: `Order number '${order.code}' received from customer ${ order.customer.name }!`, 
            icon: 'assets/logo.svg' 
          });
          notification.addEventListener('click', (event) => {
            window.focus();
          });
        }
      });
      this.editOrderSub = this.apollo.subscribe({
        query: EDITED_ORDER_SUBSCRIPTION,
        variables: {
          id: userId
        }
      }).subscribe((result) => {      
        if (result.data?.editedOrder) {
          const order = result.data?.editedOrder
          this.notification.template(this.editedOrderTemplate!, { nzData: order })
          // this.notification.blank('Order Edited', `Order number ${order.code} has been edited.`)
          const audio = new Audio('assets/notification.mp3');
			    audio.play();

          // TODO: update the cache for orders

          const notification = new Notification('Order Edited', { 
            body: `Order number '${order.code}' has been edited.`, 
            icon: 'assets/logo.svg' 
          });
          notification.addEventListener('click', (event) => {
            window.focus();
          });
        }
      });
      this.cancelOrderSub = this.apollo.subscribe({
        query: CANCELLED_ORDER_SUBSCRIPTION,
        variables: {
          id: userId
        }
      }).subscribe((result) => {      
        if (result.data?.cancelledOrder) {
          const order = result.data?.cancelledOrder
          this.notification.template(this.cancelledOrderTemplate!, { nzData: order })
          // this.notification.blank('Order Cancelled', `Order number ${order.code} has been cancelled.`)
          const audio = new Audio('assets/notification.mp3');
			    audio.play();
          // TODO: update the cache for orders

          const notification = new Notification('Order Cancelled', { 
            body: `Order number '${order.code}' has been cancelled.`, 
            icon: 'assets/logo.svg' 
          });
          notification.addEventListener('click', (event) => {
            window.focus();
          });
        }
      });
      this.productDepletedSub = this.apollo.subscribe({
        query: PRODUCT_DEPLETED_SUBSCRIPTION,
        variables: {
          id: userId
        }
      }).subscribe((result) => {      
        if (result.data?.depletedProduct) {
          const depletedProduct = result.data?.depletedProduct
          this.notification.template(this.depletedProductTemplate!, { nzData: depletedProduct })
          // this.notification.blank('Product out of Stock', `Product '${depletedProduct.product.name}' out of stock.`)
          const audio = new Audio('assets/notification.mp3');
			    audio.play();
          // TODO: update the cache for orders
          

          const notification = new Notification('Out of Stock Request', { 
            body: `${depletedProduct.requestedQuantity} '${depletedProduct.product.name}' out of stock request by ${depletedProduct.customer.name}.`, 
            icon: 'assets/logo.svg' 
          });
          notification.addEventListener('click', (event) => {
            window.focus();
          });
        }
      });
      this.newCustomerSub = this.apollo.subscribe({
        query: NEW_CUSTOMER_SUBSCRIPTION,
        variables: {
          id: userId
        }
      }).subscribe((result) => {      
        if (result.data?.newCustomer) {
          const customer = result.data?.newCustomer
          this.notification.template(this.newCustomerTemplate!, { nzData: customer })
          const audio = new Audio('assets/notification.mp3');
			    audio.play();

          // update the cache for customers

          // Read the data from our cache for this query.
          const data = this.apollo.client.readQuery({query: customersQuery})
          // Add new customer to the end.
          let customers;
          if (data) {
            customers = [...data?.customers, result.data?.newCustomer];
          } else {
            customers = [result.data?.newCustomer]
          }
          // Write our data back to the cache.
          this.apollo.client.writeQuery({ query: customersQuery, data: { customers } });

          const notification = new Notification('New Customer', { 
            body: `New customer ${ customer.name } registered!`, 
            icon: 'assets/logo.svg' 
          });
          notification.addEventListener('click', (event) => {
            window.focus();
          });
        }
      });

      this.slipAttachedSub = this.apollo.subscribe({
        query: SLIP_ATTACHED_SUB,
        variables: {
          id: userId
        }
      }).subscribe((result) => {      
        if (result.data?.slipAttached) {
          const order = result.data?.slipAttached
          this.notification.template(this.slipAttachedTemplate!, { nzData: order })
          const audio = new Audio('assets/notification.mp3');
			    audio.play();

          // TODO: update the cache for orders

          const notification = new Notification('Slip Attached', { 
            body: `Order number '${order.code}' has new slip attached.`, 
            icon: 'assets/logo.svg' 
          });
          notification.addEventListener('click', (event) => {
            window.focus();
          });
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.routeDataSub.unsubscribe();
    this.newOrderSub.unsubscribe();
    this.editOrderSub.unsubscribe();
    this.cancelOrderSub.unsubscribe();
    this.productDepletedSub.unsubscribe();
    this.newCustomerSub.unsubscribe();
    this.slipAttachedSub.unsubscribe();
  }

  isLoggedIn(): boolean {
    return this.authService.isLoggedIn();
  }

  getUser(): { name: string, image: string } {
    const user = this.authService.getUser();
    if (user) {
      return { name: user.name, image: user.image || 'assets/profile-picture-placeholder.svg' }
    }
    return { name: '', image: '' };
  }

  logout(): void {
    this.authService.logout();
    this.router.navigate(['/login']);
  }
  

}
const NEW_ORDER_SUBSCRIPTION = gql<{ newOrder: Order }, { id: number }>`
  subscription NewOrder($id: Int!) {
    newOrder(id: $id) {
      id,
      code,
      createdAt,
      customer { 
        id,
        name,
        phoneNumber
      },
      isDelivery,
      deliveryLocation {
          address,
          latitude,
          longitude
      },
      status,
      totalCost,
      orderItems {
        id,
        product { 
          id, 
          name,
          quantity,
          price,
          code, 
        },
        quantity,
        acceptedBy,
        completedBy,
        rejectedBy,
        rejectionReason,
      },
      note,
      rejectionReason,
      cancellationReason,
    }
  }
`;

const EDITED_ORDER_SUBSCRIPTION = gql<{ editedOrder: Order }, { id: number }>`
  subscription EditedOrder($id: Int!) {
    editedOrder(id: $id) {
      id,
      code,
      createdAt,
      customer { 
        id,
        name,
        phoneNumber
      },
      isDelivery,
      deliveryLocation {
          address,
          latitude,
          longitude
      },
      status,
      totalCost,
      orderItems {
        id,
        product { 
          id, 
          name,
          quantity,
          price,
          code, 
        },
        quantity,
        acceptedBy,
        completedBy,
        rejectedBy,
        rejectionReason,
      },
      note,
      rejectionReason,
      cancellationReason,
    }
  }
`

const CANCELLED_ORDER_SUBSCRIPTION = gql<{ cancelledOrder: Order }, { id: number }>`
  subscription CancelledOrder($id: Int!) {
    cancelledOrder(id: $id, type: ADMIN) {
      id,
      code,
      createdAt,
      customer { 
        id,
        name,
        phoneNumber
      },
      isDelivery,
      deliveryLocation {
          address,
          latitude,
          longitude
      },
      status,
      totalCost,
      orderItems {
        id,
        product { 
          id, 
          name,
          quantity,
          price,
          code, 
        },
        quantity,
        acceptedBy,
        completedBy,
        rejectedBy,
        rejectionReason,
      },
      note,
      rejectionReason,
      cancellationReason,
    }
  }
`

const PRODUCT_DEPLETED_SUBSCRIPTION =gql<{ 
  depletedProduct: {
    product: Product; 
    requestedQuantity: number; 
    customer: Customer
  } }, { id: number }>`
  subscription DepletedProduct($id: Int!) {
    depletedProduct(id: $id) {
      product {
        id,
        name,
        description,
        code,
        price,
        quantity,
        image,
        category {
            id,
            name
        }
      }
      customer {
        id
        name
      }
      requestedQuantity
    }
  }
`

const NEW_CUSTOMER_SUBSCRIPTION = gql<{ newCustomer: Customer }, { id: number }>`
  subscription NewCustomer($id: Int!) {
    newCustomer(id: $id) {
      id,
      name,
      companyName,
      phoneNumber,
      image,
      tin,
      createdAt,
      hasTelegramAccount
    }
  }
`;

const SLIP_ATTACHED_SUB = gql<{ slipAttached: Order }, { id: number }>`
  subscription SlipAttached($id: Int!) {
    slipAttached(id: $id) {
      id,
      code,
      slips {
        image
      }
    }
  }
`