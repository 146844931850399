import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Route, RouterModule } from '@angular/router';
import { GoogleMapsModule } from '@angular/google-maps';

import { AuthGuard } from '../auth/auth.guard';
import { BranchDetailComponent } from './branch-detail/branch-detail.component';
import { BranchFormComponent } from './branch-form/branch-form.component';
import { BranchListComponent } from './branch-list/branch-list.component';

import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { ReactiveFormsModule } from '@angular/forms';
import { LocationInputComponent } from './location-input/location-input.component';
import { ImageInputComponent } from './image-input/image-input.component';


const routes: Route[] = [
  { path: 'branches', component: BranchListComponent, canActivate: [AuthGuard] }
]

@NgModule({
  declarations: [
    BranchDetailComponent,
    BranchFormComponent,
    BranchListComponent,
    LocationInputComponent,
    ImageInputComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    ReactiveFormsModule,
    GoogleMapsModule,

    NzPageHeaderModule,
    NzTableModule,
    NzLayoutModule,
    NzModalModule,
    NzButtonModule,
    NzInputModule,
    NzMessageModule,
    NzDividerModule,
    NzFormModule,
    NzTypographyModule,
    NzSelectModule,
    NzUploadModule,
    NzIconModule,
    NzTagModule,
  ]
})
export class BranchesModule { }
