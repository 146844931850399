import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Route, RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';

import { ResourceListComponent } from './resource-list/resource-list.component';
import { ResourceFormComponent } from './resource-form/resource-form.component';
import { AuthGuard } from '../auth/auth.guard';
import { ResourceDetailComponent } from './resource-detail/resource-detail.component';

import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzTagModule } from 'ng-zorro-antd/tag';




const routes: Route[] = [
  { path: 'resources', component: ResourceListComponent, canActivate: [AuthGuard] }
]

@NgModule({
  declarations: [
    ResourceListComponent,
    ResourceFormComponent,
    ResourceDetailComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    ReactiveFormsModule,

    NzPageHeaderModule,
    NzTableModule,
    NzLayoutModule,
    NzModalModule,
    NzButtonModule,
    NzInputModule,
    NzMessageModule,
    NzDividerModule,
    NzFormModule,
    NzTypographyModule,
    NzSelectModule,
    NzUploadModule,
    NzIconModule,
    NzTagModule,
  ]
})
export class ResourcesModule { }
