import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Order, Slip} from "../order";
import {Apollo, gql} from "apollo-angular";
import {NzMessageService} from "ng-zorro-antd/message";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-slip-validation',
  templateUrl: './slip-validation.component.html',
  styleUrls: ['./slip-validation.component.css']
})
export class SlipValidationComponent implements OnInit {

  @Input() slip: Slip = {
    image: '',
    isValid: undefined,
    validatedBy: undefined,
    payment: {
      amount: 0,
      paidOn: new Date(),
      remark: ''
    }
  };

  @Input() order!: Order | null;
  @Input() slipIndex!: number;
  isSlipValid: string = `${this.slip.isValid}`;
  form: FormGroup = new FormGroup({
    amount : new FormControl('', [Validators.required]),
    paidOn : new FormControl(new Date(), [Validators.required]),
    remark : new FormControl(''),
  });
  isSubmitLoading = false;

  @Output() formSubmitted: EventEmitter<boolean> = new EventEmitter();
  @Output() formCancelled: EventEmitter<void> = new EventEmitter();

  constructor(
    private apollo: Apollo,
    private msg: NzMessageService,
    public sanitizer: DomSanitizer) { }

  ngOnInit(): void { }

  onSubmit() {
    this.isSubmitLoading = true;
    if (this.isSlipValid === 'true' && this.form.invalid) {
      this.isSubmitLoading = false;
      return;
    }
    let newSlip: Slip;
    if (this.isSlipValid === 'true') {
      newSlip = {
        image: this.slip.image,
        isValid: true,
        payment: {
          amount: this.form.get('amount')?.value,
          paidOn: this.form.get('paidOn')?.value.toISOString(),
          remark: this.form.get('remark')?.value
        }
      };
    } else {
      newSlip = {
        image: this.slip.image,
        isValid: false,
        payment: undefined,
      };
    }
    if (this.order) {
      const editOrderSub = this.apollo.mutate({
        mutation: validateSlipForOrderMutation,
        variables: {
          code: this.order.code,
          slipData: newSlip,
        }
      }).subscribe((result) => {
        const order = result.data?.editOrder
        this.isSubmitLoading = false;
        this.formSubmitted.emit(true);
        this.msg.success('Form Validated')
        editOrderSub.unsubscribe();
      })
    }
  }

  onCancel(): void{
    this.formCancelled.emit();
  }
}

interface SlipInput{
  image: string,
  isValid?: boolean,
  payment?: {
    amount: number,
    paidOn: Date,
    remark: string,
  }
}

const validateSlipForOrderMutation = gql<{ editOrder: Order }, { code: string, slipData: SlipInput }>`
  mutation validateSlip($slipData: SlipInput!, $code: String!) {
    validateSlip(code: $code, slip: $slipData) {
      id,
      code,
      slips {
        image,
        isValid,
        payment {
          amount,
          paidOn,
          remark,
        },
      },
      totalPaid,
    }
  }
`
