import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationListComponent } from './notification-list/notification-list.component';
import { Route, RouterModule } from '@angular/router';

import { NzListModule } from 'ng-zorro-antd/list';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { AuthGuard } from '../auth/auth.guard';

const routes: Route[] = [
  { path: 'notifications', component: NotificationListComponent, canActivate: [AuthGuard] }
]


@NgModule({
  declarations: [
    NotificationListComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),

    NzTypographyModule,
    NzListModule,
    NzLayoutModule,
    NzButtonModule,
    NzIconModule,
    NzToolTipModule,
  ]
})
export class NotificationsModule { }
