import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

import { NzCardModule } from 'ng-zorro-antd/card';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzMessageModule } from 'ng-zorro-antd/message';

import { OrderListComponent } from './order-list/order-list.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ManageOrderComponent } from './manage-order/manage-order.component';
import { AuthGuard } from '../auth/auth.guard';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { SlipValidationComponent } from './slip-validation/slip-validation.component';
import { NzRadioModule } from "ng-zorro-antd/radio";
import { ViewSlipComponent } from './view-slip/view-slip.component';
import { OrderStatsComponent } from './order-stats/order-stats.component';


const routes: Routes = [
  { path: 'orders', component: OrderListComponent, canActivate: [AuthGuard] },
  { path: 'order-stats', component: OrderStatsComponent, canActivate: [AuthGuard] }
];

@NgModule({
  declarations: [
    OrderListComponent, ManageOrderComponent, SlipValidationComponent, ViewSlipComponent, OrderStatsComponent,
  ],
  exports: [
    ViewSlipComponent,
    ViewSlipComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,
    NzCardModule,
    NzListModule,
    NzTypographyModule,
    NzLayoutModule,
    NzIconModule,
    NzButtonModule,
    NzDatePickerModule,
    NzGridModule,
    NzMenuModule,
    NzTableModule,
    NzDrawerModule,
    NzModalModule,
    NzFormModule,
    NzInputModule,
    NzToolTipModule,
    NzMessageModule,
    NzRadioModule,
    NzSelectModule,
  ]
})
export class OrdersModule { }
