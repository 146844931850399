import { Component, Input, OnInit } from '@angular/core';
import { Branch } from '../branch';

@Component({
  selector: 'app-branch-detail',
  templateUrl: './branch-detail.component.html',
  styleUrls: ['./branch-detail.component.css']
})
export class BranchDetailComponent implements OnInit {

  @Input() branch!: Branch;

  fileName(file: string): string {
    return file.replace(/http.+\/\d+-\d+-/g, '')
  }

  constructor() { }

  ngOnInit(): void {
  }

}
