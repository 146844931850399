import { Component, OnInit } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { EMPTY, map, Observable, of } from 'rxjs';
import { Notification, NotificationTopics } from '../notification';

const notificationsQuery = gql<{ notifications: Notification[]}, void>`
  query {
    notifications(type: ADMIN) {
      id,
      topic,
      data,
      isRead,
      createdAt
    }      
  }
`

const readNotificationMutation = gql<{ markNotificationAsRead: Notification}, { id: number }>`
  mutation MarkNotificationAsRead($id: Int!)  {
    markNotificationAsRead(type: ADMIN, id: $id) {
      id,
      topic,
      data,
      isRead,
      createdAt
    }      
  }
`

const deleteNotificationMutation = gql<{ deleteNotification: Notification}, { id: number }>`
  mutation DeleteNotification($id: Int!)  {
    deleteNotification(type: ADMIN, id: $id) {
      id,
      topic,
      data,
      isRead,
      createdAt
    }      
  }
`

@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.css']
})
export class NotificationListComponent implements OnInit {

  notifications$: Observable<Notification[]> = EMPTY;

  constructor(private apollo: Apollo) { }

  ngOnInit(): void {
    this.notifications$ = this.apollo.watchQuery({
      query: notificationsQuery
    }).valueChanges.pipe(
      map(result => {
        let notifications = result.data.notifications; //.map(notification => Object.assign({}, notification, { createdAt: new Date(notification.createdAt) }))
        return [...notifications].sort((a, b) => b.id - a.id) // shorthand method to sort by created date since the id is incremented
      })
    );
  }

  getNotificationTitle(notification: Notification) {
    switch(notification.topic) {
      case NotificationTopics.ORDER_CREATED:
        return 'New Order Received';
      case NotificationTopics.ORDER_EDITED:
        return 'Order Edited'
      case NotificationTopics.ORDER_CANCELLED:
        return 'Order Cancelled'
      case NotificationTopics.PRODUCT_OUT_OF_STOCK:
        return 'Product out of Stock'
      default: 
        return notification.topic.toLowerCase();
    }
  }

  getNotificationContent(notification: Notification): string {
    if (notification.topic === NotificationTopics.PRODUCT_OUT_OF_STOCK) {
      if (notification.data && notification.data.name) {
        return `Product '${notification.data.name}' out of stock`
      }
      return 'Product out of stock'
    }
    if (notification.data && notification.data.code) {
      if (notification.topic === NotificationTopics.ORDER_CREATED) {
        return `Order number '${notification.data.code}' has been created`
      }
      if (notification.topic === NotificationTopics.ORDER_EDITED) {
        return `Order number '${notification.data.code}' has been edited`
      }
      if (notification.topic === NotificationTopics.ORDER_CANCELLED) {
        return `Order number '${notification.data.code}' has been cancelled`
      }
    }
    return notification.topic.toLowerCase();
  }

  readNotification(notification: Notification) {
    const readNotificationSub = this.apollo.mutate({
      mutation: readNotificationMutation,
      variables: {
        id: notification.id
      }
    }).subscribe({
      next: () => {
        readNotificationSub.unsubscribe();
      },
      error: (err) => {
        console.error({err});
        readNotificationSub.unsubscribe();
      }
    });
  }

  deleteNotification(notification: Notification) {
    const deleteNotificationSub = this.apollo.mutate({
      mutation: deleteNotificationMutation,
      variables: {
        id: notification.id
      }
    }).subscribe({
      next: () => {
        deleteNotificationSub.unsubscribe();
      },
      error: (err) => {
        console.error({err});
        deleteNotificationSub.unsubscribe();
      }
    });
  }
}
