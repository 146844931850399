import {Component, Input, OnInit} from '@angular/core';
import {Slip} from "../order";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-view-slip',
  templateUrl: './view-slip.component.html',
})
export class ViewSlipComponent implements OnInit {

  @Input() slip: Slip = {
    image: '',
    isValid: false,
    validatedBy: undefined,
    payment: {
      amount: 0,
      paidOn: new Date(),
      remark: ''
    }
  };

  constructor(public sanitizer: DomSanitizer) { }

  ngOnInit(): void { }

}
