import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NzTableModule } from 'ng-zorro-antd/table';
import { NzImageModule } from 'ng-zorro-antd/image';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzTypographyModule } from 'ng-zorro-antd/typography';


import { CustomerListComponent } from './customer-list/customer-list.component';
import { Route, RouterModule } from '@angular/router';
import { AuthGuard } from '../auth/auth.guard';
import { NzSelectModule } from "ng-zorro-antd/select";
import { FormsModule } from "@angular/forms";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzIconModule } from "ng-zorro-antd/icon";
import { CustomerDetailComponent } from './customer-detail/customer-detail.component';
import {NzToolTipModule} from "ng-zorro-antd/tooltip";
import {NzModalModule} from "ng-zorro-antd/modal";
import {OrdersModule} from "../orders/orders.module";
import { NzFormModule } from 'ng-zorro-antd/form';

const routes: Route[] = [
  { path: 'customers', component: CustomerListComponent, canActivate: [AuthGuard] },
  { path: 'customer/:id', component: CustomerDetailComponent, canActivate: [AuthGuard] }
]

@NgModule({
  declarations: [
    CustomerListComponent,
    CustomerDetailComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),

    NzTableModule,
    NzImageModule,
    NzLayoutModule,
    NzTypographyModule,
    NzSelectModule,
    FormsModule,
    NzInputModule,
    NzButtonModule,
    NzIconModule,
    NzToolTipModule,
    NzModalModule,
    OrdersModule,
    FormsModule,
    NzFormModule,
  ]
})
export class CustomersModule { }
