import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { Router } from '@angular/router';
import { Apollo, gql } from 'apollo-angular';
import { NzMessageService } from 'ng-zorro-antd/message';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-reset-password-form',
  templateUrl: './reset-password-form.component.html',
  styleUrls: ['./reset-password-form.component.css']
})
export class ResetPasswordFormComponent implements OnInit {

  step = 1;

  form: FormGroup;

  medium: 'phone' | 'email' = 'email';
  changeMedium(): void {
    this.medium = this.medium === 'phone' ? 'email' : 'phone';
  }

  confirmValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return { error: true, required: true };
    } else if (control.value !== this.form.controls['password'].value) {
      return { confirm: true, error: true };
    }
    return {};
  };
  
  constructor(
    private fb: FormBuilder, 
    private apollo: Apollo, 
    private msg: NzMessageService, 
    private router: Router,
    private authService: AuthService,
  ) {
    this.form = this.fb.group({
      phoneOrEmail: [null, [Validators.required]],
      code: [null, Validators.required],
      password: [null, [Validators.required]],
      confirm: [null, [this.confirmValidator]]
    })
   }

  ngOnInit(): void {
  }

  goToStep2(): void {
    const sendCodeSub = this.apollo.mutate({
      mutation: sendCodeMutation,
      variables: {
        phoneOrEmail: this.form.controls['phoneOrEmail'].value,
      }
    }).subscribe(result => {
      if (result.data?.sendCode.success) {
        this.step = 2;
      }
      sendCodeSub.unsubscribe();
    }, err => {
      console.error({err});
      this.msg.error(err.message);
      sendCodeSub.unsubscribe();
    })
  }

  goToStep3(): void {
    const verifyCodeSub = this.apollo.mutate({
      mutation: verifyCodeMutation,
      variables: {
        phoneOrEmail: this.form.controls['phoneOrEmail'].value,
        code: this.form.controls['code'].value,
      }
    }).subscribe(result => {
      if (result.data?.verifyCode.success) {
        const token = result.data.verifyCode.resetPasswordToken;
        this.authService.setToken(token);
        this.step = 3;
      }
      verifyCodeSub.unsubscribe();
    }, err => {
      console.error({err});
      this.msg.error(err.message);
      verifyCodeSub.unsubscribe();
    });
  }

  onSubmit(): void {
    console.log({values: this.form.getRawValue()})

    const resetPasswordSub = this.apollo.mutate({
      mutation: resetPasswordMutation,
      variables: {
        password: this.form.controls['password'].value,
      },
      // context: {
      //   headers: {
      //     "Authorization": `Bearer ${this.token}`
      //   }
      // }
      
    }).subscribe(result => {
      if (result.data?.resetPassword.success) {
        this.router.navigate(['/login']);
      }
      resetPasswordSub.unsubscribe();
    }, err => {
      console.error({err});
      this.msg.error(err.message);
      resetPasswordSub.unsubscribe();
    });
  }
}

const sendCodeMutation = gql<{sendCode: {success: boolean}}, { phoneOrEmail: string }>`
  mutation SendCode($phoneOrEmail: String!) {
    sendCode(phoneOrEmail: $phoneOrEmail) {
      success
    }
  }
`

const verifyCodeMutation = gql<{ verifyCode: { success: boolean, resetPasswordToken: string }}, { phoneOrEmail: string, code: string }>`
  mutation VerifyCode($phoneOrEmail: String!, $code: String!) {
    verifyCode(phoneOrEmail: $phoneOrEmail, code: $code) {
      success,
      resetPasswordToken
    }
  }
`

const resetPasswordMutation = gql<{ resetPassword: { success: boolean }}, { password: string }>`
  mutation ResetPassword($password: String!) {
    resetPassword(password: $password) {
      success
    }
  }
`