import { Component, OnInit } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { NzMessageService } from 'ng-zorro-antd/message';
import { EMPTY, map, Observable, of } from 'rxjs';
import { Role, User } from '../user';

export const usersQuery = gql<{users: User[]}, void>`
  query {
    users {
      id,
      phoneNumber,
      email,
      image,
      name,
      roles,
      createdAt,
      updatedAt
    }
  }
`
export const usersSearchQuery = gql<{users: User[]}, { value: string }>`
  query SearchUsers($value: String!) {
    usersSearch(value: $value){
      id,
      phoneNumber,
      email,
      image,
      name,
      roles,
      createdAt,
      updatedAt
    }
  }
`

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit {

  users$: Observable<User[]> = EMPTY;

  userToEdit: User | null = null;
  userFormVisible = false;

  userToDelete: User | null = null;
  deleteUserModalVisible = false;

  resetPasswordModalVisible = false;
  userToResetPassword: User | null = null

  searchField!: string;
  isAllUsers: boolean = true;

  constructor(private apollo: Apollo, private msg: NzMessageService) { }

  ngOnInit(): void {
    this.getAllUsers();
  }

  getAllUsers(){
    this.users$ = this.apollo.watchQuery({
      query: usersQuery
    }).valueChanges.pipe(
      map(result => {
        let users = result.data?.users; //.map(user => Object.assign({}, user, { createdAt: new Date(user.createdAt) }))
        return [...users].sort((a, b) => b.id - a.id) // shorthand method to sort by created date since the id is incremented
      })
    );
  }

  getRolesString(roles: string[]): string {
    return roles.reduce((pv, cv) => pv + ', ' + cv, '').substring(2);
  }

  addUser(): void {
    this.userFormVisible = true;
  }

  editUser(user: User): void {
    this.userToEdit = user;
    this.userFormVisible = true;
  }

  onFormCancelled(): void {
    this.userFormVisible = false;
    setTimeout(() => {
      this.userToEdit = null;
    },1000)
  }

  onFormSubmitted(success: boolean): void {
      //TODO: Refactor code to show or hide form based on result status
      this.userFormVisible = false;
      setTimeout(() => {
        this.userToEdit = null;
      },1000)

  }

  deleteUser(user: User): void {
    this.userToDelete = user;
    this.deleteUserModalVisible = true;
  }

  onDeleteCancelled(): void {
    this.deleteUserModalVisible = false;
    this.userToDelete = null;
  }

  onDeleteConfirmed(): void {
    if (this.userToDelete) {
      const deleteUserSub = this.apollo.mutate({
        mutation: deleteUserMutation,
        variables: {
          id: this.userToDelete.id
        }
      }).subscribe(result => {
        const userId = result.data?.deleteUser.id;

        const data = this.apollo.client.cache.readQuery({query: usersQuery});
        if (data) {

          const index = data.users.findIndex(user => user.id === userId);
          if (index > -1) {
            const users = [...data.users];
            users.splice(index, 1);
            // Write our data back to the cache.
            this.apollo.client.writeQuery({ query: usersQuery, data: { users } });
          }
        }
        this.deleteUserModalVisible = false;
        this.userToDelete = null;
        this.msg.success('User deleted')
        deleteUserSub.unsubscribe();
      }, err => {
        // this.isSubmitLoading = false;
        this.msg.error(err.message)
        console.error({err});
        deleteUserSub.unsubscribe();
      })
    }
  }

  resetPassword(user: User): void {
    this.userToResetPassword = user;
    this.resetPasswordModalVisible = true;
  }

  onResetPasswordFormCancelled(): void {
    this.resetPasswordModalVisible = false;
    this.userToResetPassword = null;
  }

  onResetPasswordFormSubmitted(success: boolean): void {
      //TODO: Refactor code to show or hide form based on result status
      this.resetPasswordModalVisible = false;
      this.userToResetPassword = null;
  }

  searchUsers() {
    if (!this.searchField && this.isAllUsers) return;
    if (this.searchField) {
      this.users$ = this.apollo.watchQuery({
        query: usersSearchQuery,
        variables: {
          value: this.searchField
        }
      }).valueChanges.pipe(
        map(result => {
          // @ts-ignore
          let users = result.data?.usersSearch;
          return [...users].sort((a, b) => b.id - a.id);
        })
      );
      this.isAllUsers = false;
    } else if (!this.isAllUsers){
      this.getAllUsers();
      this.isAllUsers = true;
    }
  }
}

const deleteUserMutation = gql<{ deleteUser: User }, { id: number }>`
  mutation DeleteUser($id: Int!) {
    deleteUser(id: $id) {
      id,
      # phoneNumber,
      # image,
      # name,
      # roles,
      # createdAt,
      # updatedAt
    }
  }
`
