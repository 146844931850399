import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SettingsComponent } from './settings/settings.component';
import {Route, RouterModule} from "@angular/router";
import {AuthGuard} from "../auth/auth.guard";
import {NzTypographyModule} from "ng-zorro-antd/typography";
import {NzLayoutModule} from "ng-zorro-antd/layout";
import {FormsModule} from "@angular/forms";
import {NzButtonModule} from "ng-zorro-antd/button";
import {NzModalModule} from "ng-zorro-antd/modal";
import {NzFormModule} from "ng-zorro-antd/form";
import {NzInputModule} from "ng-zorro-antd/input";
import {NzIconModule} from "ng-zorro-antd/icon";

const routes: Route[] = [
  { path: 'settings', component: SettingsComponent, canActivate: [AuthGuard] }
]

@NgModule({
  declarations: [
    SettingsComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    NzTypographyModule,
    NzLayoutModule,
    NzButtonModule,
    NzModalModule,
    NzFormModule,
    NzInputModule,
    FormsModule,
    NzIconModule
  ]
})
export class SettingsModule { }
