<form nz-form nzLayout="vertical" [formGroup]="form" (ngSubmit)="onSubmit()">
    <div style="padding: 24px 24px 0px;">
        <nz-form-item>
            <nz-form-label nzRequired>New Password</nz-form-label>
            <nz-form-control nzHasFeedback nzErrorTip="Please input your password!">
                <nz-input-group nzPrefixIcon="lock">
                    <input nz-input type="password" formControlName="password" name="password" placeholder="Password" (ngModelChange)="validateConfirmPassword()">
                </nz-input-group>
            </nz-form-control>
        </nz-form-item> 
        <nz-form-item>
            <nz-form-label nzRequired>Confirm Password</nz-form-label>
            <nz-form-control nzHasFeedback [nzErrorTip]="passwordErrorTpl">
                <nz-input-group nzPrefixIcon="lock">
                    <input nz-input type="password" formControlName="confirm" placeholder="Confirm your password" />
                </nz-input-group>
                <ng-template #passwordErrorTpl let-control>
                    <ng-container *ngIf="control.hasError('required')">Please confirm your password!</ng-container>
                    <ng-container *ngIf="control.hasError('confirm')">Password is inconsistent!</ng-container>
                </ng-template>
            </nz-form-control>
        </nz-form-item>
    </div>
    <div class="footer">
        <button nz-button nzType="default" type="button" (click)="onCancel()">Cancel</button>
        <button nz-button nzType="primary" type="submit" [nzLoading]="isSubmitLoading" [disabled]="form.invalid">Submit</button>
    </div>
</form>

