<div class="slip-validation">
  <div class="image-container">
    <img class="image" alt="payment slip"
         [src]=sanitizer.bypassSecurityTrustResourceUrl(slip.image)>
  </div>
  <nz-radio-group class="radio" [(ngModel)]="isSlipValid">
    <label nz-radio nzValue="true">Valid</label>
    <label nz-radio nzValue="false">Invalid</label>
  </nz-radio-group>
  <ng-container *ngIf="isSlipValid === 'true'">
    <h2 style="margin-left: 1.5rem">Record Payment</h2>
    <form class="form" nz-form [formGroup]="form" nzLayout="vertical">
      <div class="container">
        <nz-form-item class="amount">
          <nz-form-label nzRequired>Amount</nz-form-label>
          <nz-form-control [nzErrorTip]="nameError">
            <nz-input-group nzPrefix="Birr">
              <input nz-input formControlName="amount" name="amount" type="number">
            </nz-input-group>
            <ng-template #nameError let-control>
              <ng-container *ngIf="control.hasError('required')">Please input the amount!</ng-container>
            </ng-template>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item class="date">
          <nz-form-label nzRequired>Paid On</nz-form-label>
          <nz-form-control [nzErrorTip]="dateError">
            <nz-date-picker style="width: 100%;" formControlName="paidOn"></nz-date-picker>
            <ng-template #dateError let-control>
              <ng-container *ngIf="control.hasError('required')">Please input the date!</ng-container>
            </ng-template>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div class="remark-container">
        <nz-form-item class="remark">
          <nz-form-label>Remark</nz-form-label>
          <nz-form-control>
            <nz-input-group>
              <textarea nz-input formControlName="remark" placeholder="Remark"
                        [nzAutosize]="{ minRows: 2, maxRows: 4 }">
              </textarea>
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>
      </div>
    </form>
  </ng-container>
  <div class="footer">
    <button nz-button nzType="default" type="button" (click)="onCancel()">Cancel</button>
    <button nz-button nzType="primary" type="button" [nzLoading]="isSubmitLoading" (click)="onSubmit()">Submit</button>
  </div>
</div>
