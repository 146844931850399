import { Component, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, ControlValueAccessor, Validator, AbstractControl, ValidationErrors } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzUploadChangeParam, NzUploadFile } from 'ng-zorro-antd/upload';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-image-input',
  templateUrl: './image-input.component.html',
  styleUrls: ['./image-input.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: ImageInputComponent
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: ImageInputComponent
    }
  ],
})
export class ImageInputComponent implements OnInit, ControlValueAccessor, Validator {

  url: string  = '';
  onChange = (url: any) => {};
  onTouched = () => {};
  disabled = false;
  touched = false;
  constructor(private msg: NzMessageService) { }

  ngOnInit(): void {
  }

  writeValue(obj: any): void {
    this.url = obj;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
  validate(control: AbstractControl<any, any>): ValidationErrors | null {
    return null;
  }
  // registerOnValidatorChange?(fn: () => void): void {
  //   throw new Error('Method not implemented.');
  // }
  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  // imageUploading = false;
  fileList: NzUploadFile[] = [];
  onFileRemoved: (file: NzUploadFile) => boolean | Observable<boolean> = () => {
    this.url = '';
    return true;
  }
  getUploadUrl(): string {
    return environment.baseUrl + 'upload'
  }

  getHeaders() {
    return {
      Source: 'employee_photo'
    }
  }

  handleChange(e: NzUploadChangeParam): void {
    this.markAsTouched();
    switch (e.file.status) {
      case 'uploading':
        // this.imageUploading = true;
        break;
      case 'done':
        // Get this url from response.
        // this.imageUploading = false;
        const response = e.file.response
        if (response && response.success) {
          this.url = response.url;
          this.onChange(this.url);
        }
        break;
      case 'error':
        this.msg.error('Network error');
        this.url = ''
        this.onChange(this.url);
        // this.imageUploading = false;
        break;
    }
  }
}
