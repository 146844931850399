import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Apollo, gql } from 'apollo-angular';
import { NzMessageService } from 'ng-zorro-antd/message';
import { User } from 'src/app/users/user';
import { AuthService } from '../auth.service';

const loginMutation = gql<{ login: { token: string, user: User }}, { phoneNumber: string, password: string }>`
  mutation Login($phoneNumber: String!, $password: String!) {
    login(phoneNumber: $phoneNumber, password: $password) {
      token,
      user {
        id
        name,
        phoneNumber,
        image,
        createdAt,
        roles
      }
    }
  }
`

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {


  form: FormGroup = this.fb.group({
    phoneNumber: ['', [Validators.required]],
    password: ['', [Validators.required]],
    // remember: [false]
  })

  passwordVisible = false;
  constructor(
    private fb: FormBuilder, 
    private apollo: Apollo, 
    private router: Router, 
    private msg: NzMessageService,
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
  }

  onSubmit(): void {
    const loginSub = this.apollo.mutate({
      mutation: loginMutation,
      variables: {
        phoneNumber: this.form.controls['phoneNumber'].value,
        password: this.form.controls['password'].value,
      }
    }).subscribe(result => {
      const loginResult = result.data?.login;
      if (loginResult?.token && loginResult.user){
        this.authService.login(loginResult.token, loginResult.user)
        this.router.navigate(['/orders']).then(success => {
          if(success && Notification.permission !== 'granted') {
            askNotificationPermission();
          }
        });
      }
      loginSub.unsubscribe();
    }, err => {
      this.msg.error(err.message)
      console.error({err})
      loginSub.unsubscribe();
    });
  }

}

function askNotificationPermission() {
  // function to actually ask the permissions
  function handlePermission(permission: NotificationPermission) {


  }

  // Let's check if the browser supports notifications
  if (!('Notification' in window)) {
    console.log("This browser does not support notifications.");
  } else if (checkNotificationPromise()) {
    Notification.requestPermission().then((permission) => {
      handlePermission(permission);
    });
  } else {
    Notification.requestPermission((permission) => {
      handlePermission(permission);
    });
  }
}

function checkNotificationPromise() {
  try {
    Notification.requestPermission().then();
  } catch (e) {
    return false;
  }

  return true;
}
