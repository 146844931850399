<ng-template #header>
  <div style="display: grid; place-items: center">
    <ng-container *ngIf="customer$ | async as customer">
      <img *ngIf="customer.image"
        nz-image
        width="75px"
        height="100px"
        [nzSrc]="customer.image"
        alt="customers image"
        style="border-radius: 50%;"
      />
    </ng-container>
  </div>
  <div style="display: flex; flex-wrap: wrap; justify-content: space-evenly; align-items: center" *ngIf="customer$ | async as customer">
    <span style="display: flex; justify-content: center; align-items: flex-end">
      <h3>Customer: </h3><p>{{customer?.name | titlecase}}</p>
    </span>
    <span style="display: flex; justify-content: center; align-items: flex-end">
      <h3>Company: </h3><p>{{customer?.companyName | titlecase}}</p>
    </span>
    <span style="display: flex; justify-content: center; align-items: flex-end">
      <h3>Phone Number: </h3><p>{{customer?.phoneNumber}}</p>
    </span>
    <span style="display: flex; justify-content: center; align-items: flex-end">
      <h3>TIN No: </h3><p>{{customer?.tin}}</p>
    </span>
  </div>
</ng-template>

<nz-layout style="height: calc(100vh - 72px); overflow-y: scroll;">
  <nz-header style="background-color: white;">
    <h4 nz-typography style="display: inline-block;">Customer</h4>
  </nz-header>
  <nz-content style="padding-inline: 24px; margin-top: 35px; margin-inline: auto; width: 1184px" >
    <nz-table
      #basicTable
      [nzData]="(orders$ | async) || []"
      [nzPageSize]="20"
      [nzTitle]="header"
    >
      <thead>
      <tr>
        <th nzWidth="32px"></th>
        <th nzWidth="113px">Number</th>
        <th nzWidth="142px">Phone Number</th>
        <th>Items</th>
        <th nzWidth="100px">Total Price</th>
        <th nzWidth="100px">Total Paid</th>
        <th nzWidth="100px" nzBreakWord>Delivery</th>
        <th nzWidth="100px">Status</th>
        <th nzWidth="120px">Date</th>
      </tr>
      </thead>
      <tbody>
      <ng-container *ngFor="let order of basicTable.data">
        <tr>
          <td
            [nzExpand]="expandSet.has(order.id)"
            (nzExpandChange)="onExpandChange(order.id, $event)"
          ></td>
          <td>{{ order.code }}</td>
          <td>{{ order.customer.phoneNumber }}</td>
          <td>{{ getItemsString(order.orderItems) }}</td>
          <td>{{ order.totalCost }}</td>
          <td>{{ order.totalPaid }}</td>
          <td>
            <span *ngIf="!order.isDelivery; else delivery">Walk In</span>
            <ng-template #delivery>
              <span [innerHTML]="getDeliveryLocation(order)"></span>
            </ng-template>
          </td>
          <td>
                <span nz-tooltip="" [nzTooltipTitle]="getStatusTooltip(order)">
                  {{ order.status | titlecase }}
                </span>
          </td>
          <td
            nz-tooltip="order time"
            [nzTooltipTitle]="order.createdAt | date : 'shortTime'"
          >
            {{ order.createdAt | date : "mediumDate" }}
          </td>
        </tr>
        <tr [nzExpand]="expandSet.has(order.id)">
          <div style="padding-left: 56px">
            <div style="display: flex; justify-content: space-between">
              <span><span>መልእክት: </span>{{ order.note }}</span>
              <span>
                <span>Slips:</span>
                <ng-container
                  *ngFor="let slip of order.slips; let i = index"
                >
                  <img
                    class="payment-slip"
                    [src]="sanitizer.bypassSecurityTrustResourceUrl(slip.image)"
                    alt="payment slip"
                    (click)="openViewSlipDialog(slip)"
                  />
                </ng-container>
              </span>
            </div>
          </div>
        </tr>
      </ng-container>
      </tbody>
    </nz-table>
  </nz-content>
</nz-layout>

<nz-modal
  [(nzVisible)]="validatedSlipVisible"
  [nzTitle]="'View Slip'"
  [nzFooter]="null"
  nzWidth="600px"
  (nzOnCancel)="onSlipViewCancelled()"
  [nzBodyStyle]="{ padding: '0px' }"
>
  <ng-container *nzModalContent>
    <app-view-slip [slip]="slipToView"> </app-view-slip>
  </ng-container>
</nz-modal>
