<div class="background">
    <div class="logo">
        <img src="../../../assets/logo.svg" alt="logo" width="79px">
        <h1 nz-typography>Shop Admin</h1>
    </div>

    <nz-tabset>
        <nz-tab nzTitle="Login">
            <form [formGroup]="form" nz-form nzLayout="vertical" (ngSubmit)="onSubmit()">
                <nz-form-item nzRequired>
                    <!-- <nz-form-label>Phone Number</nz-form-label> -->
                    <nz-form-control nzErrorTip="Phone number is required!">
                        <nz-input-group nzPrefixIcon="mobile">
                            <input nz-input type="text" placeholder="Phone Number" formControlName="phoneNumber" name="phoneNumber">
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item nzRequired>
                    <!-- <nz-form-label>Password</nz-form-label> -->
                    <nz-form-control nzErrorTip="Password is required!">
                        <nz-input-group nzPrefixIcon="lock" [nzSuffix]="suffixTemplate">
                            <input nz-input [type]="passwordVisible ? 'text' : 'password'" placeholder="Password" formControlName="password" name="password">
                        </nz-input-group>
                        <ng-template #suffixTemplate>
                            <span nz-icon
                              [nzType]="passwordVisible ? 'eye-invisible' : 'eye'"
                              (click)="passwordVisible = !passwordVisible">
                            </span>
                        </ng-template>
                    </nz-form-control>
                </nz-form-item>
                <div nz-row>
                    <div nz-col [nzSpan]="12" style="text-align: left;">
                      <!-- <label nz-checkbox formControlName="remember">
                        <span>Remember me</span>
                      </label> -->
                    </div>
                    <div nz-col [nzSpan]="12"  style="text-align: right;">
                      <a class="login-form-forgot" routerLink="/reset-password">Forgot password</a>
                    </div>
                </div>
                <div style="text-align: center; margin-top:22px;">
                    <button type="submit" nz-button nzType="primary" [disabled]="form.invalid">Login</button>
                </div>
            </form>
        </nz-tab>
      </nz-tabset>

</div>