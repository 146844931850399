<form nz-form [formGroup]="form" nzLayout="vertical" (ngSubmit)="onSubmit()">
    <div style="padding: 24px 24px 0px;">
        <nz-form-item>
            <nz-form-label nzRequired>Method</nz-form-label>
            <nz-form-control>
                <nz-select formControlName="method" nzPlaceHolder="Method" >
                    <nz-option *ngFor="let method of methods" 
                        [nzValue]="method" 
                        [nzLabel]="method | titlecase">
                    </nz-option>
                </nz-select>
            </nz-form-control>
        </nz-form-item>
        <div style="display: flex; justify-content: space-between;">
            <nz-form-item style="width: 160px;">
                <nz-form-label nzRequired>From (KM)</nz-form-label>
                <nz-form-control nzErrorTip="Please input From!">
                    <input nz-input type="number" formControlName="from" name="from" placeholder="From" (ngModelChange)="validateRange()">
                </nz-form-control>
            </nz-form-item> 
            <nz-form-item style="width: 160px;">
                <nz-form-label nzRequired>To (KM)</nz-form-label>
                <nz-form-control [nzErrorTip]="toErrorTpl">
                    <input nz-input type="number" formControlName="to" name="to" placeholder="To" (ngModelChange)="validateRange()">
                    <ng-template #toErrorTpl let-control>
                        <ng-container *ngIf="control.hasError('required')">"To is required."</ng-container>
                        <ng-container *ngIf="control.hasError('range')">Should be greater than "From".</ng-container>
                      </ng-template>
                </nz-form-control>
            </nz-form-item> 
        </div>
        <nz-form-item>
            <nz-form-label nzRequired>Price</nz-form-label>
            <nz-form-control nzErrorTip="Please input Price">
                <input nz-input type="number" formControlName="price" name="price" placeholder="Price">
            </nz-form-control>
        </nz-form-item> 
    </div>
    <div class="footer">
        <button nz-button nzType="default" type="button" (click)="onCancel()">Cancel</button>
        <button nz-button nzType="primary" type="submit" [nzLoading]="isSubmitLoading" [disabled]="form.invalid">Submit</button>
    </div>
</form>