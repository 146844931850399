import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Apollo, gql } from 'apollo-angular';
import { NzMessageService } from 'ng-zorro-antd/message';
import { productsQuery } from 'src/app/products/product-list/product-list.component';
import { Order, OrderItem, OrderStatus } from '../order';
import { acceptOrderMutation, rejectOrderMutation } from '../order-list/order-list.component';

interface OrderItemUpdate {
  id: number;
  status:  'ACCEPTED' | 'REJECTED' | 'COMPLETED';
  rejectionReason?: string;
}

@Component({
  selector: 'app-manage-order',
  templateUrl: './manage-order.component.html',
  styleUrls: ['./manage-order.component.css']
})
export class ManageOrderComponent implements OnInit {

  @Input() order!: Order;
  @Output() formSubmitted: EventEmitter<boolean> = new EventEmitter();
  @Output() formCancelled: EventEmitter<void> = new EventEmitter();

  isSubmitLoading = false;

  orderItemRejectionReasonFormVisible = false;
  orderItemToReject: OrderItem | null = null;
  orderItemRejectionReason: string | null = null;

  orderRejectionReasonFormVisible = false;
  orderRejectionReason: string | null = null;

  orderItemUpdates: Map<number, OrderItemUpdate> = new Map();

  constructor(private apollo: Apollo, private msg: NzMessageService) { }

  ngOnInit(): void {
  }

  onSubmit(): void {
    this.isSubmitLoading = true;
    // Manage Order
    const updates: OrderItemUpdate[] = Array.from(this.orderItemUpdates.values());
    const manageOrderSub = this.apollo.mutate({
      mutation: manageOrderMutation,
      variables: {
        manageOrderInput: {
          code: this.order.code,
          orderItemUpdates: updates,
        }
      }
    }).subscribe(result => { 
      this.isSubmitLoading = false;
      this.formSubmitted.emit(true);
      this.msg.success('Order managed');
      manageOrderSub.unsubscribe();
    }, err => {
      this.isSubmitLoading = false;
      this.msg.error(err.message)
      console.error({err});
      manageOrderSub.unsubscribe();
    })
    // setTimeout(() => {
    //   this.isSubmitLoading = false;
    //   this.formSubmitted.emit(true);
    // }, 3000)
  }
  
  onCancel(): void {
    this.formCancelled.emit();
  }

  getOrderItemStatus(oi: OrderItem): 'ACCEPTED' | 'REJECTED' | 'COMPLETED' | 'NONE' {
    if (this.orderItemUpdates.has(oi.id)) {
      return this.orderItemUpdates.get(oi.id)!.status;
    }
    return oi.rejectedBy ? 'REJECTED' : oi.completedBy ? 'COMPLETED' : oi.acceptedBy ? 'ACCEPTED' : 'NONE'
  }

  getDeliveryLocation(order: Order): string {
    if (order.deliveryLocation?.address) {
      return order.deliveryLocation?.address
    }
    const latLng = order.deliveryLocation?.latitude + ',' + order.deliveryLocation?.longitude;
    return `<a href="https://www.google.com/maps/search/?api=1&query=${latLng}" target="_blank">${latLng}</a>`
    return order.deliveryLocation?.latitude + ", " + order.deliveryLocation?.longitude
  }

  isCompleteItemAllowed(oi: OrderItem): boolean {
    return !this.orderItemUpdates.has(oi.id);
  }

  getRejectionReason(oi: OrderItem): string {
    if (this.orderItemUpdates.has(oi.id)) {
      return this.orderItemUpdates.get(oi.id)!.rejectionReason!
    }
    return oi.rejectionReason || '';
  }

  getTotalForOrder(): string {
    let itemsCost = 0;
    itemsCost = this.order.orderItems
      .filter(oi => !oi.rejectedBy)
      .map(oi => oi.product.price * oi.quantity)
      .reduce((itemsCost, cost) => itemsCost + cost, 0);
    
    const updates: OrderItemUpdate[] = Array.from(this.orderItemUpdates.values());
    const rejectedItemIds = updates.filter(u => u.status === 'REJECTED').map(i => i.id);
    
    const rejectedItems = this.order.orderItems.filter(oi => rejectedItemIds.includes(oi.id));
    let deduction = rejectedItems.map(i => i.product.price * i.quantity)
    .reduce((pv, cv) => pv + cv, 0);
    return (itemsCost - deduction).toString();
  }
  isOrderRejected(): boolean {
    return this.order.status === OrderStatus.REJECTED;
  }
  isOrderNew(): boolean {
    return this.order.status === OrderStatus.NEW; 
  }

  acceptItem(oi: OrderItem): void {
    this.orderItemUpdates.set(oi.id, { id: oi.id, status: 'ACCEPTED' })
  }

  completeItem(oi: OrderItem): void {
    this.orderItemUpdates.set(oi.id, { id: oi.id, status: 'COMPLETED' })
  }

  rejectItem(oi: OrderItem): void {
    this.orderItemToReject = oi;
    this.orderItemRejectionReasonFormVisible = true;
  }

  cancelRejectItem(): void {
    this.orderItemToReject = null;
    this.orderItemRejectionReason = null;
    this.orderItemRejectionReasonFormVisible = false;
  }

  submitRejectItem(): void {
    if (this.orderItemToReject && this.orderItemRejectionReason) {
      this.orderItemUpdates.set(this.orderItemToReject.id, { 
        id: this.orderItemToReject.id,
        status: 'REJECTED', 
        rejectionReason: this.orderItemRejectionReason 
      })
    }
    this.orderItemToReject = null;
    this.orderItemRejectionReason = null;
    this.orderItemRejectionReasonFormVisible = false;
  }

  acceptOrder(): void {
    const acceptOrderSub = this.apollo.mutate({
      mutation: acceptOrderMutation,
      variables: {
        code: this.order.code
      }
    }).subscribe(result => {
      this.formSubmitted.emit();
      this.msg.success('Order accepted')
      acceptOrderSub.unsubscribe();
    }, err=> {
      this.msg.error(err.message)
      console.error({err});
      acceptOrderSub.unsubscribe();
    })
  }

  rejectOrder(): void {
    this.orderRejectionReason = null;
    this.orderRejectionReasonFormVisible = true;
  }

  cancelRejectOrder(): void {
    this.orderRejectionReason = null;
    this.orderRejectionReasonFormVisible = false;
  }

  submitRejectOrder(): void {
    if (this.order && this.orderRejectionReason) {
      const rejectOrderSub = this.apollo.mutate({
        mutation: rejectOrderMutation,
        variables: {
          code: this.order.code,
          reason: this.orderRejectionReason,
        }
      }).subscribe(result => {
        this.orderRejectionReason = null;
        this.orderRejectionReasonFormVisible = false;
        this.formSubmitted.emit();
        this.msg.success('Order rejected');
        rejectOrderSub.unsubscribe();
      }, err => {
        this.msg.error(err.message)
        console.error({err});
        rejectOrderSub.unsubscribe();
      })
    }
  }
}
interface ManageOrderInput {
  code: string, 
  orderItemUpdates: OrderItemUpdate[]
}
const manageOrderMutation = gql<{ manageOrder: Order }, { manageOrderInput: ManageOrderInput }>`
  mutation ManageOrder($manageOrderInput: ManageOrderInput!) {
    manageOrder(manageOrderInput: $manageOrderInput) {
      id,
      status,
      updatedAt,
      orderItems {
          product { 
            id, 
            name,
            quantity,
            price,
            code, 
          },
          quantity,
          acceptedBy,
          completedBy,
          rejectedBy,
          rejectionReason,
        },
    }
  }
`
