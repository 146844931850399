<nz-layout style="height: calc(100vh - 72px); overflow-y: scroll;">
  <nz-header style="background-color: white;">
    <h4 nz-typography style="display: inline-block;">Order Stats</h4>
    <div class="container">
      <div class="child">
        <div class="title">
          <p>Orders Today</p>
          <span nz-icon nzType="info-circle" nzTheme="outline"></span>
        </div>
        <p class="value">{{ordersToday}}</p>
      </div>
      <div class="child">
        <div class="title">
          <p>Orders this Week</p>
          <span nz-icon nzType="info-circle" nzTheme="outline"></span>
        </div>
        <p class="value">{{ordersThisWeek}}</p>
      </div>
      <div class="child">
        <div class="title">
          <p>Orders this Month</p>
          <span nz-icon nzType="info-circle" nzTheme="outline"></span>
        </div>
        <p class="value">{{ordersThisMonth}}</p>
      </div>
      <div class="child">
        <div class="title">
          <p>Order this Year</p>
          <span nz-icon nzType="info-circle" nzTheme="outline"></span>
        </div>
        <p class="value">{{ordersThisYear}}</p>
      </div>
    </div>
  </nz-header>
  <nz-content style="padding-inline: 24px; margin-top: 35px; margin-inline: auto; width: 1184px" >

  </nz-content>
</nz-layout>
