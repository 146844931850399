<nz-layout style="height: calc(100vh - 72px);">
    <nz-header style="background-color: white; margin-bottom: 24px; margin-top: 35px; margin-inline: auto; width: 1184px;">
        <h5 nz-typography style="display: inline-block;">Notification</h5>
    </nz-header>
    <nz-content style="overflow-y: scroll; width: 1184px; margin-inline: auto;">
        <nz-list nzBordered>
            <nz-list-item *ngFor="let notification of notifications$ | async" style="background-color: white; padding-inline: 50px;">
                <nz-list-item-meta>
                    <nz-list-item-meta-title>
                        <a href="https://ng.ant.design">{{ getNotificationTitle(notification) }}</a>
                    </nz-list-item-meta-title>
                    <nz-list-item-meta-description>
                        {{ getNotificationContent(notification) }}
                    </nz-list-item-meta-description>
                </nz-list-item-meta>
              <!-- {{ notification.title }} -->
              <ul nz-list-item-actions>
                <nz-list-item-action *ngIf="!notification.isRead">
                    <a nz-icon nzType="check" style="font-size:20px; margin-right: 4px;"
                        nz-tooltip nzTooltipTitle="Mark as Read" (click)="readNotification(notification)">
                    </a>
                </nz-list-item-action>
                <nz-list-item-action>
                    <a nz-icon nzType="delete" style="font-size: 20px; margin-left: 4px;"
                        nz-tooltip nzTooltipTitle="Delete" (click)="deleteNotification(notification)">
                    </a>
                </nz-list-item-action>
              </ul>
            </nz-list-item>
        </nz-list>
        <div class="loadmore">
            <!-- <button nz-button>Load more</button> -->
        </div>
    </nz-content>
</nz-layout>
