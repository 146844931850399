import { Component, OnInit } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { EMPTY, map, Observable } from 'rxjs';
import { DeliveryMethod, DeliveryPrice } from '../delivery-price';

export const pricesQuery = gql<{deliveryPriceList: DeliveryPrice[]}, void>`
  query {
    deliveryPriceList {
      id,
      method,
      rangeStart
      rangeEnd
      price
    }
  }
`

@Component({
  selector: 'app-price-list',
  templateUrl: './price-list.component.html',
  styleUrls: ['./price-list.component.css']
})
export class PriceListComponent implements OnInit {

  prices$: Observable<DeliveryPrice[]> = EMPTY;

  priceToEdit: DeliveryPrice | null = null;
  priceFormVisible = false;

  constructor(private apollo: Apollo) { }

  ngOnInit(): void {
    this.prices$ = this.apollo.watchQuery({
      query: pricesQuery
    }).valueChanges.pipe(
      map(result => result.data?.deliveryPriceList),
      map(prices => [...prices].sort((a, b) => a.rangeStart - b.rangeStart))
    );
  }

  addDeliveryPrice(): void {
    this.priceFormVisible = true;
  }

  editDeliveryPrice(user: DeliveryPrice): void {
    this.priceToEdit = user;
    this.priceFormVisible = true;
  }

  onFormCancelled(): void {
    this.priceFormVisible = false;
    setTimeout(() => {
      this.priceToEdit = null;
    },1000)
  }

  onFormSubmitted(success: boolean): void {
      //TODO: Refactor code to show or hide form based on result status
      this.priceFormVisible = false;
      setTimeout(() => {
        this.priceToEdit = null;
      },1000)  
  }
}
