<form nz-form nzLayout="vertical" (ngSubmit)="onSubmit()">
    <div style="margin-bottom: 30px;">
        <span style="font-weight: 600;">Customer</span>: {{order.customer.name}}
    </div>
    <div nz-row nzGutter="16" class="header-row">
        <div nz-col nzSpan="8">
            Product
        </div>
        <div nz-col nzSpan="3">
            Quantity (In Stock)
        </div>
        <div nz-col nzSpan="3">
            Price
        </div>
        <div nz-col nzSpan="3">
            Amount
        </div>
        <div nz-col nzSpan="7">
            <!-- Accept Column -->
            <!-- Reject Column -->
        </div>
    </div>
    <div nz-row [nzGutter]="16" *ngFor="let oi of order.orderItems;let i = index" class="row">
        <div nz-col [nzSpan]="8">
            <!-- 1. [CODE] Nike -->
            {{  i+1 + '. [' +  oi.product.code + '] ' + oi.product.name }}
        </div>
        <div nz-col [nzSpan]="3">
            <!-- RequiredAmount AmountInStock -->
            {{ oi.quantity + ' (' + oi.product.quantity + ')' }}
        </div>
        <div nz-col [nzSpan]="3">
            {{ oi.product.price }}
        </div>
        <div nz-col [nzSpan]="3">
            {{ oi.quantity * oi.product.price }}
        </div>
        <div nz-col [nzSpan]="7">
            <ng-container [ngSwitch]="getOrderItemStatus(oi)">
                <div *ngSwitchCase="'NONE'">
                    <button nz-button nzSize="small" type="button" (click)="acceptItem(oi)" style="margin-right: 10px; color: #52C41A; border-color: #52C41A;">
                        <span nz-icon nzType="check"></span>
                        Accept
                    </button>
                    <button nz-button nzSize="small" nzDanger type="button" (click)="rejectItem(oi)">
                        <span nz-icon nzType="close"></span>
                        Reject
                    </button>
                </div>
                <div *ngSwitchCase="'ACCEPTED'">
                    <span style="width: 80px; display: inline-block; margin-right: 30px; color: #52C41A;">Accepted</span>
                    <button *ngIf="isCompleteItemAllowed(oi) && order.slips" nz-button nzSize="small" type="button" (click)="completeItem(oi)" style="color: #52C41A; border-color: #52C41A;">
                        <span nz-icon nzType="check"></span>
                        Complete
                    </button>
                </div>
                <div *ngSwitchCase="'REJECTED'" style="display: flex;">
                    <span style="width: 80px; display: block; margin-right: 30px; color: #FF4D4F;">Rejected</span>
                    <span
                        style="display: block; max-width: 130px; overflow:hidden; text-overflow: ellipsis; white-space:nowrap"
                        nz-tooltip [nzTooltipTitle]="getRejectionReason(oi)">
                        {{ getRejectionReason(oi) }}
                    </span>
                </div>
                <div *ngSwitchCase="'COMPLETED'">
                    <span style="color: #52C41A;">Completed</span>
                </div>
            </ng-container>
        </div>
    </div>
    <div nz-row nzGutter="16" style="margin-bottom: 30px;">
        <div nz-col nzSpan="14" style="text-align: right; font-weight: 600;">
            Total
        </div>
        <div nz-col nzSpan="3">
            {{ getTotalForOrder() }}
        </div>
        <div nz-col nzSpan="7">
            <span *ngIf="isOrderRejected()">{{ order.rejectionReason }}</span>
            <div *ngIf="isOrderNew() && orderItemUpdates.size === 0">
                <button nz-button nzSize="small" type="button" style="margin-right: 10px; color: #52C41A; border-color: #52C41A;" (click)="acceptOrder()">
                    <span nz-icon nzType="check"></span>
                    Accept All
                </button>
                <button nz-button nzSize="small" nzDanger type="button" (click)="rejectOrder()">
                    <span nz-icon nzType="close"></span>
                    Reject All
                </button>
            </div>
        </div>
    </div>

    <div nz-row nzGutter="16" class="expander">
        <div nz-col nzSpan="12">
            <ng-container *ngIf="order.note">
                <div style="font-weight: 600;">Note</div>
                <div>{{ order.note }}</div>
            </ng-container>
        </div>
        <div nz-col nzSpan="12">
            <ng-container *ngIf="order.isDelivery; else pickup">
                <span style="font-weight: 600;">Delivered To</span>: <span [innerHTML]="getDeliveryLocation(order)"></span>
            </ng-container>
            <ng-template #pickup>
                <span style="font-weight: 600;">Delivery Type</span>: Walk In
            </ng-template>
        </div>
    </div>

    <div class="footer">
        <button nz-button nzType="default" type="button" (click)="onCancel()">Cancel</button>
        <button nz-button nzType="primary" type="submit" [nzLoading]="isSubmitLoading">Submit</button>
    </div>
</form>

<nz-modal
    [(nzVisible)]="orderItemRejectionReasonFormVisible"
    [nzTitle]="'Rejecting Product \'' + orderItemToReject?.product?.name + '\''"
    (nzOnCancel)="cancelRejectItem()"
    nzOkText="Reject"
    nzOkDanger="true"
    [nzOkDisabled]="!orderItemRejectionReason"
    [nzCancelText]="null"
    (nzOnOk)="submitRejectItem()">
    <div *nzModalContent>
        <form nz-form nzLayout="vertical">
            <nz-form-item nzRequired>
                <nz-form-label>Rejection Reason</nz-form-label>
                <nz-form-control nzErrorTip="Reason is required!">
                    <textarea nz-input placeholder="Reason" [(ngModel)]="orderItemRejectionReason" [ngModelOptions]="{ standalone: true }" required #model="ngModel"></textarea>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
</nz-modal>


<nz-modal
    [(nzVisible)]="orderRejectionReasonFormVisible"
    [nzTitle]="'Rejecting Order ' + order.code"
    (nzOnCancel)="cancelRejectOrder()"
    nzOkText="Reject"
    nzOkDanger="true"
    [nzOkDisabled]="!orderRejectionReason"
    [nzCancelText]="null"
    (nzOnOk)="submitRejectOrder()">
    <div *nzModalContent>
        <form nz-form nzLayout="vertical">
            <nz-form-item nzRequired>
                <nz-form-label>Rejection Reason</nz-form-label>
                <nz-form-control nzErrorTip="Reason is required!">
                    <textarea nz-input placeholder="Reason" [(ngModel)]="orderRejectionReason" [ngModelOptions]="{ standalone: true }" required #model="ngModel"></textarea>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
</nz-modal>
