import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Apollo, gql } from 'apollo-angular';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzUploadChangeParam, NzUploadFile } from 'ng-zorro-antd/upload';
import { Observable } from 'rxjs';
import { User } from 'src/app/users/user';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  profileImageUrl = '';
  userName = ''

  step = 0;
  currentPhoneNumber = '';
  newPhoneNumber = '';
  code = '';

  passwordForm: FormGroup<{
    currentPassword: FormControl, 
    newPassword: FormControl, 
    confirmNewPassword: FormControl,
  }>;


  imageUploading = false;
  fileList: NzUploadFile[] = [];
  onFileRemoved: (file: NzUploadFile) => boolean | Observable<boolean> = () => {
    // TODO: Handle remove
    this.changeImage(null);
    this.profileImageUrl = ''
    return true;
  }
  getUploadUrl(): string {
    return environment.baseUrl + 'upload'
  }
  getHeaders() {
    return {
      Source: 'user_profile'
    }
  }
  handleChange(e: NzUploadChangeParam): void {
    switch (e.file.status) {
      case 'uploading':
        this.imageUploading = true;
        break;
      case 'done':
        // Get this url from response.
        this.imageUploading = false;
        const response = e.file.response
        if (response && response.success) {
          // Set image in backend
          this.changeImage(response.url);
          this.profileImageUrl = response.url;
        }
        break;
      case 'error':
        this.msg.error('Network error');
        this.imageUploading = false;
        break;
    }
  }

  constructor(
    private fb: FormBuilder, 
    private authService: AuthService, 
    private msg: NzMessageService,
    private apollo: Apollo,
  ) {
    this.passwordForm = this.fb.nonNullable.group({
      currentPassword: ['', [Validators.required]],
      newPassword: ['', [Validators.required]],
      confirmNewPassword: ['', [this.confirmValidator]],
    });
  }

  ngOnInit(): void {
    const user = this.authService.getUser();
    this.profileImageUrl = user?.image || '';
    this.userName = user?.name || '';
    this.currentPhoneNumber = user?.phoneNumber || '';
  }

  changeImage(imageUrl: string | null) {
    const changeImageSub = this.apollo.mutate({
      mutation: editProfileMutation,
      variables: {
        imageUrl,
      }
    }).subscribe(result => {
      if(result.data) {
        const user = this.authService.getUser()!;
        user.image = result.data.editProfile.image;
        this.authService.setUser(user);
        this.msg.success('Image Changed');
      }
      changeImageSub.unsubscribe();  
    }, err => {
      console.error({err});
      this.msg.error(err.message);
      changeImageSub.unsubscribe();  
    });
  }

  changeName(name: Event): void {
    const value = (name.target as HTMLInputElement).value;

    const changeNameSub = this.apollo.mutate({
      mutation: editProfileMutation,
      variables: {
        name: value,
      }
    }).subscribe(result => {
      if(result.data) {
        const user = this.authService.getUser()!;
        user.name = result.data.editProfile.name;
        this.authService.setUser(user);
        this.msg.success('Name Changed');
      }
      changeNameSub.unsubscribe();  
    }, err => {
      console.error({err});
      this.msg.error(err.message);
      changeNameSub.unsubscribe();  
    });
  }

  confirmValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return { error: true, required: true };
    } else if (control.value !== this.passwordForm.controls.newPassword.value) {
      return { confirm: true, error: true };
    }
    return {};
  };

  validateConfirmPassword(): void {
    setTimeout(() => this.passwordForm.controls.confirmNewPassword.updateValueAndValidity());
  }

  changePassword() {
    const changePasswordSub = this.apollo.mutate({
      mutation: changePasswordMutation,
      variables: {
        oldPassword: this.passwordForm.controls.currentPassword.value,
        newPassword: this.passwordForm.controls.newPassword.value,
      }
    }).subscribe(result => {
      if( result.data?.changePassword.success) {
        this.msg.success('Password Changed');
      }
      changePasswordSub.unsubscribe();  
    }, err => {
      console.error({err});
      this.msg.error(err.message);
      changePasswordSub.unsubscribe();  
    });
  }

  changePhoneNumber() {
    this.step = 1;
  }
  cancelChangePhoneNumber() {
    this.step = 0
    this.code = ''
    this.newPhoneNumber = '';
  }
  getVerificationCode() {
    const getCodeSub = this.apollo.mutate({
      mutation: getVerificationCodeMutation,
      variables: {
        phoneNumber: ""+this.newPhoneNumber
      }
    }).subscribe(result => {
      if( result.data?.sendCodeForPhoneNumberChange.success) {
        this.step = 2
        this.msg.success('Code Sent');
      }
      getCodeSub.unsubscribe();  
    }, err => {
      console.error({err});
      this.msg.error(err.message);
      getCodeSub.unsubscribe();  
    });
  }
  submitChangePhoneNumber() {
    const changePhoneSub = this.apollo.mutate({
      mutation: changePhoneNumberMutation,
      variables: {
        code: this.code,
        newPhoneNumber: ""+this.newPhoneNumber
      }
    }).subscribe(result => {
      if (result.data?.changePhoneNumber.success) {
        const user = this.authService.getUser()!;
        user.phoneNumber = this.newPhoneNumber;
        this.authService.setUser(user);
        this.currentPhoneNumber = this.newPhoneNumber;
        this.step=0
        this.msg.success('Phone Number Changed');
        
      }
      changePhoneSub.unsubscribe();  
    }, err => {
      console.error({err});
      this.msg.error(err.message);
      changePhoneSub.unsubscribe();  
    });
  }
}


const editProfileMutation = gql<{editProfile: User}, {name?: string, imageUrl?: string | null}>`
  mutation EditProfile($name: String, $imageUrl: String) {
    editProfile(name: $name, imageUrl: $imageUrl) {
      id,
      name,
      image,
    }
  }
`

const getVerificationCodeMutation = gql<{sendCodeForPhoneNumberChange: { success: boolean }}, { phoneNumber: string}>`
  mutation SendCodeForPhoneNumberChange($phoneNumber: String!) {
    sendCodeForPhoneNumberChange(phoneNumber: $phoneNumber) {
      success
    }
  }
`
const changePhoneNumberMutation = gql<{changePhoneNumber: { success: boolean, message?: string}}, { code: string, newPhoneNumber: string}>`
  mutation ChangePhoneNumber($code: String!, $newPhoneNumber: String!) {
    changePhoneNumber(code: $code, newPhoneNumber: $newPhoneNumber) {
      success
      message
    }
  }
`

const changePasswordMutation = gql<{changePassword: { success: boolean, message?: string}}, { oldPassword: string, newPassword: string}>`
  mutation ChangePassword($oldPassword: String!, $newPassword: String!) {
    changePassword(oldPassword: $oldPassword, newPassword: $newPassword) {
      success
      message
    }
  }
`
