import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import { ProductListComponent } from './product-list/product-list.component';
import { ProductItemComponent } from './product-item/product-item.component';
import { ProductFormComponent } from './product-form/product-form.component';


import { Route, RouterModule } from '@angular/router';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { CategoryFormComponent } from './category-form/category-form.component';
import { ImportStockFormComponent } from './import-stock-form/import-stock-form.component';
import { AuthGuard } from '../auth/auth.guard';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { BulkDiscountFormComponent } from './bulk-discount-form/bulk-discount-form.component';


const routes: Route[] = [
  { path: 'products', component: ProductListComponent, canActivate: [AuthGuard] }
]

@NgModule({
  declarations: [
    ProductListComponent,
    ProductItemComponent,
    ProductFormComponent,
    CategoryFormComponent,
    ImportStockFormComponent,
    BulkDiscountFormComponent
  ],
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        ReactiveFormsModule,

        NzLayoutModule,
        NzPageHeaderModule,
        NzSpaceModule,
        NzButtonModule,
        NzIconModule,
        NzMenuModule,
        NzGridModule,
        NzCardModule,
        NzModalModule,
        NzTypographyModule,
        NzFormModule,
        NzInputModule,
        NzSelectModule,
        NzUploadModule,
        NzMessageModule,
        FormsModule,
    ]
})
export class ProductsModule { }
