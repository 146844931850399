import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Apollo, gql } from 'apollo-angular';
import { NzMessageService } from 'ng-zorro-antd/message';
import { EMPTY, map, Observable, of, switchMap } from 'rxjs';
import { Category, Product } from '../product';

export const productsQuery = gql<{ products: Product[] }, void>`
    query Products {
        products {
            id,
            name,
            description,
            code,
            price,
            quantity,
            image,
            category {
                id,
                name,
                image
            },
            discount {
                id,
                discountType,
                amount
            }
        }
    }
`

const categoriesQuery = gql<{ categories: Category[] }, void>`
    query Categories {
        categories {
            id,
            name,
            image,
        }
    }
`

const archiveCategoryMutation = gql<{ archiveCategory: Category }, { id: number }>`
    mutation ArchiveCategory($id: Int!) {
        archiveCategory(id: $id) {
            id,
        }
    }
`

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.css']
})
export class ProductListComponent implements OnInit {

    productFormVisible = false;
    discountFormVisible = false;
    productToEdit: Product | null = null;

    categoryFormVisible = false;
    categoryToEdit: Category | null = null;
    archiveCategoryModalVisible = false;
    categoryToArchive: Category | null = null;

    importFormVisible = false;

    categories$: Observable<Category[]> = EMPTY;
    products$: Observable<Product[]> = EMPTY;


    constructor(private route: ActivatedRoute, private apollo: Apollo, private changeDetectorRef: ChangeDetectorRef, private msg: NzMessageService) { }

    ngOnInit(): void {

        this.categories$ = this.apollo
            .watchQuery({
                query: categoriesQuery,
            })
            .valueChanges.pipe(map((result) => result?.data?.categories));


        this.products$ = this.route.queryParamMap.pipe(
            map(pm => pm.get('category')),
            map(category => {
                return this.apollo
                    .watchQuery({
                        query: productsQuery,
                    })
                    .valueChanges.pipe(
                        map((result) => result?.data?.products),
                        map(products => this.filterProductByCategory(products, category))
                    )
            }),
            switchMap(p => p)
        );
    }

    private filterProductByCategory(products: Product[], categoryId: string | null): Product[] {
        if (categoryId == null) {
            return products
        } else {
            return products.filter(p => p.category.id === +categoryId)
        }
    }

    addProduct(): void {
        this.productFormVisible = true;
    }

    editProduct(product: Product): void {
        this.productToEdit = product;
        this.productFormVisible = true;
        // needed because sometimes the emit event and change detection happen in different cycles
        // hence the form pops up when new change detection event happens such as clicking something
        this.changeDetectorRef.detectChanges();
    }

    onFormCancelled(): void {
        this.productFormVisible = false;
        this.discountFormVisible = false;
        this.productToEdit = null;
    }

    onFormSubmitted(success: boolean): void {
        //TODO: Refactor code to show or hide form based on result status
        this.productFormVisible = false;
        this.discountFormVisible = false;
        this.productToEdit = null;
    }

    addCategory(): void {
        this.categoryFormVisible = true;
    }

    editCategory(category: Category): void {
        this.categoryToEdit = category;
        this.categoryFormVisible = true;
    }

    onCategoryFormCancelled(): void {
        this.categoryFormVisible = false;
        this.categoryToEdit = null;
    }

    onCategoryFormSubmitted(success: boolean): void {
        //TODO: Refactor code to show or hide form based on result status
        this.categoryFormVisible = false;
        this.categoryToEdit = null;
    }

    archiveCategory(category: Category): void {
        this.categoryToArchive = category;
        this.archiveCategoryModalVisible = true;
    }

    onArchiveCategoryConfirmed(): void {
        if (this.categoryToArchive) {
            const archiveCategorySub = this.apollo.mutate({
              mutation: archiveCategoryMutation,
              variables: {
                id: this.categoryToArchive.id
              }
            }).subscribe(result => {
              const categoryId = result.data?.archiveCategory.id;

              const data = this.apollo.client.cache.readQuery({query: categoriesQuery});
              if (data) {

                const index = data.categories.findIndex(category => category.id === categoryId);
                if (index > -1) {
                  const categories = [...data.categories];
                  categories.splice(index, 1);
                  // Write our data back to the cache.
                  this.apollo.client.writeQuery({ query: categoriesQuery, data: { categories } });
                }
              }
              this.archiveCategoryModalVisible = false;
              this.categoryToArchive = null;
              this.msg.success('Category Archived')
              archiveCategorySub.unsubscribe();
            }, err => {
              // this.isSubmitLoading = false;
              this.msg.error(err.message)
              console.error({err});
              archiveCategorySub.unsubscribe();
            })
          }
    }

    onArchiveCategoryCancelled(): void {
        this.archiveCategoryModalVisible = false;
        this.categoryToArchive = null;
    }

    importStock(): void {
        this.importFormVisible = true;

    }

    onImportFormCancelled(): void {
        this.importFormVisible = false;
    }

    onImportFormSubmitted(success: boolean): void {
        //TODO: Refactor code to show or hide form based on result status
        this.importFormVisible = false;
    }

    bulkDiscount() {
      this.discountFormVisible = true;
    }
}
