<nz-layout style="height: calc(100vh - 72px); overflow-y: scroll;">
    <nz-page-header nzTitle="Resources" [nzGhost]="false">
        <nz-page-header-extra>
          <button nz-button nzType="primary" (click)="addResource()">
              <span nz-icon nzType="plus"></span>
              Add Resource
          </button>
      </nz-page-header-extra>
    </nz-page-header>
    <nz-content style="padding-inline: 24px; margin-top: 35px; margin-inline: auto; width: 1184px" >
        <nz-table #basicTable [nzData]="(resources$ | async) || []" [nzPageSize]="20" nzTableLayout="fixed">
            <thead>
              <tr>
                <th nzWidth="100px">Type</th>
                <th nzWidth="250px">Title</th>
                <th nzEllipsis>Description</th>
                <th nzWidth="100px">File Count</th>
                <th nzWidth="130px">Created At</th>
                <th nzWidth="130px">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let resource of basicTable.data" (click)="viewResourceDetail(resource)">
                <td> 
                    {{ getTypeLabel(resource.type) | titlecase }}
                </td>
                <td>{{ resource.title }}</td>
                <td nzEllipsis>{{ resource.description }}</td>
                <td>{{ resource.files?.length || 0 }}</td>
                <td>{{ resource.createdAt | date:'mediumDate' }}</td>
                <td>
                  <a (click)="editResource(resource); $event.stopPropagation()">Edit</a>
                  <nz-divider nzType="vertical"></nz-divider>
                  <a (click)="deleteResource(resource); $event.stopPropagation()">Delete</a>
                </td>
              </tr>
            </tbody>
        </nz-table>
    </nz-content>

</nz-layout>

<nz-modal 
    [(nzVisible)]="resourceFormVisible" 
    [nzTitle]="resourceToEdit ? 'Edit Resource' : 'Add Resource'" 
    [nzFooter]="null" nzWidth="572px" 
    (nzOnCancel)="onFormCancelled()"
    [nzBodyStyle]="{ 'padding': '0px' }">
    <ng-container *nzModalContent>
        <app-resource-form 
            [resource]="resourceToEdit" 
            (formCancelled)="onFormCancelled()" 
            (formSubmitted)="onFormSubmitted($event)">
        </app-resource-form>
      </ng-container>
</nz-modal>

<nz-modal
  [nzVisible]="deleteResourceModalVisible"
  [nzTitle]="'Are you sure you want to delete the resource ' + resourceToDelete?.title + '?'"
  nzOkText="Yes, Delete" nzOkDanger nzOkType="primary" (nzOnOk)="onDeleteConfirmed()"
  nzCancelText="No" (nzOnCancel)="onDeleteCancelled()">
  <ng-container *nzModalContent>
    <span style="padding: 24px; display: block;">This action cannot be undone!</span>
  </ng-container>
</nz-modal>

<nz-modal
  [nzVisible]="!!resourceToViewDetail"
  [nzTitle]="resourceTitle"
  [nzOkText]="null"
  nzCancelText="Close"
  (nzOnCancel)="closeResourceDetail()">
  <ng-container *nzModalContent>
    <app-resource-detail [resource]="resourceToViewDetail!"></app-resource-detail>
  </ng-container>
</nz-modal>
<ng-template #resourceTitle>
  <div style="display: flex; padding-right: 20px;">
    <div>{{resourceToViewDetail?.title}}</div>
    <div style="flex: 1 0 auto;"></div>
    <div><nz-tag *ngIf="resourceToViewDetail">{{ getTypeLabel(resourceToViewDetail.type) | titlecase }}</nz-tag></div>
  </div>
</ng-template>
