import {Component, OnInit} from '@angular/core';
import {Apollo, gql} from "apollo-angular";
import {Order} from "../order";
import {EMPTY, map, Observable} from "rxjs";

export const ordersCountQuery = gql<{ orders: Order[] }, void>`
  query Orders {
    ordersCount
  }
`

@Component({
  selector: 'app-order-stats',
  templateUrl: './order-stats.component.html',
  styleUrls: ['./order-stats.component.css']
})
export class OrderStatsComponent implements OnInit {

  ordersToday: number = 0;
  ordersThisWeek: number = 0;
  ordersThisMonth: number = 0;
  ordersThisYear: number = 0;

  counts$: Observable<any> = EMPTY;

  constructor( private apollo: Apollo) { }

  ngOnInit(): void {
    this.counts$ = this.apollo.watchQuery({
      query: ordersCountQuery
    }).valueChanges.pipe(
      map(result =>  {
        // @ts-ignore
        return result.data.ordersCount;
      }),
    );
    this.counts$.subscribe((data) => {
      this.ordersToday = data[0];
      this.ordersThisWeek = data[1];
      this.ordersThisMonth = data[2];
      this.ordersThisYear = data[3];
    })
  }

}
