<nz-layout>
    <nz-sider class="sider">
        <ul nz-menu nzMode="inline" [nzInlineIndent]=48>
            <li nz-menu-group nzTitle="Category">
                <ul>
                    <li nz-menu-item [routerLink]="['./']" nzSelected="true">All</li>

                    <li nz-menu-item *ngFor="let category of categories$ | async" style="justify-content: space-between; padding-right: 8px;"
                        [routerLink]="['./']" [queryParams]="{category: category.id}">
                        {{ category.name }}
                        <div>
                            <button type="button" nz-button nzType="link" nzSize="small" (click)="editCategory(category)"><span class="actions" nz-icon nzType="edit"></span></button>
                            <button type="button" nz-button nzType="link" nzSize="small" (click)="archiveCategory(category)"><span class="actions" nz-icon nzType="delete"></span></button>
                        </div>
                    </li>



                    <li nz-menu-item (click)="addCategory()">
                        <span nz-icon nzType="plus"></span>New Category
                    </li>
                </ul>
            </li>
        </ul>
    </nz-sider>
    <nz-layout style="height: calc(100vh - 72px);">
        <nz-page-header nzTitle="Products" [nzGhost]="false">
            <nz-page-header-extra>
                <nz-space>
                    <button *nzSpaceItem nz-button (click)="importStock()">
                        <span nz-icon nzType="upload"></span>
                        Import Stock
                    </button>
                    <button *nzSpaceItem nz-button nzType="primary" (click)="bulkDiscount()">
                      <span nz-icon nzType="plus"></span>
                      Bulk Discount
                    </button>
                    <button *nzSpaceItem nz-button nzType="primary" (click)="addProduct()">
                        <span nz-icon nzType="plus"></span>
                        Add Product
                    </button>
                </nz-space>
            </nz-page-header-extra>
        </nz-page-header>
        <nz-content style="padding-inline: 24px; overflow-y: scroll; padding-top: 40px;">
            <div nz-row [nzGutter]="16">
                <div nz-col nzSpan="6" *ngFor="let product of products$ | async" style="margin-bottom: 16px;">
                    <app-product-item [product]="product" (editProduct)="editProduct(product)"></app-product-item>
                </div>
            </div>
        </nz-content>
    </nz-layout>
</nz-layout>

<nz-modal
    [(nzVisible)]="productFormVisible"
    [nzTitle]="productToEdit ? 'Edit Product' : 'Add Product'"
    [nzFooter]="null" nzWidth="572px"
    (nzOnCancel)="onFormCancelled()"
    [nzBodyStyle]="{ 'padding': '0px' }">
    <ng-container *nzModalContent>
        <app-product-form
            [product]="productToEdit"
            (formCancelled)="onFormCancelled()"
            (formSubmitted)="onFormSubmitted($event)">
        </app-product-form>
    </ng-container>
</nz-modal>

<nz-modal
    [(nzVisible)]="discountFormVisible"
    [nzTitle]="'Add Bulk Discount'"
    [nzFooter]="null" nzWidth="572px"
    (nzOnCancel)="onFormCancelled()"
    [nzBodyStyle]="{ 'padding': '0px' }">
    <ng-container *nzModalContent>
        <app-bulk-discount-form
          [products] = "products$ | async"
          (formCancelled)="onFormCancelled()"
          (formSubmitted)="onFormSubmitted($event)">
        </app-bulk-discount-form>
    </ng-container>
</nz-modal>

<nz-modal
    [nzVisible]="categoryFormVisible"
    [nzTitle]="categoryToEdit ? 'Edit Category': 'Add Category'"
    [nzFooter]="null" nzWidth="448px"
    (nzOnCancel)="onCategoryFormCancelled()"
    [nzBodyStyle]="{ 'padding': '0px' }">
    <ng-container *nzModalContent>
        <app-category-form
            [category]="categoryToEdit"
            (formCancelled)="onCategoryFormCancelled()"
            (formSubmitted)="onCategoryFormSubmitted($event)">
        </app-category-form>
    </ng-container>

</nz-modal>


<nz-modal
    [nzVisible]="importFormVisible"
    nzTitle="Import Product Stock"
    [nzFooter]="null" nzWidth="443px"
    (nzOnCancel)="onImportFormCancelled()"
    [nzBodyStyle]="{ 'padding': '0px' }">
    <ng-container *nzModalContent>
        <app-import-stock-form
            (formCancelled)="onImportFormCancelled()"
            (formSubmitted)="onImportFormSubmitted($event)">
        </app-import-stock-form>
    </ng-container>

</nz-modal>

<nz-modal
  [nzVisible]="archiveCategoryModalVisible"
  [nzTitle]="'Are you sure you want to archive the category ' + categoryToArchive?.name + '?'"
  nzOkText="Yes, Archive" nzOkDanger nzOkType="primary" (nzOnOk)="onArchiveCategoryConfirmed()"
  nzCancelText="No" (nzOnCancel)="onArchiveCategoryCancelled()">
  <ng-container *nzModalContent>
    <span style="padding: 24px; display: block;">This action cannot be undone!</span>
  </ng-container>
</nz-modal>
