import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Apollo, gql } from 'apollo-angular';
import { NzMessageService } from 'ng-zorro-antd/message';

import { DeliveryMethod, DeliveryPrice } from '../delivery-price';
import { pricesQuery } from '../price-list/price-list.component';


interface DeliveryPriceInput {
  method: DeliveryMethod;
  rangeStart: number;
  rangeEnd: number;
  price: number;
}

const addDeliveryPriceMutation = gql<{ addDeliveryPrice: DeliveryPrice }, { deliveryPriceInput: DeliveryPriceInput }>`
  mutation AddDeliveryPrice($deliveryPriceInput: DeliveryPriceInput!) {
    addDeliveryPrice(deliveryPriceInput: $deliveryPriceInput) {
      id,
      method,
      rangeStart
      rangeEnd
      price
    }
  }
`
const editDeliveryPriceMutation = gql<{ editDeliveryPrice: DeliveryPrice }, {id: number, deliveryPriceInput: DeliveryPriceInput }>`
  mutation EditDeliveryPrice($deliveryPriceInput: DeliveryPriceInput!, $id: Int!) {
    editDeliveryPrice(deliveryPriceInput: $deliveryPriceInput, id: $id) {
      id,
      method,
      rangeStart
      rangeEnd
      price
    }
  }
`


@Component({
  selector: 'app-price-form',
  templateUrl: './price-form.component.html',
  styleUrls: ['./price-form.component.css']
})
export class PriceFormComponent implements OnInit {

  @Input() price: DeliveryPrice | null = null

  @Output() formSubmitted: EventEmitter<boolean> = new EventEmitter();
  @Output() formCancelled: EventEmitter<void> = new EventEmitter();

  form  = this.fb.nonNullable.group({
    method: [DeliveryMethod.CAR, Validators.required],
    from: ['', [Validators.required] ],
    to: ['', [Validators.required] ],
    price: ['', [Validators.required] ],
  });
  isSubmitLoading = false;

  readonly methods = Object.keys(DeliveryMethod);

  constructor(private fb: FormBuilder, private apollo: Apollo, private msg: NzMessageService) { 
    
  }

  ngOnInit(): void {
    if (this.price) {
      this.form.setValue({
        method: this.price.method,
        from: this.price.rangeStart.toString(),
        to: this.price.rangeEnd.toString(),
        price: this.price.price.toString(),
      });
    }
    this.form.controls.to.addValidators(this.rangeValidator);
    this.form.updateValueAndValidity();
  }

  rangeValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return { error: true, required: true };
    } else if (this.form.controls['from'].value && control.value <= this.form.controls['from']?.value) {
      return { error: true, range: true, };
    }
    return {};
  };

  validateRange(): void {
    setTimeout(() => this.form.controls.to.updateValueAndValidity());
  }

  onSubmit(): void {
    this.isSubmitLoading = true;
    if (this.form.invalid) {
      this.isSubmitLoading = false;
       return;
    } 
    if (this.price) {
      const editDeliveryPriceSub = this.apollo.mutate({
        mutation: editDeliveryPriceMutation,
        variables: {
          id: this.price.id,
          deliveryPriceInput: {
            method: this.form.controls.method.value,
            rangeStart: +this.form.controls.from.value,
            rangeEnd: +this.form.controls.to.value,
            price: +this.form.controls.price.value
          }
        }
      }).subscribe(result => {      
        const price = result.data?.editDeliveryPrice;
        this.isSubmitLoading = false;
        this.formSubmitted.emit(true);
        this.msg.success('Price edited')
        editDeliveryPriceSub.unsubscribe();
      }, err => {
        this.isSubmitLoading = false;
        this.msg.error(err.message)
        console.error({err})
        editDeliveryPriceSub.unsubscribe();
      })
      return;
    }
    const addDeliveryPriceSub = this.apollo.mutate({
      mutation: addDeliveryPriceMutation,
      variables: {
        deliveryPriceInput: {
          method: this.form.controls.method.value,
          rangeStart: +this.form.controls.from.value,
          rangeEnd: +this.form.controls.to.value,
          price: +this.form.controls.price.value
        },
      },
      update: (store, result) => {
        // Read the data from our cache for this query.
        const data = store.readQuery<any>({ query: pricesQuery });
        
        // Add our product from the mutation to the end.
        const prices = [...data.deliveryPriceList, result.data?.addDeliveryPrice];
        
        // Write our data back to the cache.
        store.writeQuery({ query: pricesQuery, data: {deliveryPriceList: prices} });
      }
    }).subscribe(result => {
      const price = result.data?.addDeliveryPrice;
      this.isSubmitLoading = false;
      this.formSubmitted.emit(true);
      this.msg.success('Price added')
      addDeliveryPriceSub.unsubscribe();
    }, err => {
      this.isSubmitLoading = false;
      this.msg.error(err.message)
      console.error({err});
      addDeliveryPriceSub.unsubscribe();
    })
  }
  
  onCancel(): void{
    this.formCancelled.emit();
  }
}
