<form nz-form nzLayout="vertical" [formGroup]="form" (ngSubmit)="onSubmit()">
    <div style="padding: 24px 24px 0px;">
        <div nz-row [nzGutter]="20">
            <div nz-col nzSpan="12">
                <nz-form-item>
                    <nz-form-label nzRequired>Category</nz-form-label>
                    <nz-form-control nzErrorTip="Please select Category">
                        <nz-select formControlName="categoryId" nzPlaceHolder="Category" >
                            <nz-option *ngFor="let category of categories$ | async"
                                [nzValue]="''+category.id" [nzLabel]="category.name"></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-col nzSpan="12">
                <nz-form-item>
                    <!-- <nz-form-label>Image</nz-form-label> -->
                    <nz-form-control nzHasFeedback nzErrorTip="Please upload product image!">
                        <nz-upload
                            class="avatar-uploader"
                            [nzAction]="getUploadUrl()"
                            [nzHeaders]="getHeaders()"
                            nzName="file"
                            nzListType="picture-card"
                            [nzShowUploadList]="true"
                            [(nzFileList)]="fileList"
                            [nzShowButton]="fileList.length < 1"
                            (nzChange)="handleChange($event)"
                            [nzRemove]="onFileRemoved">
                            <ng-container *ngIf="!form.controls.image.value">
                                <span class="upload-icon" nz-icon [nzType]="'plus'"></span>
                                <div class="ant-upload-text">Upload</div>
                            </ng-container>
                            <img alt="Product Image" *ngIf="form.controls.image.value" [src]="form.controls.image.value" style="width: 100%; height: 100%;" />
                    </nz-upload>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>
        <div nz-row [nzGutter]="20">
            <div nz-col [nzSpan]="12">
                <nz-form-item>
                    <nz-form-label nzRequired>Code</nz-form-label>
                    <nz-form-control [nzErrorTip]="codeError">
                        <input nz-input formControlName="code" name="code" type="text" placeholder="Product Code" >
                        <ng-template #codeError let-control>
                            <ng-container *ngIf="control.hasError('required')">Please add code!</ng-container>
                            <ng-container *ngIf="control.hasError('minlength')">Code must be at least 4 characters long!</ng-container>
                        </ng-template>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-col [nzSpan]="12">
                <nz-form-item>
                    <nz-form-label nzRequired>Name</nz-form-label>
                    <nz-form-control nzErrorTip="Name is Required!">
                        <input nz-input formControlName="name" name="name" type="text" placeholder="Name">
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>
        <div nz-row [nzGutter]="20">
            <div nz-col nzSpan="12">
                <nz-form-item>
                    <nz-form-label nzRequired>Price</nz-form-label>
                    <nz-form-control [nzErrorTip]="priceError">
                        <input nz-input formControlName="price" name="price" type="number" placeholder="100"  >
                        <ng-template #priceError let-control>
                            <ng-container *ngIf="control.hasError('required')">Please add price!</ng-container>
                            <ng-container *ngIf="control.hasError('min')">Price must be minimum of 1!</ng-container>
                        </ng-template>
                    </nz-form-control>
                </nz-form-item>
            </div>

            <div nz-col nzSpan="12">
                <nz-form-item>
                    <nz-form-label nzRequired>Quantity</nz-form-label>
                    <nz-form-control [nzErrorTip]="quantityError">
                        <input nz-input formControlName="quantity" name="quantity" type="number" placeholder="100"  >
                        <ng-template #quantityError let-control>
                            <ng-container *ngIf="control.hasError('required')">Please add quantity!</ng-container>
                            <ng-container *ngIf="control.hasError('min')">Quantity must be minimum of 0!</ng-container>
                        </ng-template>
                    </nz-form-control>
                </nz-form-item>
            </div>

        </div>
        <div nz-row [nzGutter]="20">
            <div nz-col nzSpan="24">
                <nz-form-item>
                    <nz-form-label>Description</nz-form-label>
                    <nz-form-control>
                        <textarea nz-input formControlName="description" name="description" placeholder="Product description"></textarea>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>
        <div nz-row [nzGutter]="20">
            <div nz-col nzSpan="24">
                <nz-form-item>
                    <nz-form-label>Discount</nz-form-label>
                    <nz-form-control>
                      <div class="discount-section">
                        <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Discount Type"
                                   [(ngModel)]="discountType" [ngModelOptions]="{standalone: true}">
                          <nz-option nzLabel="Percentage" nzValue="PERCENTAGE"></nz-option>
                          <nz-option nzLabel="Fixed" nzValue="FIXED"></nz-option>
                        </nz-select>
                        <input nz-input type="number" name="amount" placeholder="Discount Amount"
                               [(ngModel)]="discountAmount" [ngModelOptions]="{standalone: true}">
                      </div>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>
    </div>
    <div class="footer">
        <button nz-button nzType="default" type="button" (click)="onCancel()">Cancel</button>
        <button nz-button nzType="primary" type="submit" [nzLoading]="isSubmitLoading" [disabled]="form.invalid">Submit</button>
    </div>
</form>
