import { Component, OnInit } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { NzMessageService } from 'ng-zorro-antd/message';
import { EMPTY, map, Observable } from 'rxjs';
import { Resource } from '../resource';

@Component({
  selector: 'app-resource-list',
  templateUrl: './resource-list.component.html',
  styleUrls: ['./resource-list.component.css']
})
export class ResourceListComponent implements OnInit {

  resources$: Observable<Resource[]> = EMPTY;

  resourceToEdit: Resource | null = null;
  resourceFormVisible = false;

  resourceToViewDetail: Resource | null = null;

  resourceToDelete: Resource | null = null;
  deleteResourceModalVisible = false;

  constructor(private apollo: Apollo, private msg: NzMessageService) { }

  ngOnInit(): void {
    this.resources$ = this.apollo.watchQuery({
      query: resourcesQuery
    }).valueChanges.pipe(
      map(result => {
        let resources = result.data?.resources; //.map(resource => Object.assign({}, resource, { createdAt: new Date(resource.createdAt) }))
        return [...resources].sort((a, b) => b.id - a.id) // shorthand method to sort by created date since the id is incremented
      })
    );
  }

  getTypeLabel(type: string): string {
    return type.replace(/[_]/g, ' ')
  }
  
  addResource(): void {
    this.resourceFormVisible = true;
  }

  editResource(resource: Resource): void {
    this.resourceToEdit = resource;
    this.resourceFormVisible = true;
  }

  onFormCancelled(): void {
    this.resourceFormVisible = false;
    setTimeout(() => {
      this.resourceToEdit = null;
    },1000)
  }

  onFormSubmitted(success: boolean): void {
      //TODO: Refactor code to show or hide form based on result status
      this.resourceFormVisible = false;
      setTimeout(() => {
        this.resourceToEdit = null;
      },1000)
      
  }

  viewResourceDetail(resource: Resource): void {
    this.resourceToViewDetail = resource;
  }

  closeResourceDetail(): void {
    this.resourceToViewDetail = null;
  }

  deleteResource(resource: Resource): void {
    this.resourceToDelete = resource;
    this.deleteResourceModalVisible = true;
  }

  onDeleteCancelled(): void {
    this.deleteResourceModalVisible = false;
    this.resourceToDelete = null;
  }

  onDeleteConfirmed(): void {
    if (this.resourceToDelete) {
      const deleteResourceSub = this.apollo.mutate({
        mutation: deleteResourceMutation,
        variables: {
          id: this.resourceToDelete.id
        }
      }).subscribe(result => {
        const resourceId = result.data?.deleteResource.id;

        const data = this.apollo.client.cache.readQuery({query: resourcesQuery});
        if (data) {

          const index = data.resources.findIndex(resource => resource.id === resourceId);
          if (index > -1) {
            const resources = [...data.resources];
            resources.splice(index, 1);
            // Write our data back to the cache.
            this.apollo.client.writeQuery({ query: resourcesQuery, data: { resources } });
          }
        }
        this.deleteResourceModalVisible = false;
        this.resourceToDelete = null;
        this.msg.success('Resource deleted')
        deleteResourceSub.unsubscribe();
      }, err => {
        // this.isSubmitLoading = false;
        this.msg.error(err.message)
        console.error({err});
        deleteResourceSub.unsubscribe();
      })
    }
  }

}

export const resourcesQuery = gql<{resources: Resource[]}, void>`
  query {
    resources {
      id,
      type,
      title,
      description,
      files,
      createdAt,
    }
  }
`
const deleteResourceMutation = gql<{ deleteResource: Resource }, { id: number }>`
  mutation DeleteResource($id: Int!) {
    deleteResource(id: $id) {
      id,
    }
  }
`