export interface Resource {
    id: number;
    type: ResourceType;
    title: string;
    description: string;
    files?: string[];
    createdAt: string;
}

export enum ResourceType {
    FAQ = "FAQ",
    NEWS = "NEWS",
    SUPPORT = "SUPPORT",
    POLICY = "POLICY",
    PROMOTION = 'PROMOTION',
    LINKS = 'LINKS',
    OTHER = 'OTHER',
}