import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Apollo, gql } from 'apollo-angular';
import { NzMessageService } from 'ng-zorro-antd/message';
import { User } from '../user';

@Component({
  selector: 'app-reset-user-password-form',
  templateUrl: './reset-user-password-form.component.html',
  styleUrls: ['./reset-user-password-form.component.css']
})
export class ResetUserPasswordFormComponent implements OnInit {

  @Input() user: User | null = null

  form: FormGroup;
  isSubmitLoading = false;

  @Output() formSubmitted: EventEmitter<boolean> = new EventEmitter();
  @Output() formCancelled: EventEmitter<void> = new EventEmitter();

  constructor(private fb: FormBuilder, private apollo: Apollo, private msg: NzMessageService) { 
    this.form = this.fb.group({
      password: [null, [Validators.required]],
      confirm: [null, [this.confirmValidator]]
    })
  }

  ngOnInit(): void {
  }

  confirmValidator = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return { error: true, required: true };
    } else if (control.value !== this.form.controls['password'].value) {
      return { confirm: true, error: true };
    }
    return {};
  };

  validateConfirmPassword(): void {
    setTimeout(() => this.form.get('confirm')?.updateValueAndValidity());
  }

  onSubmit(): void {
    this.isSubmitLoading = true;
    if (this.form.invalid) {
      this.isSubmitLoading = false;
       return;
    } 
    const changePasswordSub = this.apollo.mutate({
      mutation: changePasswordMutation,
      variables: {
        id: this.user?.id!,
        password: this.form.controls['password'].value,
      }
    }).subscribe(result => {
      // TODO: Refactor code to show or hide form based on result status
      this.isSubmitLoading = false;
      this.formSubmitted.emit(true);
      this.msg.success('Password changed')
      changePasswordSub.unsubscribe();
    }, err => {
      this.msg.error(err.message)
      console.error({err});
      changePasswordSub.unsubscribe();
    })
  }
  
  onCancel(): void{
    this.formCancelled.emit();
  }
}

const changePasswordMutation = gql<{changePassword: {success: boolean}}, {id: number, password: string}>`
  mutation ChangeUserPassword($id: Int!, $password: String!) {
    changeUserPassword(id: $id, password: $password) {
      success
    }
  }
`;