import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { ProfileComponent } from './profile/profile.component';
import { Route, RouterModule } from '@angular/router';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { JwtHelperService } from '@auth0/angular-jwt';

import { ResetPasswordFormComponent } from './reset-password-form/reset-password-form.component';

const routes: Route[] = [
  {  path: 'login', component: LoginComponent, data: { fullscreen: true } },
  {  path: 'reset-password', component: ResetPasswordFormComponent, data: { fullscreen: true } },
  {  path: 'profile', component: ProfileComponent, data: { fullscreen: true }},
]

@NgModule({
  declarations: [
    LoginComponent,
    ProfileComponent,
    ResetPasswordFormComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    ReactiveFormsModule,
    FormsModule,

    NzFormModule,
    NzInputModule,
    NzIconModule,
    NzButtonModule,
    NzTabsModule,
    NzCheckboxModule,
    NzGridModule,
    NzTypographyModule,
    NzMessageModule,
    NzUploadModule,
    NzDividerModule,
  ], 
  providers: [{ provide: JwtHelperService, useValue: new JwtHelperService() }],
})
export class AuthModule { }
