// export type By = number | { id: number, name: string };

export interface Order {
    id: number;
    code: string;
    customer: Customer;
    orderItems: OrderItem[];
    totalCost: number;
    totalPaid: number;
    discount: number;
    subTotal: number;
    deliveryCost: number;
    status: OrderStatus;
    acceptedBy?: number;
    acceptedAt?: string;
    completedBy?: number;
    completedAt?: string;
    rejectedBy?: number,
    rejectedAt?: string,
    isDelivery: boolean;
    note?: string;
    slips: Slip[];
    createdAt: Date | string;
    deliveryLocation?: {
        address: string,
        latitude: number,
        longitude: number,
    }
    rejectionReason?: string;
    cancellationReason?: string;
}

export interface Customer {
    id: number;
    name: string;
    phoneNumber: string;
}

export interface OrderItem {
    id: number;
    product: Product;
    quantity: number;
    acceptedBy?: number;
    acceptedAt?: string;
    completedBy?: number;
    completedAt?: string;
    rejectedBy?: number,
    rejectedAt?: string,
    rejectionReason?: string;
}

export interface Product {
    id: number;
    name: string;
    code: string;
    quantity: number;
    price: number;
}

export interface Slip {
  image: string;
  isValid?: boolean;
  validatedBy?: number;
  payment?: {
    amount: number;
    paidOn: Date;
    remark: string;
  };
}

export enum OrderStatus {
    NEW = "NEW",
    ACCEPTED = "ACCEPTED",
    PENDING = "PENDING",
    REJECTED = "REJECTED",
    COMPLETED = "COMPLETED",
    CANCELLED = "CANCELLED",
}
