<form nz-form [formGroup]="form" nzLayout="vertical" (ngSubmit)="onSubmit()">
    <div style="padding: 24px 24px 0px;">
        <nz-form-item>
            <nz-form-label nzRequired>Type</nz-form-label>
            <nz-form-control>
                <nz-select formControlName="type" nzPlaceHolder="Type" >
                    <nz-option *ngFor="let type of types" 
                        [nzValue]="type" 
                        [nzLabel]="getTypeLabel(type) | titlecase">
                    </nz-option>
                </nz-select>
            </nz-form-control>
        </nz-form-item>
        <nz-form-item>
            <nz-form-label nzRequired>Title</nz-form-label>
            <nz-form-control nzHasFeedback [nzErrorTip]="nameError">
                <nz-input-group>
                    <input nz-input formControlName="title" name="title" type="text" placeholder="Title">
                </nz-input-group>
                <ng-template #nameError let-control>
                    <ng-container *ngIf="control.hasError('required')">Please input title!</ng-container>
                </ng-template>
            </nz-form-control>
        </nz-form-item> 
        <nz-form-item>
            <nz-form-label nzRequired>Description</nz-form-label>
            <nz-form-control nzHasFeedback [nzErrorTip]="descriptionError">
                <textarea nz-input formControlName="description" name="description" type="text" placeholder="Description" rows="7"></textarea>
                <ng-template #descriptionError let-control>
                    <ng-container *ngIf="control.hasError('required')">Please input description!</ng-container>
                    <ng-container *ngIf="control.hasError('minlength')">Description must be at least 10 characters!</ng-container>
                </ng-template>
            </nz-form-control>
        </nz-form-item> 
        <nz-upload
            nzType="drag"
            [nzAction]="getUploadUrl()"
            [nzHeaders]="getHeaders()"
            nzName="file"
            nzListType="text"
            nzMultiple
            [nzShowUploadList]="true"
            [(nzFileList)]="fileList"
            (nzChange)="handleChange($event)"
            [nzRemove]="onFileRemoved">
            <p class="ant-upload-drag-icon">
                <span nz-icon nzType="inbox"></span>
            </p>
            <p class="ant-upload-text">Click or drag file to this area to upload</p>
            <p class="ant-upload-hint">
                Support for a single or bulk upload.
            </p>
    </nz-upload>
    </div>
    <div class="footer">
        <button nz-button nzType="default" type="button" (click)="onCancel()">Cancel</button>
        <button nz-button nzType="primary" type="submit" [nzLoading]="isSubmitLoading" [disabled]="form.invalid">Submit</button>
    </div>
</form>