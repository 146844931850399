<nz-layout style="height: calc(100vh - 72px); overflow-y: scroll;">
    <nz-header style="background-color: white;">
        <h4 nz-typography style="display: inline-block;">Customers</h4>
    </nz-header>
    <nz-content style="padding-inline: 24px; margin-top: 35px; margin-inline: auto; width: 1184px" >
        <nz-table #basicTable [nzData]="(customers$ | async) || []" [nzPageSize]="20" [nzTitle]="header">
            <thead>
              <tr>
                <th nzWidth="74px">Image</th>
                <th nzWidth="200px">Name</th>
                <th>Company</th>
                <th nzWidth="142px">Phone Number</th>
                <th nzWidth="115px">TIN</th>
                <th nzWidth="125px">Created At</th>
                <th nzWidth="125px">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let customer of basicTable.data">
                <td (click)="navigateTo(customer.id)" style="cursor: pointer">
                    <img *ngIf="customer.image"
                        nz-image
                        width="30px"
                        height="30px"
                        [nzSrc]="customer.image"
                        alt=""
                        style="border-radius: 50%;"
                    />
                </td>
                <td (click)="navigateTo(customer.id)" style="cursor: pointer">
                  {{ customer.name }}
                </td>
                <td (click)="navigateTo(customer.id)" style="cursor: pointer">
                  {{ customer.companyName }}
                </td>
                <td (click)="navigateTo(customer.id)" style="cursor: pointer">
                  {{ customer.phoneNumber }}
                </td>
                <td (click)="navigateTo(customer.id)" style="cursor: pointer">
                  {{ customer.tin }}
                </td>
                <td (click)="navigateTo(customer.id)" style="cursor: pointer">
                  {{ customer.createdAt | date:'mediumDate' }}
                </td>
                <td>
                  <a *ngIf="customer.hasTelegramAccount" (click)="sendMessage(customer)">Send Message</a>
                </td>
              </tr>
            </tbody>
        </nz-table>
    </nz-content>
</nz-layout>

<ng-template #header>
  <div style="display: flex; justify-content: center; align-items: center;">
    <span nz-typography style="padding-inline: 8px">Search Customers</span>
    <form (ngSubmit)="searchCustomers()" style="display: flex; justify-content: center; align-items: center; width: 45%">
      <input type="text" nz-input [(ngModel)]="searchField" name="search"/>
      <button nz-button nzType="primary" type="submit">
        <span nz-icon nzType="search"></span>
        Search
      </button>
    </form>
  </div>
</ng-template>

<nz-modal
  [(nzVisible)]="sendMessageFormVisible"
  [nzTitle]="'Send message to  ' + customerToSendMessage?.name"
  (nzOnCancel)="cancelSendMessage()"
  nzOkText="Send"
  [nzOkDisabled]="!message"
  nzCancelText="Cancel"
  (nzOnOk)="submitSendMessage()"
>
  <div *nzModalContent>
    <form nz-form nzLayout="vertical">
      <nz-form-item>
        <nz-form-label nzRequired>Message</nz-form-label>
        <nz-form-control nzErrorTip="Message is required!">
          <textarea
            nz-input
            placeholder="Message"
            [(ngModel)]="message"
            [ngModelOptions]="{ standalone: true }"
            required
          ></textarea>
        </nz-form-control>
      </nz-form-item>
    </form>
  </div>
</nz-modal>