import {Component, NgZone, OnInit} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import { Apollo, gql } from 'apollo-angular';
import { NzMessageService } from 'ng-zorro-antd/message';
import { BehaviorSubject, combineLatest, EMPTY, map, Observable, of, Subject, switchMap, tap } from 'rxjs';
import { Branch } from 'src/app/branches/branch';
import { branchesQuery } from 'src/app/branches/branch-list/branch-list.component';
import { DeliveryMethod } from 'src/app/delivery-price/delivery-price';
import { Order, OrderItem, OrderStatus, Slip } from '../order';
import {DomSanitizer} from "@angular/platform-browser";

type OrderBysMap = { [key: number]: { id: number, name: string } }
export const ordersQuery = gql<{ orders: Order[] }, void>`
	query Orders {
    	orders {
        id,
    		code,
			  createdAt,
        customer {
          id,
          name,
          phoneNumber
        },
			  isDelivery,
        deliveryLocation {
          address,
          latitude,
          longitude
        },
			  status,
        acceptedBy,
        acceptedAt,
        completedBy,
        completedAt,
        rejectedBy,
        rejectedAt,
        totalCost,
        orderItems {
          id,
          product {
            id,
            name,
            quantity,
            price,
            code,
          },
          quantity,
          acceptedBy,
          acceptedAt,
          completedBy,
          completedAt,
          rejectedBy,
          rejectedAt,
          rejectionReason,
        },
        slips {
            image,
            isValid,
            payment {
                amount,
                paidOn,
                remark,
            },
        },
        totalPaid,
        discount,
        subTotal,
        deliveryCost,
        note,
        rejectionReason,
        cancellationReason,
    	}
  	}
`
const getBysQuery = gql<{ getBys: { id: number, name: string }[] }, { ids: number[]}>`
  query GetBys($ids: [Int!]!){
    getBys(ids: $ids){
      id,
      name,
    }
  }
`
export const searchOrdersQuery = gql<{ orders: Order[] }, { value: string }>`
  query SearchOrders($value: String!) {
    ordersSearch(value: $value){
        id,
        code,
        createdAt,
        customer {
            id,
            name,
            phoneNumber
        },
        isDelivery,
        deliveryLocation {
            address,
            latitude,
            longitude
        },
        status,
        acceptedBy,
        acceptedAt,
        completedBy,
        completedAt,
        rejectedBy,
        rejectedAt,
        totalCost,
        orderItems {
            id,
            product {
                id,
                name,
                quantity,
                price,
                code,
            },
            quantity,
            acceptedBy,
            acceptedAt,
            completedBy,
            completedAt,
            rejectedBy,
            rejectedAt,
            rejectionReason,
        },
        slips {
            image,
            isValid,
            payment {
                amount,
                paidOn,
                remark,
            },
        },
        totalPaid,
        discount,
        subTotal,
        deliveryCost,
        note,
        rejectionReason,
        cancellationReason,
    }
  }
`

@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.css']
})
export class OrderListComponent implements OnInit {

  orders$: Observable<Order[]> = EMPTY;
  private dateFilter: Subject<Date[] | null> = new BehaviorSubject<Date[] | null>(null);
  private paymentFilterer: Subject<string | null> = new BehaviorSubject<string | null>(null);
  private deliveryFilterer: Subject<string | null> = new BehaviorSubject<string | null>(null);
  private bysRetrievedForOrder: Map<number, OrderBysMap> = new Map();
  expandSet = new Set<number>();
  slipValidationVisible: boolean = false;
  validatedSlipVisible: boolean = false;
  slipToValidate!: any;
  slipToView!: any;
  slipIndex!: any;
  orderWithSlipToValidate: Order | null = null;
  manageOrderVisible = false;
  orderToManage: Order | null = null;
  rejectionReasonFormVisible = false;
  orderToReject: Order | null = null;
  rejectionReason: string | null = null;
  orderToCancel: Order | null = null;
  cancellationReasonFormVisible = false;
  cancellationReason: string | null = null;

  paymentFilter: "underpaid" | "unpaid" | "paid" | "overpaid" | null = null;
  deliveryFilter: "delivery" | "walkIn" | null = null;
  searchField!: string;
  isAllOrders: boolean = true;

  orderToAccept: Order | null = null;
  acceptOrderFormVisible = false;
  deliveryForm = this.fb.nonNullable.group({
    method: [DeliveryMethod.CAR, Validators.required],
    branch: ['', Validators.required],
    cost: [0, [Validators.required, Validators.min(0)]],
    distance: ['']
  })
  costHint = '';
  readonly methods = Object.keys(DeliveryMethod);
  branches$: Observable<Branch[]> = EMPTY;

  // private bys: Map<number, { id: number, name: string }> = new Map();

  constructor(private route: ActivatedRoute,
              private router: Router,
              private apollo: Apollo,
              private msg: NzMessageService,
              private fb: FormBuilder,
              private ngZone: NgZone,
              public sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    const statusFilter = this.statusFilter();
    this.getAllOrders(statusFilter);
  /*   const getByForOrders = map((orders: Order[]) => {
      const userIds = new Set<number>();
      orders.forEach(order => {
        if (typeof order.acceptedBy === "number")
          userIds.add(order.acceptedBy);
        if (typeof order.rejectedBy === "number")
          userIds.add(order.rejectedBy);
        if (typeof order.completedBy === "number")
          userIds.add(order.completedBy);
        order.orderItems.forEach(oi => {
          if (typeof oi.acceptedBy === "number")
            userIds.add(oi.acceptedBy);
          if (typeof oi.rejectedBy === "number")
            userIds.add(oi.rejectedBy);
          if (typeof oi.completedBy === "number")
            userIds.add(oi.completedBy);
        })
      });

      return this.apollo.query({
        query: getBysQuery,
        variables: {
          ids: Array.from(userIds) as number[]
        }
      }).pipe(
        tap(result => {
          result.data.getBys.forEach(by => this.bys.set(by.id, by));
        }),
        map(_ => {
          orders.forEach(order => {
            if (typeof order.acceptedBy === "number") {
              order.acceptedBy = this.bys.get(order.acceptedBy);
            }
            if (typeof order.completedBy === "number") {
              order.completedBy = this.bys.get(order.completedBy);
            }
            if (typeof order.rejectedBy === "number") {
              order.rejectedBy = this.bys.get(order.rejectedBy);
            }
            order.orderItems.forEach(oi => {
              if (typeof oi.acceptedBy === "number") {
                oi.acceptedBy = this.bys.get(oi.acceptedBy);
              }
              if (typeof oi.completedBy === "number") {
                oi.completedBy = this.bys.get(oi.completedBy);
              }
              if (typeof oi.rejectedBy === "number") {
                oi.rejectedBy = this.bys.get(oi.rejectedBy);
              }
            })
          })
          return orders;
        }),
      )
    }); */
  }

  getAllOrders(statusFilter: Observable<OrderStatus | null>){
    this.orders$ = combineLatest({
      statusFilter,
      dateFilter: this.dateFilter,
      paymentFilter: this.paymentFilterer,
      deliveryFilter: this.deliveryFilterer
    })
      .pipe(map(({statusFilter, dateFilter, paymentFilter, deliveryFilter}) => {
          return this.apollo.watchQuery({
            query: ordersQuery
          }).valueChanges.pipe(map(result => {
              return [...result.data.orders];
            }),
            map(orders => {
              // shorthand method to sort by created date since the id is incremented
              orders.sort((a, b) => b.id - a.id);
              return this.filterOrder(orders, statusFilter, dateFilter, paymentFilter, deliveryFilter);
            }),
          )}),
        switchMap(o => o),
      );
  }

  statusFilter () {
    return this.route.queryParamMap.pipe(
      map(pm => pm.get('status')), map(status => {
        switch(status) {
          case 'new': return OrderStatus.NEW;
          case 'accepted': return OrderStatus.ACCEPTED;
          case 'pending': return OrderStatus.PENDING;
          case 'rejected': return OrderStatus.REJECTED;
          case 'completed': return OrderStatus.COMPLETED;
          case 'cancelled': return OrderStatus.CANCELLED;
          default: return null;
        }
      }),
    );
  }

  onExpandChange(id: number, checked: boolean): void {
    if (checked) {
      this.expandSet.add(id);
    } else {
      this.expandSet.delete(id);
    }
  }

  getByForOrder(order: Order): Observable<OrderBysMap> {
    if (this.bysRetrievedForOrder.has(order.id)) {
      return of(this.bysRetrievedForOrder.get(order.id)!);
    }
    const userIds = new Set<number>();
    if (order.acceptedBy) userIds.add(order.acceptedBy);
    if (order.rejectedBy) userIds.add(order.rejectedBy);
    if (order.completedBy) userIds.add(order.completedBy);

    order.orderItems.forEach(oi => {
      if (oi.acceptedBy) userIds.add(oi.acceptedBy);
      if (oi.rejectedBy) userIds.add(oi.rejectedBy);
      if (oi.completedBy) userIds.add(oi.completedBy);
    });

    return this.apollo.query({
      query: getBysQuery,
      variables: {
        ids: Array.from(userIds) as number[]
      }
    }).pipe(tap(result => {
        const map: OrderBysMap = {};
        result.data.getBys.forEach(by => {
          map[by.id] = by;
          // this.bys.set(by.id, by);
        });
        this.bysRetrievedForOrder.set(order.id, map);
      }),
      map(_ => this.bysRetrievedForOrder.get(order.id)!)
    );
  }

  getAcceptedByString(order: Order, byMap: OrderBysMap): string {
    let str = 'Accepted'
    str +=  order.acceptedBy? ' by ' + byMap[order.acceptedBy].name : ' by Unknown'
    return str;
  }

  getCompletedByString(order: Order, byMap: OrderBysMap): string {
    let str = 'Completed'
    str +=  order.completedBy? ' by ' + byMap[order.completedBy].name : ' by Unknown'
    return str;
  }

  getRejectedByString(order: Order, byMap: OrderBysMap): string {
    let str = 'Rejected'
    str +=  order.rejectedBy? ' by ' + byMap[order.rejectedBy].name : ' by Unknown'
    return str;
  }

  getItemsString(items: OrderItem[]): string {
    return items.map(i => i.quantity + ' ' + i.product.name).reduce((pv, cv) => pv + ', ' + cv);
  }

  getDeliveryLocation(order: Order): string {
    if (order.deliveryLocation?.address) {
      return order.deliveryLocation?.address
    }
    const latLng = order.deliveryLocation?.latitude + ',<br>' + order.deliveryLocation?.longitude;
    return `<a href="https://www.google.com/maps/search/?api=1&query=${latLng}" target="_blank">${latLng}</a>`
    // return order.deliveryLocation?.latitude + ", " + order.deliveryLocation?.longitude
  }

  getStatusTooltip(order: Order): string {
    return order.status === 'REJECTED' ? order.rejectionReason || '' : order.status === 'CANCELLED' ? order.cancellationReason || '' : '';
  }

  private filterOrderByStatus(orders: Order[], status: OrderStatus | null): Order[] {
    if (status === null) {
      return orders
    } else {
      return orders.filter(o => o.status === status)
    }
  }

  private filterOrderByDateRange(orders: Order[], dateRange: Date[] | null): Order[] {
    if (dateRange === null) return orders;
    return orders.filter(o => {
      if (typeof o.createdAt === 'string') {
        o.createdAt = new Date(o.createdAt);
      }
      return o.createdAt >= dateRange[0] && o.createdAt <= dateRange[1];
    });
  }

  onDateRangeChanged(dates: Date[]): void {
    if (dates && dates.length === 2) {
      this.dateFilter.next(dates);
    } else {
      this.dateFilter.next(null);
    }
  }

  private filterOrderByPaymentStatus(orders: Order[], paymentFilter: string | null) {
   if (paymentFilter === 'paid') {
      return orders.filter(o => {
        return o.totalPaid === o.totalCost;
      });
    } else if (paymentFilter === 'unpaid') {
      return orders.filter(o => {
        return o.totalPaid === 0;
      });
    } else if (paymentFilter === 'overpaid'){
      return orders.filter(o => {
        return o.totalPaid > o.totalCost;
      });
    } else if(paymentFilter === 'underpaid'){
      return orders.filter(o => {
        return o.totalPaid > 0 && o.totalPaid < o.totalCost;
      });
    } else {
     return orders;
   }
  }

  private filterOrderByDelivery(orders: Order[], deliveryFilter: string | null) {
    if (deliveryFilter === null) {
      return orders;
    } else if (deliveryFilter === 'delivery') {
      return orders.filter(o => {
        return o.isDelivery;
      });
    } else {
      return orders.filter(o => {
        return !o.isDelivery;
      });
    }
  }

  private filterOrder(orders: Order[], status: OrderStatus | null, dateRange: Date[] | null, paymentFilter: string | null, deliveryFilter: string | null): Order[] {
    return this.filterOrderByStatus(
      this.filterOrderByDateRange(
        this.filterOrderByPaymentStatus(
          this.filterOrderByDelivery( orders,
            deliveryFilter
          ),
          paymentFilter
        ),
        dateRange
      ),
      status
    );
  }

  acceptOrder(order: Order): void {
    if (!order.isDelivery) {
      const acceptOrderSub = this.apollo.mutate({
        mutation: acceptOrderMutation,
        variables: {
          code: order.code
        }
      }).subscribe({
        next: () => {
        this.msg.success('Order accepted.')
        acceptOrderSub.unsubscribe();
        },
        error: (err) => {
        this.msg.error(err.message)
        console.error({err});
        acceptOrderSub.unsubscribe();
        },
      });
      return;
    }
    this.branches$ = this.apollo.watchQuery({
      query: branchesQuery
    }).valueChanges.pipe(map(result => result.data?.branches));
    this.orderToAccept = order;
    this.acceptOrderFormVisible = true;
    this.deliveryForm.reset();
    this.costHint = '';
  }

  cancelAcceptOrder(): void {
    this.orderToAccept = null;
    this.acceptOrderFormVisible = false;
    this.deliveryForm.reset();
    this.costHint = '';
  }

  submitAcceptOrder(): void {
    if (this.orderToAccept) {
      const acceptOrderSub = this.apollo.mutate({
        mutation: acceptOrderMutation,
        variables: {
          code: this.orderToAccept.code,
          delivery: {
            method: this.deliveryForm.controls.method.value,
            branchId: (this.deliveryForm.controls.branch.value as any).id,
            cost: this.deliveryForm.controls.cost.value,
            distance: +this.deliveryForm.controls.distance.value,
          },
        }
      }).subscribe({
        next: () => {
          this.msg.success('Order accepted.');
          acceptOrderSub.unsubscribe();
        },
        error: (err) => {
          this.msg.error(err.message)
          console.error({err});
          acceptOrderSub.unsubscribe();
        }
      });
    }
  }

  validateCost(): void {
    console.log('Validating cost')
    // Calculate cost based on branch location and delivery location
    this.costHint = 'Calculating'
    const branchLocation = (this.deliveryForm.controls.branch.value as unknown as Branch).location;
    const deliveryLocation = this.orderToAccept?.deliveryLocation;
    if(!deliveryLocation || !deliveryLocation.latitude) {
      this.costHint = 'Delivery location doesn\'t have coordinates'
      return;
    }
    this.calculateDistance(
      { lat: branchLocation.latitude, lng: branchLocation.longitude},
      { lat: deliveryLocation!.latitude, lng: deliveryLocation!.longitude}
    ).then(distance => {
      console.log({distance})
      if (distance === null) {
        this.costHint = 'Could not determine distance'
        return
      }
      this.deliveryForm.controls.distance.setValue(distance.toString());
      const priceSub = this.calculatePrice(this.deliveryForm.controls.method.value, distance).subscribe(price => {
        console.log({price});

        if (price === null) {
          this.costHint = 'Could not compute price'
        } else {
          this.costHint = '';
          this.deliveryForm.controls.cost.setValue(price);
        }
        this.deliveryForm.controls.cost.updateValueAndValidity()
        priceSub.unsubscribe();
      })
    })
    // setTimeout(() => this.deliveryForm.controls.cost.updateValueAndValidity());
  }

  private calculateDistance(from: google.maps.LatLngLiteral, to: google.maps.LatLngLiteral) {
    const service = new google.maps.DistanceMatrixService();
    return service.getDistanceMatrix({
        origins: [from],
        destinations: [ to ],
        travelMode: google.maps.TravelMode.DRIVING,
        unitSystem: google.maps.UnitSystem.METRIC,
      }).then(response => {
          // See Parsing the Results for
          // the basics of a callback function.
          if (response) {
            return response.rows[0].elements[0].distance.value / 1000;
          }
          return null
      });
  }

  private calculatePrice(method: DeliveryMethod, distance: number) {
    return this.apollo.query({
      query: calculateDeliveryPriceQuery,
      variables: {
        method,
        distance
      }
    }).pipe(map(result => result.data?.calculateDeliveryPrice));
  }

  completeOrder(order: Order): void {
    const completeOrderSub = this.apollo.mutate({
      mutation: completeOrderMutation,
      variables: {
        code: order.code
      }
    }).subscribe({
      next: () => {
        this.msg.success('Order completed')
        completeOrderSub.unsubscribe();
      },
      error: (err) => {
      this.msg.error(err.message)
      console.error({err});
      completeOrderSub.unsubscribe();
      },
    });
  }

  rejectOrder(order: Order): void {
    this.orderToReject = order;
    // this.rejectionReason = null;
    this.rejectionReasonFormVisible = true;
  }

  cancelRejectOrder(): void {
    this.orderToReject = null;
    this.rejectionReason = null;
    this.rejectionReasonFormVisible = false;
  }

  submitRejectOrder(): void {
    if (this.orderToReject && this.rejectionReason) {
      const rejectOrderSub = this.apollo.mutate({
        mutation: rejectOrderMutation,
        variables: {
          code: this.orderToReject.code,
          reason: this.rejectionReason,
        }
      }).subscribe({
        next: () => {
          this.cancelRejectOrder();
          this.msg.success('Order rejected')
          rejectOrderSub.unsubscribe();
        },
        error: (err) => {
        this.msg.error(err.message)
        console.error({err});
        rejectOrderSub.unsubscribe();
        },
      });
    }
  }

  manageOrder(order: Order): void {
    this.orderToManage = order;
    this.manageOrderVisible = true;
  }

  onManageOrderCancelled(): void {
    this.manageOrderVisible = false;
    this.orderToManage = null;
  }

  onManageOrderSubmitted(success: boolean): void {
    //TODO: Refactor code to show or hide form based on result status
    this.manageOrderVisible = false;
    this.orderToManage = null;
  }

  openSlipValidationDialog(slip: Slip, order: Order, index: number) {
    this.slipToValidate = slip;
    this.slipIndex = index;
    this.orderWithSlipToValidate = order;
    this.slipValidationVisible = true;
  }

  onSlipValidationCancelled() {
    this.slipValidationVisible = false;
    setTimeout(() => {
      this.slipToValidate = null;
      this.slipIndex = null;
      this.orderWithSlipToValidate = null;
    },1000);
  }

  onSlipValidationSubmitted(success: boolean): void {
    //TODO: Refactor code to show or hide form based on result status
    this.slipValidationVisible = false;
    this.slipToValidate = null;
  }

  openViewSlipDialog(slip: Slip) {
    this.slipToView = slip;
    this.validatedSlipVisible = true;
  }

  onSlipViewCancelled(){
    this.validatedSlipVisible = false;
    setTimeout(() => { this.slipToView = null; },1000);
  }

  submitCancelOrder(): void {
    if (this.orderToCancel && this.cancellationReason) {
      const cancelOrderSub = this.apollo.mutate({
        mutation: cancelOrderMutation,
        variables: {
          code: this.orderToCancel.code,
          reason: this.cancellationReason,
        }
      }).subscribe({
        next: () => {
          this.abortOrderCancellation();
          this.msg.success('Order Cancelled');
          cancelOrderSub.unsubscribe();
        },
        error: (err) => {
          this.msg.error(err.message)
          console.error({err});
          cancelOrderSub.unsubscribe();
        },
      });
    }
  }

  cancelOrder(order: Order): void {
    this.orderToCancel = order;
    this.cancellationReasonFormVisible = true;
  }

  abortOrderCancellation() {
    this.orderToCancel = null;
    this.cancellationReason = null;
    this.cancellationReasonFormVisible = false;
  }

  filterOrdersByPaymentStatus() {
    if (this.paymentFilter === 'paid'){
      this.paymentFilterer.next('paid');
    } else if (this.paymentFilter === 'unpaid'){
      this.paymentFilterer.next('unpaid');
    } else if (this.paymentFilter === 'overpaid'){
      this.paymentFilterer.next('overpaid');
    } else if (this.paymentFilter === 'underpaid'){
      this.paymentFilterer.next('underpaid');
    } else {
      this.paymentFilterer.next(null);
    }
  }

  filterOrdersByDeliveryType() {
    if (this.deliveryFilter === 'delivery'){
      this.deliveryFilterer.next('delivery');
    } else if (this.deliveryFilter === 'walkIn'){
      this.deliveryFilterer.next('walkIn');
    } else {
      this.deliveryFilterer.next(null);
    }
  }

  searchOrders() {
    const statusFilter = this.statusFilter();
    if (!this.searchField && this.isAllOrders) return;
    if (this.searchField){
      this.orders$ = combineLatest({
        statusFilter,
        dateFilter: this.dateFilter,
        paymentFilter: this.paymentFilterer,
        deliveryFilter: this.deliveryFilterer
      })
        .pipe(map(({statusFilter, dateFilter, paymentFilter, deliveryFilter}) => {
            return this.apollo.watchQuery({
              query: searchOrdersQuery,
              variables: {
                value: this.searchField,
              }
            }).valueChanges.pipe(map(result => {
                // @ts-ignore
                return [...result.data.ordersSearch];
              }),
              map(orders => {
                // shorthand method to sort by created date since the id is incremented
                orders.sort((a, b) => b.id - a.id);
                return this.filterOrder(orders, statusFilter, dateFilter, paymentFilter, deliveryFilter);
              }),
            )}),
          switchMap(o => o),
        );
      this.isAllOrders = false;
    } else if (!this.isAllOrders){
      this.getAllOrders(statusFilter);
      this.isAllOrders = true
    }
  }

  navigateTo(customerId: number) {
    this.ngZone.run(() => this.router.navigate(['/customer', customerId])).then(() => {return});
  }
}

interface DeliveryInput {
  method: DeliveryMethod,
  branchId: number,
  cost: number,
  distance?: number,
}

export const acceptOrderMutation = gql<{ acceptOrder: Order }, { code: string, delivery?: DeliveryInput }>`
  mutation AcceptOrder($code: String!, $delivery: DeliveryInput) {
    acceptOrder(code: $code, delivery: $delivery) {
      id,
      code,
      status
    }
  }
`

export const rejectOrderMutation = gql<{ rejectOrder: Order }, { code: string, reason: string }>`
  mutation RejectOrder($code: String!, $reason: String!) {
    rejectOrder(code: $code, reason: $reason) {
      id,
      code,
      status,
      rejectionReason
    }
  }
`

const completeOrderMutation = gql<{ completeOrder: Order }, { code: string }>`
  mutation CompleteOrder($code: String!) {
    completeOrder(code: $code) {
      id,
      code,
      status
    }
  }
`

const cancelOrderMutation = gql<{ cancelOrder: Order }, { code: string, reason: string }>`
  mutation CancelOrder($code: String!, $reason: String!) {
    cancelOrder(code: $code, reason: $reason) {
      id,
      code,
      status,
      cancellationReason,
    }
  }
`

const calculateDeliveryPriceQuery = gql<{ calculateDeliveryPrice: number | null }, { method: DeliveryMethod, distance: number }>`
  query CalculateDeliveryPrice($method: DeliveryMethod!, $distance: Float!) {
    calculateDeliveryPrice(method: $method, distance: $distance)
  }
`
