import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Apollo, gql } from 'apollo-angular';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzUploadFile, NzUploadChangeParam } from 'ng-zorro-antd/upload';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Resource, ResourceType } from '../resource';
import { resourcesQuery } from '../resource-list/resource-list.component';

@Component({
  selector: 'app-resource-form',
  templateUrl: './resource-form.component.html',
  styleUrls: ['./resource-form.component.css']
})
export class ResourceFormComponent implements OnInit {

  @Input() resource: Resource | null = null
  readonly types = Object.keys(ResourceType);
  getTypeLabel(type: string): string {
    return type.replace(/[_]/g, ' ')
  }
  form = this.fb.nonNullable.group({
    type: ['', [Validators.required]],
    title: ['', [Validators.required]],
    description: ['', Validators.required],
  })
  isSubmitLoading = false;

  fileUploading = false;
  fileList: NzUploadFile[] = [];
  private fileUrls: string[] = [];
  onFileRemoved: (uploadedFile: NzUploadFile) => boolean | Observable<boolean> = (uploadedFile: NzUploadFile) => {
    this.fileUrls.splice(this.fileUrls.findIndex(f => uploadedFile.url === f), 1);
    return true;
  }
  getUploadUrl(): string {
    return environment.baseUrl + 'upload'
  }
  getHeaders() {
    return {
      Source: 'resource_files'
    }
  }
  handleChange(e: NzUploadChangeParam): void {
    switch (e.file.status) {
      case 'uploading':
        this.fileUploading = true;
        break;
      case 'done':
        // Get this url from response.
        this.fileUploading = false;
        const response = e.file.response
        if (response && response.success) {
          this.fileUrls.push(response.url);
        }
        break;
      case 'error':
        this.msg.error('Network error');
        this.fileUploading = false;
        break;
    }
  }

  @Output() formSubmitted: EventEmitter<boolean> = new EventEmitter();
  @Output() formCancelled: EventEmitter<void> = new EventEmitter();
  constructor(private fb: FormBuilder, private apollo: Apollo, private msg: NzMessageService) { }

  ngOnInit(): void {
    if (this.resource) {
      this.form.setValue({
        type: this.resource.type,
        title: this.resource.title,
        description: this.resource.description,
      });
      if (this.resource.files) {
        this.fileList = this.resource.files.map((url, i) => ({
          uid: '' + i,
          name: url.replace(/http.+\/\d+-\d+-/g, ''),
          status: 'done',
          url: url,
        }))
        this.fileUrls = this.resource.files.map(url => url);
      }
      return;
    }
  }

  onSubmit(): void {
    this.isSubmitLoading = true;
    if (this.form.invalid) {
      this.isSubmitLoading = false;
      return;
    } 
    // Add or edit Resource
    if (this.resource) {
      const editResourceSub = this.apollo.mutate({
        mutation: editResourceMutation,
        variables: {
          id: this.resource.id,
          resourceData: {
            type: this.form.controls.type.value,
            title: this.form.controls.title.value,
            description: this.form.controls.description.value,
            files: this.fileUrls.length ? this.fileUrls : undefined,
          }
        }
      }).subscribe(result => {      
        const resource = result.data?.editResource;
        this.isSubmitLoading = false;
        this.formSubmitted.emit(true);
        this.msg.success('Resource edited')
        editResourceSub.unsubscribe();
      }, err => {
        this.isSubmitLoading = false;
        this.msg.error(err.message)
        console.error({err})
        editResourceSub.unsubscribe();
      })
      return;
    }
    const addResourceSub = this.apollo.mutate({
      mutation: addResourceMutation,
      variables: {
        resourceData: {
          type: this.form.controls.type.value,
          title: this.form.controls.title.value,
          description: this.form.controls.description.value,
          files: this.fileUrls.length ? this.fileUrls : undefined,
        },
      },
      update: (store, result) => {
        // Read the data from our cache for this query.
        const data = store.readQuery<any>({ query: resourcesQuery });
        
        // Add our product from the mutation to the end.
        const resources = [...data.resources, result.data?.addResource];
        
        // Write our data back to the cache.
        store.writeQuery({ query: resourcesQuery, data: {resources} });
      }
    }).subscribe(result => {
      const resource = result.data?.addResource;
      this.isSubmitLoading = false;
      this.formSubmitted.emit(true);
      this.msg.success('Resource added')
      addResourceSub.unsubscribe();
    }, err => {
      this.isSubmitLoading = false;
      this.msg.error(err.message)
      console.error({err});
      addResourceSub.unsubscribe();
    })
  }
  
  onCancel(): void{
    this.formCancelled.emit();
  }

}

interface ResourceInput {
  type: string;
  title: string;
  description: string;
  files?: string[];
}

const addResourceMutation = gql<{ addResource: Resource }, { resourceData: ResourceInput }>`
  mutation AddResource($resourceData: ResourceInput!) {
    addResource(resourceData: $resourceData) {
      id,
      type,
      title,
      description,
      files,
      createdAt,
    }
  }
`
const editResourceMutation = gql<{ editResource: Resource }, { id: number, resourceData: ResourceInput }>`
  mutation EditResource($resourceData: ResourceInput!, $id: Int!) {
    editResource(resourceData: $resourceData, id: $id) {
      id,
      type,
      title,
      description,
      files,
      createdAt,
      updatedAt
    }
  }
`

