import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Route, RouterModule } from '@angular/router';

import { NzTableModule } from 'ng-zorro-antd/table';
import { NzImageModule } from 'ng-zorro-antd/image';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzDividerModule } from 'ng-zorro-antd/divider';

import { UserListComponent } from './user-list/user-list.component';
import { UserFormComponent } from './user-form/user-form.component';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzButtonModule } from 'ng-zorro-antd/button';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzInputModule } from 'ng-zorro-antd/input';
import { ResetUserPasswordFormComponent } from './reset-user-password-form/reset-user-password-form.component';
import { AuthGuard } from '../auth/auth.guard';
import { NzMessageModule } from 'ng-zorro-antd/message';
import {NzSelectModule} from "ng-zorro-antd/select";

const routes: Route[] = [
  { path: 'users', component: UserListComponent, canActivate: [AuthGuard] }
]

@NgModule({
  declarations: [
    UserListComponent,
    UserFormComponent,
    ResetUserPasswordFormComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    ReactiveFormsModule,

    NzTableModule,
    NzImageModule,
    NzLayoutModule,
    NzTypographyModule,
    NzDividerModule,
    NzPageHeaderModule,
    NzModalModule,
    NzButtonModule,
    NzUploadModule,
    NzFormModule,
    NzInputModule,
    NzIconModule,
    NzCheckboxModule,
    NzMessageModule,
    FormsModule,
    NzSelectModule,
  ]
})
export class UsersModule { }
