export interface User {
    id: number;
    name: string;
    phoneNumber: string;
    email?: string;
    image?: string;
    createdAt: string;
    roles: Role[];
}

export enum Role {
    ADMIN = "ADMIN",
    DELIVERY = "DELIVERY",
    SALES = "SALES",
    MARKETING = 'MARKETING'
}