import { Component, Input, OnInit } from '@angular/core';
import { Resource } from '../resource';

@Component({
  selector: 'app-resource-detail',
  templateUrl: './resource-detail.component.html',
  styleUrls: ['./resource-detail.component.css']
})
export class ResourceDetailComponent implements OnInit {

  @Input() resource!: Resource;

  fileName(file: string): string {
    return file.replace(/http.+\/\d+-\d+-/g, '')
  }

  constructor() { }

  ngOnInit(): void {
  }

}
