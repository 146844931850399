<div>
    <div class="label">Phone Numbers</div>
    <p> {{branch.phoneNumbers}} </p>
</div>

<div>
    <div class="label">Location</div>
    <p> 
        {{ branch.location?.address }} 
        <a target="_blank" [attr.href]="'https://www.google.com/maps/search/?api=1&query=' + branch.location?.latitude + ',' + branch.location?.longitude">
          <!-- {{branch.location?.latitude}}, {{branch.location?.longitude}} -->
          View on Map
        </a>
    </p>
</div>

<!--  -->
<ng-container *ngIf="branch.employees">
    <div style="font-weight: 600;">Employees</div>
    <nz-divider style="margin-top: 8px; margin-bottom: 12px;"></nz-divider>
</ng-container>

<div *ngFor="let employee of branch.employees">
    <div style="float: right;">
        <!-- employee photo -->
        <img alt="Image" *ngIf="employee.photo" [src]="employee.photo"/>
    </div>
    <div>
        <div class="label">Name</div>
        <p>{{employee.name}}</p>
    </div>
    <div>
        <div class="label">Position</div>
        <p>{{employee.position}}</p>
    </div>
    <div>
        <div class="label">Description</div>
        <p>{{employee.description}}</p>
    </div>
    <nz-divider nzDashed="true" style="margin: 12px 0;"></nz-divider>
</div>

<div>
    <div class="label">Branch Description</div>
    <p> {{branch.description}} </p>
</div>
