export interface DeliveryPrice {
    id: number;
    method: DeliveryMethod;
    rangeStart: number;
    rangeEnd: number;
    price: number;
}

export enum DeliveryMethod {
    CAR = 'CAR',
    MOTOR = 'MOTOR',
}