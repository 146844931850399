<nz-layout style="height: calc(100vh - 72px); overflow-y: scroll;">
    <nz-page-header nzTitle="Branches" [nzGhost]="false">
        <nz-page-header-extra>
          <button nz-button nzType="primary" (click)="addBranch()">
              <span nz-icon nzType="plus"></span>
              Add Branch
          </button>
      </nz-page-header-extra>
    </nz-page-header>
    <nz-content style="padding-inline: 24px; margin-top: 35px; margin-inline: auto; width: 1184px" >
        <nz-table #basicTable [nzData]="(branches$ | async) || []" [nzPageSize]="20" nzTableLayout="fixed">
            <thead>
              <tr>
                <th nzWidth="150px">Type</th>
                <th nzWidth="250px">Name</th>
                <th nzEllipsis>Phone Numbers</th>
                <th nzWidth="200px">Location</th>
                <th nzWidth="130px">Created At</th>
                <th nzWidth="150px">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let branch of basicTable.data" (click)="viewBranchDetail(branch)">
                <td> 
                    {{ getTypeLabel(branch.type) | titlecase }}
                </td>
                <td>{{ branch.name }}</td>
                <td nzEllipsis>{{ branch.phoneNumbers }}</td>
                <td>
                  {{ branch.location?.address }} 
                  <a target="_blank" 
                    [attr.href]="'https://www.google.com/maps/search/?api=1&query=' + branch.location?.latitude + ',' + branch.location?.longitude"
                    (click)="$event.stopPropagation()">
                    {{branch.location?.latitude}}, {{branch.location?.longitude}}
                  </a>
                </td>
                <td>{{ branch.createdAt | date:'mediumDate' }}</td>
                <td>
                  <a (click)="editBranch(branch); $event.stopPropagation()">Edit</a>
                  <nz-divider nzType="vertical"></nz-divider>
                  <a (click)="deleteBranch(branch); $event.stopPropagation()">Delete</a>
                </td>
              </tr>
            </tbody>
        </nz-table>
    </nz-content>

</nz-layout>

<nz-modal 
    [(nzVisible)]="branchFormVisible" 
    [nzTitle]="branchToEdit ? 'Edit Branch' : 'Add Branch'" 
    [nzFooter]="null" nzWidth="572px" 
    (nzOnCancel)="onFormCancelled()"
    [nzBodyStyle]="{ 'padding': '0px' }">
    <ng-container *nzModalContent>
        <app-branch-form 
            [branch]="branchToEdit" 
            (formCancelled)="onFormCancelled()" 
            (formSubmitted)="onFormSubmitted($event)">
        </app-branch-form>
    </ng-container>
</nz-modal>

<nz-modal
  [nzVisible]="deleteBranchModalVisible"
  [nzTitle]="'Are you sure you want to delete the branch ' + branchToDelete?.name + '?'"
  nzOkText="Yes, Delete" nzOkDanger nzOkType="primary" (nzOnOk)="onDeleteConfirmed()"
  nzCancelText="No" (nzOnCancel)="onDeleteCancelled()">
  <ng-container *nzModalContent>
    <span style="padding: 24px; display: block;">This action cannot be undone!</span>
  </ng-container>
</nz-modal>

<nz-modal
  [nzVisible]="!!branchToViewDetail"
  [nzTitle]="branchTitle"
  [nzOkText]="null"
  nzCancelText="Close"
  (nzOnCancel)="closeBranchDetail()">
  <ng-container *nzModalContent>
    <app-branch-detail [branch]="branchToViewDetail!"></app-branch-detail>
  </ng-container>
</nz-modal>
<ng-template #branchTitle>
  <div style="display: flex; padding-right: 20px;">
    <div>{{branchToViewDetail?.name}}</div>
    <div style="flex: 1 0 auto;"></div>
    <div><nz-tag *ngIf="branchToViewDetail">{{ getTypeLabel(branchToViewDetail.type) | titlecase }}</nz-tag></div>
  </div>
</ng-template>
