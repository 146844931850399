import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { User } from '../users/user';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private jwtHelper: JwtHelperService) { }

  login(token: string, user: User) {
    localStorage.setItem('token', token);
    localStorage.setItem('user', JSON.stringify(user))
  }

  logout (){
    localStorage.removeItem('token')
    localStorage.removeItem('user');
  }

  isLoggedIn(): boolean {
    const token = localStorage.getItem('token');
    return token ? !this.jwtHelper.isTokenExpired(token) : false;
  }

  getUser(): User | null {
    const user = localStorage.getItem('user');
    if (this.isLoggedIn() && user) {
      const u = JSON.parse(user)
      return u;
    }
    return null;
  }

  setUser(user: User): void {
    localStorage.setItem('user', JSON.stringify(user))
  }

  setToken(token: string): void {
    localStorage.setItem('token', token);
  }

  getToken(): string | null {
    return localStorage.getItem('token');
  }
}
