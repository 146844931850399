
<div class="ant-form-item">
    <label class="ant-form-item-label ant-col">Location</label>
    <input type="text" class="ant-input" 
        placeholder="Select Location" matInput #locationAutocomplete
        [value]="selectedLocation?.address || ''" [disabled]="disabled">
    <button nz-button nzType="link" type="button" (click)="mapVisible = true;" [disabled]="disabled">Show on map</button>

</div>

<nz-modal
    [(nzVisible)]="!disabled && mapVisible" 
    nzTitle="Select Location" 
    nzWidth="572px" 
    [nzFooter]="null"
    (nzOnCancel)="mapVisible = false"
    [nzBodyStyle]="{ 'padding': '0px' }">
    <ng-container *nzModalContent>
        <google-map height="400px"
            width="100%"
            [center]="center"
            [zoom]="zoom"
            (mapClick)="selectLocation($event)">
            <map-marker *ngIf="selectedLocation" 
                [position]="{lat: selectedLocation.latitude, lng: selectedLocation.longitude }">
            </map-marker>
        </google-map>

        <div>Latitude: {{selectedLocation?.latitude}}</div>
        <div>Longitude: {{selectedLocation?.longitude}}</div>
        <div>Address: {{selectedLocation?.address}}</div>
    </ng-container>
</nz-modal>
