import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';

import { PriceListComponent } from './price-list/price-list.component';
import { PriceFormComponent } from './price-form/price-form.component';
import { AuthGuard } from '../auth/auth.guard';

import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzSelectModule } from 'ng-zorro-antd/select';


const routes: Routes = [
  { path: 'delivery-prices', component: PriceListComponent, canActivate: [AuthGuard] }
];

@NgModule({
  declarations: [
    PriceListComponent,
    PriceFormComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    ReactiveFormsModule,

    NzLayoutModule,
    NzPageHeaderModule,
    NzTableModule,
    NzModalModule,
    NzButtonModule,
    NzFormModule,
    NzIconModule,
    NzInputModule,
    NzMessageModule,
    NzSelectModule,
  ]
})
export class DeliveryPriceModule { }
