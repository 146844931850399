<div class="view-slip">
  <div style="display: grid; place-items: center;">
    <img style="width: 90%;" alt="payment slip"
         [src]="sanitizer.bypassSecurityTrustResourceUrl(slip.image)">
  </div>
  <div *ngIf="slip.isValid">
    <h2 style="margin: 0.5rem 0 0 1.5rem">Payment Recorded</h2>
    <div style="padding: 0.25rem 0 0.25rem 2.5rem;">
      <h3>Amount: {{slip.payment?.amount}}</h3>
      <h3>On: {{slip.payment?.paidOn | date:'medium'}}</h3>
      <h3>Remark: {{slip.payment?.remark}}</h3>
    </div>
  </div>
  <div *ngIf="!slip.isValid">
    <h2 style="padding: 0.5rem 0 1rem 1.5rem">Invalid Slip</h2>
  </div>
</div>
