<nz-layout style="height: calc(100vh - 72px); overflow-y: scroll;">
    <nz-page-header nzTitle="Users" [nzGhost]="false">
        <nz-page-header-extra>
          <button nz-button nzType="primary" (click)="addUser()">
              <span nz-icon nzType="plus"></span>
              Add User
          </button>
      </nz-page-header-extra>
    </nz-page-header>
    <nz-content style="padding-inline: 24px; margin-top: 35px; margin-inline: auto; width: 1184px" >
        <nz-table #basicTable [nzData]="(users$ | async) || []" [nzPageSize]="20" [nzTitle]="header">
            <thead>
              <tr>
                <th nzWidth="74px">Image</th>
                <th nzWidth="200px">Name</th>
                <th nzWidth="150px">Phone Number</th>
                <th>Email</th>
                <th>Roles</th>
                <th nzWidth="140px">Created At</th>
                <th nzWidth="230px">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let user of basicTable.data">
                <td>
                    <img *ngIf="user.image"
                        nz-image
                        width="30px"
                        height="30px"
                        [nzSrc]="user.image"
                        alt=""
                        style="border-radius: 50%;"
                    />
                </td>
                <td>{{ user.name }}</td>
                <td>{{ user.phoneNumber }}</td>
                <td>{{ user.email }}</td>
                <td>{{ getRolesString(user.roles) }}</td>
                <td>{{ user.createdAt | date:'mediumDate' }}</td>
                <td>
                  <a (click)="editUser(user)">Edit</a>
                  <nz-divider nzType="vertical"></nz-divider>
                  <a (click)="resetPassword(user)">Reset Password</a>
                  <nz-divider nzType="vertical"></nz-divider>
                  <a (click)="deleteUser(user)">Delete</a>
                </td>
              </tr>
            </tbody>
        </nz-table>
    </nz-content>

</nz-layout>

<ng-template #header>
  <div style="display: flex; justify-content: center; align-items: center;">
    <span nz-typography style="padding-inline: 8px">Search Users</span>
    <form (ngSubmit)="searchUsers()" style="display: flex; justify-content: center; align-items: center; width: 45%">
      <input type="text" nz-input [(ngModel)]="searchField" name="search"/>
      <button nz-button nzType="primary" type="submit">
        <span nz-icon nzType="search"></span>
        Search
      </button>
    </form>
  </div>
</ng-template>

<nz-modal
    [(nzVisible)]="userFormVisible"
    [nzTitle]="userToEdit ? 'Edit User' : 'Add User'"
    [nzFooter]="null" nzWidth="572px"
    (nzOnCancel)="onFormCancelled()"
    [nzBodyStyle]="{ 'padding': '0px' }">
    <ng-container *nzModalContent>
        <app-user-form
            [user]="userToEdit"
            (formCancelled)="onFormCancelled()"
            (formSubmitted)="onFormSubmitted($event)">
        </app-user-form>
      </ng-container>
</nz-modal>

<nz-modal
  [nzVisible]="deleteUserModalVisible"
  [nzTitle]="'Are you sure you want to delete the user ' + userToDelete?.name + '?'"
  nzOkText="Yes, Delete" nzOkDanger nzOkType="primary" (nzOnOk)="onDeleteConfirmed()"
  nzCancelText="No" (nzOnCancel)="onDeleteCancelled()">
  <ng-container *nzModalContent>
    <span style="padding: 24px; display: block;">This action cannot be undone!</span>
  </ng-container>
</nz-modal>

<nz-modal
  [nzVisible]="resetPasswordModalVisible"
  [nzTitle]="'Reset password for user ' + userToResetPassword?.name"
  [nzFooter]="null"
  (nzOnCancel)="onResetPasswordFormCancelled()"
  [nzBodyStyle]="{ 'padding': '0px' }">
  <ng-container *nzModalContent>
    <app-reset-user-password-form
      [user]="userToResetPassword"
      (formCancelled)="onResetPasswordFormCancelled()"
      (formSubmitted)="onResetPasswordFormSubmitted($event)">
    </app-reset-user-password-form>
  </ng-container>
</nz-modal>
