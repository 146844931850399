<nz-upload
    class="avatar-uploader"
    [nzAction]="getUploadUrl()"
    [nzHeaders]="getHeaders()"
    nzName="file"
    nzListType="picture-card"
    [nzShowUploadList]="true"
    [(nzFileList)]="fileList"
    [nzShowButton]="fileList.length < 1"
    (nzChange)="handleChange($event)"
    [nzRemove]="onFileRemoved">
    <ng-container *ngIf="!url">
        <span class="upload-icon" nz-icon [nzType]="'plus'"></span>
        <div class="ant-upload-text">Upload</div>
    </ng-container>
    <img alt="Image" *ngIf="url" [src]="url" style="width: 100%; height: 100%;" />
</nz-upload>