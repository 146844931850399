<form nz-form nzLayout="vertical" (ngSubmit)="onSubmit()">
  <div style="padding: 24px 24px 0px;">
    <nz-form-item>
      <nz-form-label>Products</nz-form-label>
      <nz-select
        [nzMaxTagCount]="3"
        [nzMaxTagPlaceholder]="tagPlaceHolder"
        nzMode="multiple"
        nzPlaceHolder="Select Products"
        [(ngModel)]="listOfSelectedProducts"
        name="products"
      >
        <nz-option *ngFor="let product of products" [nzLabel]="product.name" [nzValue]="product.id"></nz-option>
      </nz-select>
    </nz-form-item>
    <ng-template #tagPlaceHolder let-selectedList>and {{ selectedList.length }} more selected</ng-template>
    <div nz-row [nzGutter]="20">
      <div nz-col nzSpan="24">
        <nz-form-item>
          <nz-form-label>Discount</nz-form-label>
            <div class="discount-section">
              <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Discount Type"
                         [(ngModel)]="discountType" name="type">
                <nz-option nzLabel="Percentage" nzValue="PERCENTAGE"></nz-option>
                <nz-option nzLabel="Fixed" nzValue="FIXED"></nz-option>
              </nz-select>
              <input nz-input type="number" name="amount" placeholder="Discount Amount"
                     [(ngModel)]="discountAmount">
            </div>
        </nz-form-item>
      </div>
    </div>
  </div>
  <div class="footer">
    <button nz-button nzType="default" type="button" (click)="onCancel()">Cancel</button>
    <button [disabled]="!(listOfSelectedProducts.length > 0 && discountType && discountAmount)"
            nz-button nzType="primary" type="submit" [nzLoading]="isSubmitLoading">Submit</button>
  </div>
</form>
