export interface Notification {
    id: number;
    topic: string;
    data?: any;
    isRead: boolean;
    createdAt: string;
}

export enum NotificationTopics {
    ORDER_CREATED = 'ORDER_CREATED',
    ORDER_EDITED = 'ORDER_EDITED',
    ORDER_CANCELLED = 'ORDER_CANCELLED',
    PRODUCT_OUT_OF_STOCK = 'PRODUCT_OUT_OF_STOCK',
}