<nz-layout style="height: calc(100vh - 72px); overflow-y: scroll;">
    <nz-page-header nzTitle="Prices" [nzGhost]="false">
        <nz-page-header-extra>
          <button nz-button nzType="primary" (click)="addDeliveryPrice()">
              <span nz-icon nzType="plus"></span>
              Add Delivery Price
          </button>
      </nz-page-header-extra>
    </nz-page-header>
    <nz-content style="padding-inline: 24px; margin-top: 35px; margin-inline: auto; width: 1184px" >
        <nz-table #basicTable [nzData]="(prices$ | async) || []" [nzPageSize]="20">
            <thead>
              <tr>
                <th>Method</th>
                <th>From</th>
                <th>To</th>
                <th>Price</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let price of basicTable.data">
                <td>{{ price.method | titlecase }}</td>
                <td>{{ price.rangeStart }}</td>
                <td>{{ price.rangeEnd }}</td>
                <td>{{ price.price }}</td>
                <td>
                  <a (click)="editDeliveryPrice(price)">Edit</a>
                </td>
              </tr>
            </tbody>
        </nz-table>
    </nz-content>

</nz-layout>

<nz-modal 
    [(nzVisible)]="priceFormVisible" 
    [nzTitle]="priceToEdit ? 'Edit Price' : 'Add Price'" 
    [nzFooter]="null" nzWidth="400px" 
    (nzOnCancel)="onFormCancelled()"
    [nzBodyStyle]="{ 'padding': '0px' }">
    <ng-container *nzModalContent>
        <app-price-form 
            [price]="priceToEdit" 
            (formCancelled)="onFormCancelled()" 
            (formSubmitted)="onFormSubmitted($event)">
        </app-price-form>
      </ng-container>
</nz-modal>
