<div class="background">
  <div nz-form nzLayout="vertical" style="width: 400px;">  
    <h2 style="margin-bottom: 20px;">Edit Profile</h2>
    <div style="width: 100%; text-align: center;">
        <nz-upload
            class="avatar-uploader"
            [nzAction]="getUploadUrl()"
            [nzHeaders]="getHeaders()"
            nzName="file"
            nzListType="picture-card"
            [nzShowUploadList]="true"
            [(nzFileList)]="fileList"
            [nzShowButton]="fileList.length < 1"
            (nzChange)="handleChange($event)"
            [nzRemove]="onFileRemoved">
            <ng-container *ngIf="!profileImageUrl">
                <span class="upload-icon" nz-icon [nzType]="'plus'"></span>
                <div class="ant-upload-text">Upload</div>
            </ng-container>
            <img alt="Product Image" *ngIf="profileImageUrl" [src]="profileImageUrl" style="width: 100%; height: 100%;" />
        </nz-upload>
    </div>
    <div>
        <nz-form-item>
            <nz-form-label>Name</nz-form-label>
            <nz-form-control nzHasFeedback [nzErrorTip]="nameError">
                <nz-input-group nzPrefixIcon="user">
                    <input nz-input name="name" type="text" placeholder="Name" [ngModel]="userName" (change)="changeName($event)">
                </nz-input-group>
                <ng-template #nameError let-control>
                    <ng-container *ngIf="control.hasError('required')">Please input your name!</ng-container>
                </ng-template>
            </nz-form-control>
        </nz-form-item> 
    </div>

    <div style="text-align: right;">
        <nz-divider class="bolder" nzPlain nzText="Change Phone Number" nzOrientation="left"></nz-divider>
        <div *ngIf="step === 0">
            <nz-form-item>
                <nz-form-label>Phone Number</nz-form-label>
                <nz-form-control>
                    <nz-input-group nzPrefixIcon="mobile">
                        <input nz-input name="phoneNumber" type="text" placeholder="Phone Number" [value]="currentPhoneNumber" disabled>
                    </nz-input-group>
                </nz-form-control>
            </nz-form-item> 
            <button type="button" nz-button (click)="changePhoneNumber()">Change Phone Number</button>
        </div>
        <div *ngIf="step === 1">
            <nz-form-item>
                <nz-form-label>New Phone Number</nz-form-label>
                <nz-form-control nzHasFeedback [nzErrorTip]="phoneNumberError">
                    <nz-input-group nzPrefixIcon="mobile">
                        <input nz-input name="phoneNumber" type="number" placeholder="New Phone Number"  [(ngModel)]="newPhoneNumber" [ngModelOptions]="{ standalone: true }" #newPhoneNumberControl="ngModel" required minlength="10">
                    </nz-input-group>
                    <ng-template #phoneNumberError let-control>
                        <ng-container *ngIf="control.hasError('required')">Please input your phone number!</ng-container>
                        <ng-container *ngIf="control.hasError('minlength')">The phone number must be at least 10 digits!</ng-container>
                    </ng-template>
                </nz-form-control>
            </nz-form-item> 
            <div>
                <button type="button" nz-button (click)="cancelChangePhoneNumber()">Cancel</button>
                <button type="button" nz-button nzType="primary" (click)="getVerificationCode()" [disabled]="newPhoneNumberControl.invalid">Get Code</button>
            </div>
        </div>

        <div *ngIf="step === 2">
            <nz-form-item>
                <nz-form-label>Verification Code</nz-form-label>
                <nz-form-control nzHasFeedback nzErrorTip="Please input verification code">
                    <nz-input-group nzPrefixIcon="lock">
                        <input nz-input name="code" type="text" placeholder="Code" [(ngModel)]="code" [ngModelOptions]="{ standalone: true }" #codeControl="ngModel" required>
                    </nz-input-group>
                </nz-form-control>
            </nz-form-item> 
            <div>
                <button type="button" nz-button (click)="cancelChangePhoneNumber()">Cancel</button>
                <button type="button" nz-button nzType="primary" (click)="submitChangePhoneNumber()" [disabled]="codeControl.invalid">Verify & Set New Number</button>
            </div>
        </div>
    </div>

    <div [formGroup]="passwordForm" style="text-align: right;">
        <nz-divider class="bolder" nzPlain nzText="Change Password" nzOrientation="left"></nz-divider>
        <nz-form-item>
            <nz-form-label>Current Password</nz-form-label>
            <nz-form-control nzHasFeedback nzErrorTip="Please input your current password!">
                <nz-input-group nzPrefixIcon="lock">
                    <input nz-input type="password" formControlName="currentPassword" name="currentPassword" placeholder="Password">
                </nz-input-group>
            </nz-form-control>
        </nz-form-item> 
        <nz-form-item>
            <nz-form-label>New Password</nz-form-label>
            <nz-form-control nzHasFeedback nzErrorTip="Please input your new password!">
                <nz-input-group nzPrefixIcon="lock">
                    <input nz-input type="password" formControlName="newPassword" name="newPassword" placeholder="Password" (ngModelChange)="validateConfirmPassword()">
                </nz-input-group>
            </nz-form-control>
        </nz-form-item> 
        <nz-form-item>
            <nz-form-label>Confirm New Password</nz-form-label>
            <nz-form-control nzHasFeedback [nzErrorTip]="passwordErrorTpl">
                <nz-input-group nzPrefixIcon="lock">
                    <input nz-input type="password" formControlName="confirmNewPassword" placeholder="Confirm your new password" />
                    <ng-template #passwordErrorTpl let-control>
                      <ng-container *ngIf="control.hasError('required')">Please confirm your password!</ng-container>
                      <ng-container *ngIf="control.hasError('confirm')">Password is inconsistent!</ng-container>
                    </ng-template>
                </nz-input-group>
            </nz-form-control>
        </nz-form-item>
        <button type="button" nz-button (click)="changePassword()" [disabled]="passwordForm.invalid">Change Password</button>
    </div>
  </div>  
</div>