import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Apollo, gql } from 'apollo-angular';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzUploadChangeParam, NzUploadFile } from 'ng-zorro-antd/upload';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Role, User } from '../user';
import { usersQuery } from '../user-list/user-list.component';

interface UserInput {
  name: string;
  phoneNumber: string;
  email: string;
  roles: Role[];
  image: string;
}

const addUserMutation = gql<{ addUser: User }, { userData: UserInput, password: string }>`
  mutation AddUser($userData: UserInput!, $password: String!) {
    addUser(userData: $userData, password: $password) {
      id,
      name,
      phoneNumber,
      email,
      roles,
      image,
      createdAt,
      updatedAt
    }
  }
`
const editUserMutation = gql<{ editUser: User }, {id: number, userData: UserInput }>`
  mutation EditUser($userData: UserInput!, $id: Int!) {
    editUser(userData: $userData, id: $id) {
      id,
      name,
      phoneNumber,
      email,
      roles,
      image,
      createdAt,
      updatedAt
    }
  }
`

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.css']
})
export class UserFormComponent implements OnInit {

  @Input() user: User | null = null

  form: FormGroup; 
  isSubmitLoading = false;

  imageUploading = false;
  fileList: NzUploadFile[] = [];
  onFileRemoved: (file: NzUploadFile) => boolean | Observable<boolean> = () => {
    this.form.controls['image'].setValue('');
    return true;
  }
  getUploadUrl(): string {
    return environment.baseUrl + 'upload'
  }

  getHeaders() {
    return {
      Source: 'user_profile'
    }
  }

  @Output() formSubmitted: EventEmitter<boolean> = new EventEmitter();
  @Output() formCancelled: EventEmitter<void> = new EventEmitter();

  constructor(private fb: FormBuilder, private apollo: Apollo, private msg: NzMessageService) { 
    this.form = this.fb.group({
      image: [''],
      name: ['', [Validators.required]],
      phoneNumber: ['', [Validators.required, Validators.minLength(10)]],
      email: ['', [Validators.required, Validators.minLength(10)]],
      password: ['', [Validators.required]],
      confirm: ['', [this.confirmValidator]],
      roles: this.fb.group({
        admin: [false,],
        sales: [false,],
        marketing: [false,],
        delivery: [false,],
      }, { validators: this.rolesValidator })
    })
  }

  ngOnInit(): void {
    if (this.user) {
      this.form.removeControl('password');
      this.form.removeControl('confirm');
      this.form.setValue({
        image: this.user.image,
        name: this.user.name,
        phoneNumber: this.user.phoneNumber,
        email: this.user.email,
        roles: {
          admin: this.user.roles.includes(Role.ADMIN),
          sales: this.user.roles.includes(Role.SALES),
          marketing: this.user.roles.includes(Role.MARKETING),
          delivery: this.user.roles.includes(Role.DELIVERY),
        },
      });
      this.form.updateValueAndValidity()
      if (this.user.image) {
        this.fileList = [
          {
            uid: '1',
            name: this.user.name,
            status: 'done',
            url: this.user.image,
          }
        ]
      }

      return;
    }
    this.form.addControl('password', new FormControl('', [Validators.required]));
    this.form.addControl('confirm', new FormControl('', [this.confirmValidator]));
    this.form.updateValueAndValidity();
  }

  confirmValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return { error: true, required: true };
    } else if (control.value !== this.form.controls['password'].value) {
      return { confirm: true, error: true };
    }
    return {};
  };

  validateConfirmPassword(): void {
    setTimeout(() => this.form.get('confirm')?.updateValueAndValidity());
  }

  rolesValidator: ValidatorFn = (g: AbstractControl): ValidationErrors | null => {
    if (g.get('admin')?.value || g.get('sales')?.value || g.get('marketing')?.value || g.get('delivery')?.value) {
      return null;
    }
    return { error: true, roles: true };
  }

  handleChange(e: NzUploadChangeParam): void {
    switch (e.file.status) {
      case 'uploading':
        this.imageUploading = true;
        break;
      case 'done':
        // Get this url from response.
        this.imageUploading = false;
        const response = e.file.response
        if (response && response.success) {
          this.form.controls['image'].setValue(response.url);
        }
        break;
      case 'error':
        this.msg.error('Network error');
        this.imageUploading = false;
        break;
    }
  }

  onSubmit(): void {
    this.isSubmitLoading = true;
    if (this.form.invalid) {
      this.isSubmitLoading = false;
       return;
    } 
    // Add or edit User
    const roles: Role[] = [];
    if (this.form.get('roles.admin')?.value) {
      roles.push(Role.ADMIN)
    }
    if (this.form.get('roles.sales')?.value) {
      roles.push(Role.SALES)
    }
    if (this.form.get('roles.marketing')?.value) {
      roles.push(Role.MARKETING)
    }
    if (this.form.get('roles.delivery')?.value) {
      roles.push(Role.DELIVERY);
    }
    // console.log({roles})
    if (this.user) {
      const editUserSub = this.apollo.mutate({
        mutation: editUserMutation,
        variables: {
          id: this.user.id,
          userData: {
            name: this.form.controls['name'].value,
            phoneNumber: this.form.controls['phoneNumber'].value,
            email: this.form.controls['email'].value,
            roles: roles,
            image: this.form.controls['image'].value
          }
        }
      }).subscribe(result => {      
        const user = result.data?.editUser;
        this.isSubmitLoading = false;
        this.formSubmitted.emit(true);
        this.msg.success('User edited')
        editUserSub.unsubscribe();
      }, err => {
        this.isSubmitLoading = false;
        this.msg.error(err.message)
        console.error({err})
        editUserSub.unsubscribe();
      })
      return;
    }
    const addUserSub = this.apollo.mutate({
      mutation: addUserMutation,
      variables: {
        userData: {
          name: this.form.controls['name'].value,
          phoneNumber: this.form.controls['phoneNumber'].value,
          email: this.form.controls['email'].value,
          roles: roles,
          image: this.form.controls['image'].value
        },
        password: this.form.controls['password'].value
      },
      update: (store, result) => {
        // Read the data from our cache for this query.
        const data = store.readQuery<any>({ query: usersQuery });
        
        // Add our product from the mutation to the end.
        const users = [...data.users, result.data?.addUser];
        
        // Write our data back to the cache.
        store.writeQuery({ query: usersQuery, data: {users} });
      }
    }).subscribe(result => {
      const user = result.data?.addUser;
      this.isSubmitLoading = false;
      this.formSubmitted.emit(true);
      this.msg.success('User added')
      addUserSub.unsubscribe();
    }, err => {
      this.isSubmitLoading = false;
      this.msg.error(err.message)
      console.error({err});
      addUserSub.unsubscribe();
    })
  }
  
  onCancel(): void{
    this.formCancelled.emit();
  }

}
