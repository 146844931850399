<nz-card nzHoverable 
    [nzCover]="coverTemplate"
    [nzActions]="[actionEdit]">
    <nz-card-meta 
        [nzTitle]="product.name" 
        [nzDescription]="product.description || ''">
    </nz-card-meta>
    <div style="display: flex; justify-content: space-between; margin-top: 15px;">
        <span nz-typography>
            {{ product.price + ' ብር' }} 
            <span *ngIf="product.discount" 
                style="margin-left: 10px; padding-inline: 5px; border: solid 1px red; border-radius: 4px;">
                Discount: {{ 
                    product.discount.discountType === 'FIXED' ? 
                        product.discount.amount + ' ብር' :
                        product.discount.amount + '%'
                }}
            </span>
        </span> 
        <span>{{ product.quantity + ' in Stock' }}</span>
    </div>
</nz-card>
<ng-template #coverTemplate>
    <img alt="example" [src]="product.image" style="max-height: 284px;"/>
</ng-template>

<ng-template #actionEdit>
    <button type="button" nz-button nzType="link" style="color: rgba(0,0,0,0.85)" title="Edit" (click)="onEditButtonClicked()">
        <span nz-icon nzType="edit"></span>
    </button>
</ng-template>