import {NgModule} from '@angular/core';
import {ApolloModule, APOLLO_OPTIONS} from 'apollo-angular';
import {ApolloClientOptions, InMemoryCache, split} from '@apollo/client/core';
import {HttpLink} from 'apollo-angular/http';
import { environment } from 'src/environments/environment';
// import {WebSocketLink} from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";


const uri = environment.baseUrl + 'graphql';
const wsUri = environment.wsUrl + 'graphql';

export function createApollo(httpLink: HttpLink): ApolloClientOptions<any> {

  // Create an http link:
  const http = httpLink.create({
    uri,
  });

  // Create a WebSocket link:
  // const ws = new WebSocketLink({
  //   uri: wsUri,
  //   options: {
  //     reconnect: true,
  //   },
  // });
  const ws = new GraphQLWsLink(
    createClient({
      url: wsUri,
    }),
  );

  // using the ability to split links, you can send data to each link
  // depending on what kind of operation is being sent
  const link = split(
    // split based on operation type
    ({query}) => {
      const definition = getMainDefinition(query);
      
      return (
        definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
      );
    },
    ws,
    http,
  );

  return {
    link,
    cache: new InMemoryCache(),
  };

  // return {
  //   link: httpLink.create({uri}),
  //   cache: new InMemoryCache(),
  // };
}

@NgModule({
  exports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink],
    },
  ],
})
export class GraphQLModule {}
