import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Product} from "../product";
import {Apollo, gql} from "apollo-angular";
import {NzMessageService} from "ng-zorro-antd/message";

export interface DiscountInput {
  productIds: number[];
  discountType: string;
  amount: number;
}
const addBulkDiscountMutation = gql<{importProducts: {success: boolean, message:string}}, { discounts: DiscountInput }>`
  mutation addBulkDiscounts($discounts: DiscountInput!) {
    addBulkDiscounts(discounts: $discounts) {
      success
      message
    }
  }
`;

@Component({
  selector: 'app-bulk-discount-form',
  templateUrl: './bulk-discount-form.component.html',
  styleUrls: ['./bulk-discount-form.component.css']
})
export class BulkDiscountFormComponent implements OnInit {

  @Input() products!: Product[] | null;
  listOfSelectedProducts = [];
  isSubmitLoading: boolean = false;
  discountType: any;
  discountAmount: any;

  @Output() formSubmitted: EventEmitter<boolean> = new EventEmitter();
  @Output() formCancelled: EventEmitter<void> = new EventEmitter();

  constructor(private msg: NzMessageService, private apollo: Apollo) { }

  ngOnInit(): void { }

  onSubmit() {
    this.isSubmitLoading = true;
    const discountSub = this.apollo.mutate({
      mutation: addBulkDiscountMutation,
      variables: {
        discounts: {
          productIds: this.listOfSelectedProducts,
          discountType: this.discountType,
          amount: this.discountAmount,
        }
      }
    }).subscribe(result => {
      this.isSubmitLoading = false;
      this.formSubmitted.emit(true);
      if(result.data) {
        // @ts-ignore
        this.msg.success(result.data.addBulkDiscounts.message);
      }
      discountSub.unsubscribe();
    }, err => {
      this.isSubmitLoading = false;
      console.error({err});
      this.msg.error(err.message)
      discountSub.unsubscribe();
    })
  }

  onCancel(): void {
    this.formCancelled.emit();
  }
}
