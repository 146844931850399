<div style="padding: 24px 24px;">
    <div style="margin-bottom: 20px; margin-top: -20px">
        <a style="margin-right: 20px;" [attr.href]="downloadStockTemplateUrl">Download Stock Template</a> 
        <a [attr.href]="downloadStockUrl">Download Current Stock</a>
    </div>
    <nz-upload
        nzType="drag"
        [nzAction]="getUploadUrl()"
        [nzHeaders]="getHeaders()"
        nzName="file"
        nzListType="text"
        nzFileType="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        nzAccept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        nzLimit="1"
        [nzShowUploadList]="true"
        [(nzFileList)]="fileList"
        [nzDisabled]="fileList.length > 0"
        (nzChange)="handleChange($event)"
        [nzRemove]="onFileRemoved"
        [nzBeforeUpload]="beforeUpload">
        <ng-container *ngIf="fileList.length === 0;else fileUploaded">
            <p class="ant-upload-drag-icon">
                <span nz-icon nzType="inbox"></span>
            </p>
            <p class="ant-upload-text">Click or drag file to this area to upload</p>
        </ng-container>
        <ng-template #fileUploaded>
            <p class="ant-upload-drag-icon">
                <!-- <span nz-icon nzType="inbox"></span> -->
                <img src="assets/xlsx_icon.svg" alt="xlsx icon" nz-icon height="48px"/>
            </p>
            <p class="ant-upload-text">Click import to start product import</p>
        </ng-template>

        <!-- <p class="ant-upload-hint">
            Support for a single or bulk upload. Strictly prohibit from uploading company data or other band files
        </p> -->
    </nz-upload>
</div>


<div class="footer">
    <!-- <button nz-button nzType="default" type="button" (click)="onCancel()">Cancel</button> -->
    <button nz-button nzType="primary" type="button" [nzLoading]="isSubmitLoading" [disabled]="!fileUrl" (click)="onSubmit()">Import</button>
</div>