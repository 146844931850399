import { Component, OnInit } from '@angular/core';
import {Apollo, gql} from "apollo-angular";
import {Setting} from "../setting";
import {map} from "rxjs";
import {NzMessageService} from "ng-zorro-antd/message";

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {

  settings: Setting[] = [];
  changeTimeLimitForm: boolean = false;
  newTimeLimit!: number | null;
  timeLimit!: number;

  constructor(private apollo: Apollo, private msg: NzMessageService,) { }

  ngOnInit(): void {
    this.apollo.watchQuery({
      query: settingsQuery
    }).valueChanges.pipe(
      map(result => {
        let settings = result.data?.settings;
        return [...settings].sort((a, b) => b.id - a.id);
      })
    ).subscribe((data) => {
      this.settings = data;
      this.settings.forEach((setting) => {
        if (setting.key === 'accepted-expiry'){
          // @ts-ignore
          this.timeLimit = setting.value.value;
        }
      });
    });
  }

  openTimeLimitDialog() {
    this.changeTimeLimitForm = true;
  }

  cancelTimeLimitChange() {
    this.changeTimeLimitForm = false;
    this.newTimeLimit = null;
  }

  submitTimeLimitChange() {
    const changeTimeLimitSub = this.apollo.mutate({
      mutation: updateSettingsQuery,
      variables: {
        key: 'accepted-expiry',
        value: {
          value: this.newTimeLimit
        }
      }
    }).subscribe({
      next: () => {
        this.cancelTimeLimitChange();
        this.msg.success('Time Limit Changed');
        changeTimeLimitSub.unsubscribe();
        return;
      },
      error: (err) => {
        this.msg.error(err.message)
        console.error({err});
        changeTimeLimitSub.unsubscribe();
        return;
      }
    });
  }

}

export const settingsQuery = gql<{ settings: Setting[] }, void>`
  query Settings {
    settings {
      id,
      key,
      value,
    }
  }
`

export const updateSettingsQuery = gql<{ settings: Setting[] }, { key: string, value: object }>`
  mutation UpdateSettings ($key: String!, $value: JSONObject!){
    updateSetting (key: $key, value: $value){
      id,
      key,
      value,
    }
  }
`
