<nz-layout style="height: calc(100vh - 72px); overflow-y: scroll;">
  <nz-header style="background-color: white;">
    <h4 nz-typography style="display: inline-block;">Settings</h4>
  </nz-header>
  <nz-content style="padding-inline: 24px; margin-top: 35px; margin-inline: auto; width: 1184px" >
    <div style="width: 100%; height: 100%; padding: 2rem">
      <div class="time-limit">
        <span style="font-weight: 500; font-size: 1.2rem; margin-right: 4rem">Time limit before automatic expiry of orders: {{timeLimit}}hrs</span>
        <button
          type="button"
          nz-button
          (click)="openTimeLimitDialog()"
        >
          <span nz-icon nzType="edit"></span>
          Change
        </button>
      </div>
    </div>
  </nz-content>

</nz-layout>

<nz-modal
  [(nzVisible)]="changeTimeLimitForm"
  [nzTitle]="'Change Time Limit'"
  (nzOnCancel)="cancelTimeLimitChange()"
  nzOkText="Change"
  [nzOkDisabled]="!newTimeLimit"
  [nzCancelText]="null"
  (nzOnOk)="submitTimeLimitChange()"
>
  <div *nzModalContent>
    <form nz-form nzLayout="vertical">
      <nz-form-item>
        <nz-form-label nzRequired >Time Limit</nz-form-label>
        <nz-form-control nzErrorTip="Limit is required!">
          <input
            nz-input
            type="number"
            placeholder="Time in Hours"
            [(ngModel)]="newTimeLimit"
            [ngModelOptions]="{ standalone: true }"
            required
          >
        </nz-form-control>
      </nz-form-item>
    </form>
  </div>
</nz-modal>

