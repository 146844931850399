<form nz-form [formGroup]="form" nzLayout="vertical" (ngSubmit)="onSubmit()">
    <div style="padding: 24px 24px 0px;">
        <nz-form-item>
            <nz-form-label>Picture</nz-form-label>
            <nz-form-control>
                <nz-upload
                    class="avatar-uploader"
                    [nzAction]="getUploadUrl()"
                    [nzHeaders]="getHeaders()"
                    nzName="file"
                    nzListType="picture-card"
                    [nzShowUploadList]="true"
                    [(nzFileList)]="fileList"
                    [nzShowButton]="fileList.length < 1"
                    (nzChange)="handleChange($event)"
                    [nzRemove]="onFileRemoved">
                    <ng-container *ngIf="!form.controls['image'].value">
                        <span class="upload-icon" nz-icon [nzType]="'plus'"></span>
                        <div class="ant-upload-text">Upload</div>
                    </ng-container>
                    <img alt="Product Image" *ngIf="form.controls['image'].value" [src]="form.controls['image'].value" style="width: 100%; height: 100%;" />
              </nz-upload>
            </nz-form-control>
        </nz-form-item>
        <nz-form-item>
            <nz-form-label nzRequired>Name</nz-form-label>
            <nz-form-control nzHasFeedback [nzErrorTip]="nameError">
                <nz-input-group nzPrefixIcon="user">
                    <input nz-input formControlName="name" name="name" type="text" placeholder="Name">
                </nz-input-group>
                <ng-template #nameError let-control>
                    <ng-container *ngIf="control.hasError('required')">Please input your name!</ng-container>
                </ng-template>

            </nz-form-control>
        </nz-form-item> 
        <nz-form-item>
            <nz-form-label nzRequired>Phone Number</nz-form-label>
            <nz-form-control nzHasFeedback [nzErrorTip]="phoneNumberError">
                <nz-input-group nzPrefixIcon="mobile">
                    <input nz-input formControlName="phoneNumber" name="phoneNumber" type="text" placeholder="Phone Number">
                </nz-input-group>
                <ng-template #phoneNumberError let-control>
                    <ng-container *ngIf="control.hasError('required')">Please input your phone number!</ng-container>
                    <ng-container *ngIf="control.hasError('minlength')">The phone number must be at least 10 digits!</ng-container>
                </ng-template>
            </nz-form-control>
        </nz-form-item> 
        <nz-form-item>
            <nz-form-label nzRequired>Email</nz-form-label>
            <nz-form-control nzHasFeedback [nzErrorTip]="emailError">
                <nz-input-group nzPrefixIcon="mail">
                    <input nz-input formControlName="email" name="email" type="email" placeholder="Email">
                </nz-input-group>
                <ng-template #emailError let-control>
                    <ng-container *ngIf="control.hasError('required')">Please input email!</ng-container>
                    <ng-container *ngIf="control.hasError('minlength')">Email must be at least 10 digits!</ng-container>
                </ng-template>
            </nz-form-control>
        </nz-form-item> 
        <nz-form-item *ngIf="!user">
            <nz-form-label nzRequired>Password</nz-form-label>
            <nz-form-control nzHasFeedback nzErrorTip="Please input your password!">
                <nz-input-group nzPrefixIcon="lock">
                    <input nz-input type="password" formControlName="password" name="password" placeholder="Password" (ngModelChange)="validateConfirmPassword()">
                </nz-input-group>
            </nz-form-control>
        </nz-form-item> 
        <nz-form-item *ngIf="!user">
            <nz-form-label nzRequired>Confirm Password</nz-form-label>
            <nz-form-control nzHasFeedback [nzErrorTip]="passwordErrorTpl">
              <input nz-input type="password" formControlName="confirm" placeholder="Confirm your password" />
              <ng-template #passwordErrorTpl let-control>
                <ng-container *ngIf="control.hasError('required')">Please confirm your password!</ng-container>
                <ng-container *ngIf="control.hasError('confirm')">Password is inconsistent!</ng-container>
              </ng-template>
            </nz-form-control>
        </nz-form-item>
        <nz-form-item>
            <nz-form-label nzRequired>Roles</nz-form-label>
            <nz-form-control nzErrorTip="Select at least 1 role">
                <div formGroupName="roles" style="display: flex;" >
                    <label nz-checkbox formControlName="admin">
                        <span>Admin</span>
                    </label>
                    <label nz-checkbox formControlName="sales">
                        <span>Sales</span>
                    </label>
                    <label nz-checkbox formControlName="marketing">
                        <span>Marketing</span>
                    </label>
                    <label nz-checkbox formControlName="delivery">
                        <span>Delivery</span>
                    </label>
                </div>
            </nz-form-control>
 
        </nz-form-item> 
    </div>
    <div class="footer">
        <button nz-button nzType="default" type="button" (click)="onCancel()">Cancel</button>
        <button nz-button nzType="primary" type="submit" [nzLoading]="isSubmitLoading" [disabled]="form.invalid">Submit</button>
    </div>
</form>