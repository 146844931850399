<div class="background">
    <div class="logo">
        <img src="../../../assets/logo.svg" alt="logo" width="79px">
        <h1 nz-typography>Shop Admin</h1>
    </div>

    <nz-tabset>
        <nz-tab nzTitle="Reset Password">
            <form [formGroup]="form" nz-form nzLayout="vertical" (ngSubmit)="onSubmit()">
                <ng-container *ngIf="step === 1">
                    <nz-form-item nzRequired>
                        <!-- <nz-form-label>Phone Number</nz-form-label> -->
                        <nz-form-control [nzErrorTip]="(medium === 'phone' ? 'Phone number' : 'Email') + ' is required'!" #control>
                            <nz-input-group [nzPrefix]="medium === 'phone' ? prefixTemplatePhone : prefixTemplateEmail">
                                <input nz-input type="text" placeholder="Phone or Email" [placeholder]="medium === 'phone' ? 'Phone number' : 'Email'" formControlName="phoneOrEmail" name="phoneOrEmail">
                            </nz-input-group>
                            <ng-template #prefixTemplatePhone><span nz-icon nzType="mobile" (click)="changeMedium()"></span></ng-template>
                            <ng-template #prefixTemplateEmail><span nz-icon nzType="mail" (click)="changeMedium()"></span></ng-template>
                        </nz-form-control>
                    </nz-form-item>
                    <div class="button-wrapper">
                        <button type="button" nz-button nzType="default" 
                            [disabled]="(control.validateControl && control.validateControl.invalid) || !control.validateControl"
                            (click)="goToStep2()">
                            Get Code
                        </button>
                    </div>
                </ng-container>
                <ng-container *ngIf="step === 2">
                    <nz-form-item nzRequired>
                        <!-- <nz-form-label>Phone Number</nz-form-label> -->
                        <nz-form-control nzErrorTip="Code is required!" #control>
                            <nz-input-group nzPrefixIcon="lock">
                                <input nz-input type="text" placeholder="Verification code" formControlName="code" name="code">
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>
                    <div class="button-wrapper">
                        <button type="button" nz-button nzType="default" 
                            [disabled]="(control.validateControl && control.validateControl.invalid) || !control.validateControl"
                            (click)="goToStep3()">
                            Verify
                        </button>
                    </div>
                </ng-container>
                <ng-container *ngIf="step === 3">
                    <nz-form-item nzRequired>
                        <!-- <nz-form-label>Password</nz-form-label> -->
                        <nz-form-control nzErrorTip="Password is required!">
                            <nz-input-group nzPrefixIcon="lock">
                                <input nz-input type="password" placeholder="New password" formControlName="password" name="password">
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item>
                        <!-- <nz-form-label nzRequired>Confirm Password</nz-form-label> -->
                        <nz-form-control [nzErrorTip]="passwordError">
                            <nz-input-group nzPrefixIcon="lock">
                                <input nz-input type="password" formControlName="confirm" placeholder="Confirm your password" name="confirm"/>
                            </nz-input-group>
                            <ng-template #passwordError let-control>
                                <ng-container *ngIf="control.hasError('required')">Please confirm your password!</ng-container>
                                <ng-container *ngIf="control.hasError('confirm')">Password is inconsistent!</ng-container>
                            </ng-template>
                        </nz-form-control>
                    </nz-form-item>
                    <div class="button-wrapper">
                        <button type="submit" nz-button nzType="primary" [disabled]="form.invalid">Reset</button>
                    </div>
                </ng-container>



            </form>
        </nz-tab>
    </nz-tabset>
</div>
