<nz-layout>
  <nz-sider class="sider">
    <ul nz-menu nzMode="inline" [nzInlineIndent]="48">
      <li nz-menu-group nzTitle="Status">
        <ul>
          <li nz-menu-item [routerLink]="['./']" nzSelected="true">All</li>
          <li
            nz-menu-item
            [routerLink]="['./']"
            [queryParams]="{ status: 'new' }"
          >
            New
          </li>
          <li
            nz-menu-item
            [routerLink]="['./']"
            [queryParams]="{ status: 'accepted' }"
          >
            Accepted
          </li>
          <li
            nz-menu-item
            [routerLink]="['./']"
            [queryParams]="{ status: 'pending' }"
          >
            Pending
          </li>
          <li
            nz-menu-item
            [routerLink]="['./']"
            [queryParams]="{ status: 'rejected' }"
          >
            Rejected
          </li>
          <li
            nz-menu-item
            [routerLink]="['./']"
            [queryParams]="{ status: 'completed' }"
          >
            Completed
          </li>
          <li
            nz-menu-item
            [routerLink]="['./']"
            [queryParams]="{ status: 'cancelled' }"
          >
            Cancelled
          </li>
        </ul>
      </li>
    </ul>
  </nz-sider>
  <nz-layout style="height: calc(100vh - 72px)">
    <nz-header style="background-color: white">
      <h4 nz-typography style="display: inline-block">Order</h4>
    </nz-header>
    <nz-content
      style="
        padding-inline: 24px;
        overflow-y: scroll;
        margin-top: 35px;
        margin-inline: auto;
        width: 1184px;
      "
    >
      <nz-table
        #basicTable
        [nzData]="(orders$ | async) || []"
        [nzPageSize]="20"
        [nzTitle]="header"
      >
        <thead>
          <tr>
            <th nzWidth="32px"></th>
            <th nzWidth="113px">Number</th>
            <th nzWidth="150px" nzBreakWord>Customer</th>
            <th nzWidth="142px">Phone Number</th>
            <th>Items</th>
            <th nzWidth="100px">Total Price</th>
            <th nzWidth="100px" nzBreakWord>Delivery</th>
            <th nzWidth="100px">Status</th>
            <th nzWidth="120px">Date</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let order of basicTable.data">
            <tr>
              <td
                [nzExpand]="expandSet.has(order.id)"
                (nzExpandChange)="onExpandChange(order.id, $event)"
              ></td>
              <td>{{ order.code }}</td>
              <td>
                <a (click)="navigateTo(order.customer.id)">
                  {{ order.customer.name }}
                </a>
              </td>
              <td>{{ order.customer.phoneNumber }}</td>
              <td>{{ getItemsString(order.orderItems) }}</td>
              <td>{{ order.totalCost }}</td>
              <td>
                <span *ngIf="!order.isDelivery; else delivery">Walk In</span>
                <ng-template #delivery>
                  <span [innerHTML]="getDeliveryLocation(order)"></span>
                </ng-template>
              </td>
              <td>
                <span nz-tooltip="" [nzTooltipTitle]="getStatusTooltip(order)">
                  {{ order.status | titlecase }}
                </span>
              </td>
              <td
                nz-tooltip="order time"
                [nzTooltipTitle]="order.createdAt | date : 'shortTime'"
              >
                {{ order.createdAt | date : "mediumDate" }}
              </td>
            </tr>
            <tr [nzExpand]="expandSet.has(order.id)">
              <div style="padding-left: 56px">
                <div style="display: flex; justify-content: space-between">
                  <span><span>መልእክት: </span>{{ order.note }}</span>
                  <span>
                    <span>Slips:</span>
                    <ng-container
                      *ngFor="let slip of order.slips; let i = index"
                    >
                      <img
                        class="payment-slip"
                        [src]="sanitizer.bypassSecurityTrustResourceUrl(slip.image)"
                        alt="payment slip"
                        (click)="
                          slip['isValid'] === undefined || slip['isValid'] === null
                            ? openSlipValidationDialog(slip, order, i)
                            : openViewSlipDialog(slip)
                        "
                      />
                    </ng-container>
                  </span>
                  <div>
                    <ng-container *ngIf="order.status === 'NEW'">
                      <button
                        type="button"
                        nz-button
                        (click)="acceptOrder(order)"
                        style="color: #52c41a; border-color: #52c41a"
                      >
                        <span nz-icon nzType="check"></span>
                        Accept
                      </button>
                      <button
                        type="button"
                        nz-button
                        nzDanger
                        (click)="rejectOrder(order)"
                      >
                        <span nz-icon nzType="close"></span>
                        Reject
                      </button>
                    </ng-container>
                    <button
                      *ngIf="
                        order.status === 'NEW' || order.status === 'PENDING'
                      "
                      type="button"
                      nz-button
                      (click)="manageOrder(order)"
                    >
                      <span nz-icon nzType="edit"></span>
                      Manage
                    </button>
                    <button
                      *ngIf="order.status === 'ACCEPTED'"
                      type="button"
                      nz-button
                      nzDanger
                      (click)="cancelOrder(order)"
                    >
                      <span nz-icon nzType="close"></span>
                      Cancel
                    </button>
                    <button
                      *ngIf="order.status === 'ACCEPTED' && order['slips']"
                      type="button"
                      nz-button
                      (click)="completeOrder(order)"
                    >
                      <span nz-icon nzType="check"></span>
                      Complete
                    </button>
                  </div>
                </div>
                <ng-container *ngIf="expandSet.has(order.id)">
                  <div *ngIf="getByForOrder(order) | async as byMap">
                    <div *ngIf="order.status === 'ACCEPTED'">
                      {{ getAcceptedByString(order, byMap) }}
                      {{
                        order.acceptedAt
                          ? " at " + (order.acceptedAt | date : "full")
                          : " at Unknown"
                      }}
                    </div>
                    <div *ngIf="order.status === 'COMPLETED'">
                      <div>
                        {{ getAcceptedByString(order, byMap) }}
                        {{
                          order.acceptedAt
                            ? " at " + (order.acceptedAt | date : "full")
                            : " at Unknown"
                        }}
                      </div>
                      <div>
                        {{ getCompletedByString(order, byMap) }}
                        {{
                          order.completedAt
                            ? " at " + (order.completedAt | date : "full")
                            : " at Unknown"
                        }}
                      </div>
                    </div>
                    <div *ngIf="order.status === 'REJECTED'">
                      <div>
                        {{ getRejectedByString(order, byMap) }}
                        {{
                          order.rejectedAt
                            ? " at " + (order.rejectedAt | date : "full")
                            : " at Unknown"
                        }}
                      </div>
                      <div>Reason: {{ getStatusTooltip(order) }}</div>
                    </div>
                    <div *ngIf="order.status === 'CANCELLED'">
                      <div>Cancelled</div>
                      <div>Reason: {{ getStatusTooltip(order) }}</div>
                    </div>
                  </div>
                </ng-container>
                
                <div *ngIf="order.deliveryCost || order.discount">Sub Total: {{ order.subTotal }} ETB</div>
                <div *ngIf="order.deliveryCost">Delivery Cost: {{ order.deliveryCost }} ETB</div>
                <div *ngIf="order.discount">Discount: {{ order.discount }} ETB</div>
                <div>Paid: {{ order.totalPaid }} ETB</div>
              </div>
            </tr>
          </ng-container>
        </tbody>
      </nz-table>
    </nz-content>
  </nz-layout>
</nz-layout>

<ng-template #header>
  <div style="display: flex; justify-content: center; align-items: center;">
    <span nz-typography style="padding-inline: 8px">Search Orders</span>
    <form (ngSubmit)="searchOrders()" style="display: flex; justify-content: center; align-items: center; width: 45%">
      <input type="text" nz-input [(ngModel)]="searchField" name="search" placeholder="Search Orders"/>
      <button nz-button nzType="primary" type="submit">
        <span nz-icon nzType="search"></span>
        Search
      </button>
    </form>
  </div>
  <div style="display: flex; justify-content: space-evenly; align-items: center; padding-top: 1rem">
    <span nz-typography style="padding-inline: 8px">Filter by Date</span>
    <nz-range-picker
      ngModel
      (ngModelChange)="onDateRangeChanged($event)"
    ></nz-range-picker>
    <span nz-typography style="padding-inline: 8px">Filter by Payment</span>
    <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select Payment Status" [(ngModel)]="paymentFilter"
    (ngModelChange)="filterOrdersByPaymentStatus()">
      <nz-option nzLabel="Paid" nzValue="paid"></nz-option>
      <nz-option nzLabel="Unpaid" nzValue="unpaid"></nz-option>
      <nz-option nzLabel="Overpaid" nzValue="overpaid"></nz-option>
      <nz-option nzLabel="Underpaid" nzValue="underpaid"></nz-option>
    </nz-select>
    <span nz-typography style="padding-inline: 8px">Filter by Delivery</span>
    <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select Delivery Type" [(ngModel)]="deliveryFilter"
    (ngModelChange)="filterOrdersByDeliveryType()">
      <nz-option nzLabel="Delivery" nzValue="delivery"></nz-option>
      <nz-option nzLabel="Walk In" nzValue="walkIn"></nz-option>
    </nz-select>
  </div>
</ng-template>

<nz-modal
  [(nzVisible)]="slipValidationVisible"
  [nzTitle]="'Validate Slip'"
  [nzFooter]="null"
  nzWidth="600px"
  (nzOnCancel)="onSlipValidationCancelled()"
  [nzBodyStyle]="{ padding: '0px' }"
>
  <ng-container *nzModalContent>
    <app-slip-validation
      [slip]="slipToValidate"
      [order]="orderWithSlipToValidate"
      [slipIndex]="slipIndex"
      (formCancelled)="onSlipValidationCancelled()"
      (formSubmitted)="onSlipValidationSubmitted($event)"
    >
    </app-slip-validation>
  </ng-container>
</nz-modal>

<nz-modal
  [(nzVisible)]="validatedSlipVisible"
  [nzTitle]="'View Slip'"
  [nzFooter]="null"
  nzWidth="600px"
  (nzOnCancel)="onSlipViewCancelled()"
  [nzBodyStyle]="{ padding: '0px' }"
>
  <ng-container *nzModalContent>
    <app-view-slip [slip]="slipToView"> </app-view-slip>
  </ng-container>
</nz-modal>

<nz-modal
  [(nzVisible)]="rejectionReasonFormVisible"
  [nzTitle]="'Rejecting Order ' + orderToReject?.code"
  (nzOnCancel)="cancelRejectOrder()"
  nzOkText="Reject"
  nzOkDanger="true"
  [nzOkDisabled]="!rejectionReason"
  [nzCancelText]="null"
  (nzOnOk)="submitRejectOrder()"
>
  <div *nzModalContent>
    <form nz-form nzLayout="vertical">
      <nz-form-item>
        <nz-form-label nzRequired>Rejection Reason</nz-form-label>
        <nz-form-control nzErrorTip="Reason is required!">
          <textarea
            nz-input
            placeholder="Reason"
            [(ngModel)]="rejectionReason"
            [ngModelOptions]="{ standalone: true }"
            required
          ></textarea>
        </nz-form-control>
      </nz-form-item>
    </form>
  </div>
</nz-modal>

<nz-modal
  [(nzVisible)]="acceptOrderFormVisible"
  [nzTitle]="'Accept Order ' + orderToAccept?.code"
  (nzOnCancel)="cancelAcceptOrder()"
  nzOkText="Accept"
  [nzOkDisabled]="deliveryForm.invalid"
  (nzOnOk)="submitAcceptOrder()"
>
  <div *nzModalContent>
    <form nz-form nzLayout="vertical" [formGroup]="deliveryForm">
      <nz-form-item>
        <nz-form-label nzRequired>Method</nz-form-label>
        <nz-form-control>
          <nz-select
            formControlName="method"
            nzPlaceHolder="Method"
            (ngModelChange)="validateCost()"
          >
            <nz-option
              *ngFor="let method of methods"
              [nzValue]="method"
              [nzLabel]="method | titlecase"
            >
            </nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label nzRequired>Branch</nz-form-label>
        <nz-form-control>
          <nz-select
            formControlName="branch"
            nzPlaceHolder="Branch"
            (ngModelChange)="validateCost()"
          >
            <nz-option
              *ngFor="let branch of branches$ | async"
              [nzValue]="branch"
              [nzLabel]="branch.name | titlecase"
            >
            </nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label
          >Cost
          <span
            style="
              opacity: 0.7;
              font-size: 10px;
              font-weight: 300;
              margin-left: 10px;
              padding-top: 4px;
              color: red;
            "
            >{{ costHint }}</span
          ></nz-form-label
        >
        <nz-form-control
          nzErrorTip="Cost is required and must be greater than 0!"
        >
          <input
            type="number"
            nz-input
            [placeholder]="costHint"
            placeholder="0"
            formControlName="cost"
            name="Cost"
          />
        </nz-form-control>
      </nz-form-item>
    </form>
  </div>
</nz-modal>

<nz-modal
  [(nzVisible)]="cancellationReasonFormVisible"
  [nzTitle]="'Cancelling Order ' + orderToCancel?.code"
  (nzOnCancel)="abortOrderCancellation()"
  nzOkText="Cancel"
  nzOkDanger="true"
  [nzOkDisabled]="!cancellationReason"
  [nzCancelText]="null"
  (nzOnOk)="submitCancelOrder()"
>
  <div *nzModalContent>
    <form nz-form nzLayout="vertical">
      <nz-form-item>
        <nz-form-label nzRequired >Cancellation Reason</nz-form-label>
        <nz-form-control nzErrorTip="Reason is required!">
          <textarea
            nz-input
            placeholder="Reason"
            [(ngModel)]="cancellationReason"
            [ngModelOptions]="{ standalone: true }"
            required
          ></textarea>
        </nz-form-control>
      </nz-form-item>
    </form>
  </div>
</nz-modal>

<nz-modal
    [(nzVisible)]="acceptOrderFormVisible"
    [nzTitle]="'Accept Order ' + orderToAccept?.code"
    (nzOnCancel)="cancelAcceptOrder()"
    nzOkText="Accept"
    [nzOkDisabled]="deliveryForm.invalid"
    (nzOnOk)="submitAcceptOrder()">
    <div *nzModalContent>
        <form nz-form nzLayout="vertical" [formGroup]="deliveryForm">
            <nz-form-item>
                <nz-form-label nzRequired>Method</nz-form-label>
                <nz-form-control>
                    <nz-select formControlName="method" nzPlaceHolder="Method" (ngModelChange)="validateCost()">
                        <nz-option *ngFor="let method of methods"
                            [nzValue]="method"
                            [nzLabel]="method | titlecase">
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label nzRequired>Branch</nz-form-label>
                <nz-form-control>
                    <nz-select formControlName="branch" nzPlaceHolder="Branch"  (ngModelChange)="validateCost()">
                        <nz-option *ngFor="let branch of branches$ | async"
                            [nzValue]="branch"
                            [nzLabel]="branch.name | titlecase">
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label>Cost <span style="opacity: 0.7; font-size: 10px; font-weight:300; margin-left: 10px; padding-top:4px; color: red;">{{costHint}}</span></nz-form-label>
                <nz-form-control nzErrorTip="Cost is required!">
                    <input type="number" nz-input [placeholder]="costHint" placeholder="0" formControlName="cost" name="Cost">
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
</nz-modal>


<nz-drawer
  nzWidth="923px"
  [nzClosable]="true"
  [nzVisible]="manageOrderVisible"
  nzPlacement="right"
  [nzTitle]="title"
  [nzExtra]="orderToManage?.status || '' | titlecase"
  (nzOnClose)="onManageOrderCancelled()"
>
  <ng-container *nzDrawerContent>
    <app-manage-order
      *ngIf="orderToManage"
      [order]="orderToManage"
      (formCancelled)="onManageOrderCancelled()"
      (formSubmitted)="onManageOrderSubmitted($event)"
    >
    </app-manage-order>
  </ng-container>
</nz-drawer>
<ng-template #title>
  {{ orderToManage ? "Manage Order " + orderToManage.code : "Manage Order" }}
</ng-template>
