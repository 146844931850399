import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { GraphQLModule } from './graphql.module';
import { HttpClientModule } from '@angular/common/http';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { en_US } from 'ng-zorro-antd/i18n';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzNotificationModule } from 'ng-zorro-antd/notification';

import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { OrdersModule } from './orders/orders.module';
import { CustomersModule } from './customers/customers.module';
import { UsersModule } from './users/users.module';
import { ProductsModule } from './products/products.module';
import { NotificationsModule } from './notifications/notifications.module';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { AuthModule } from './auth/auth.module';
import { httpInterceptorProviders } from './http-interceptors';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { NzConfig, NZ_CONFIG } from 'ng-zorro-antd/core/config';
import { ResourcesModule } from './resources/resources.module';
import { BranchesModule } from './branches/branches.module';
import { DeliveryPriceModule } from './delivery-price/delivery-price.module';
import {SettingsModule} from "./settings/settings.module";

registerLocaleData(en);

const ngZorroConfig: NzConfig = {
  notification: { nzPlacement: 'bottomRight', nzDuration: 0 }
};

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
  ],
  imports: [
    BrowserModule,
    GraphQLModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,

    NzMenuModule,
    NzPageHeaderModule,
    NzIconModule,
    NzButtonModule,
    NzBadgeModule,
    NzSpaceModule,
    NzDropDownModule,
    NzNotificationModule,

    OrdersModule,
    CustomersModule,
    UsersModule,
    ProductsModule,
    NotificationsModule,
    AuthModule,
    ResourcesModule,
    BranchesModule,
    SettingsModule,
    DeliveryPriceModule,
    AppRoutingModule,
  ],
  providers: [
    httpInterceptorProviders,
    { provide: NZ_I18N, useValue: en_US },
    { provide: NZ_CONFIG, useValue: ngZorroConfig }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
