<form nz-form nzLayout="vertical" [formGroup]="form" (ngSubmit)="onSubmit()">
    <div style="padding: 24px 24px 0px;">
        <nz-form-item>
            <!-- <nz-form-label>Image</nz-form-label> -->
            <nz-form-control>
                <nz-upload
                    class="avatar-uploader"
                    [nzAction]="getUploadUrl()"
                    [nzHeaders]="getHeaders()"
                    nzName="file"
                    nzListType="picture-card"
                    [nzShowUploadList]="true"
                    [(nzFileList)]="fileList"
                    [nzShowButton]="fileList.length < 1"
                    (nzChange)="handleChange($event)"
                    [nzRemove]="onFileRemoved">
                    <ng-container *ngIf="!form.controls.image.value">
                        <span class="upload-icon" nz-icon [nzType]="'plus'"></span>
                        <div class="ant-upload-text">Upload</div>
                    </ng-container>
                    <img alt="Category Image" *ngIf="form.controls.image.value" [src]="form.controls.image.value" style="width: 100%; height: 100%;" />
            </nz-upload>
            </nz-form-control>
        </nz-form-item>
        <nz-form-item>
            <nz-form-label nzRequired>Name</nz-form-label>
            <nz-form-control nzErrorTip="Name is Required!">
                <input nz-input formControlName="name" name="name" type="text" placeholder="Name">
            </nz-form-control>
        </nz-form-item>
    </div>
    <div class="footer">
        <button nz-button nzType="default" type="button" (click)="onCancel()">Cancel</button>
        <button nz-button nzType="primary" type="submit" [nzLoading]="isSubmitLoading" [disabled]="form.invalid">Submit</button>
    </div>
</form>
