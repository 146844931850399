import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Apollo, gql } from 'apollo-angular';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzUploadChangeParam, NzUploadFile } from 'ng-zorro-antd/upload';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Branch, BranchType, Employee } from '../branch';
import { branchesQuery } from '../branch-list/branch-list.component';

@Component({
  selector: 'app-branch-form',
  templateUrl: './branch-form.component.html',
  styleUrls: ['./branch-form.component.css']
})
export class BranchFormComponent implements OnInit {


  @Input() branch: Branch | null = null
  readonly types = Object.keys(BranchType);
  getTypeLabel(type: string): string {
    return type.replace(/[_]/g, ' ')
  }
  form = this.fb.nonNullable.group({
    type: ['', [Validators.required]],
    name: ['', [Validators.required]],
    description: ['', Validators.required],
    phoneNumbers: this.fb.nonNullable.array<string>([]),
    location: ['' as any,],
    employees: this.fb.array<FormGroup>([])
  });

  get phoneNumbers() {
    return this.form.controls.phoneNumbers;
  }

  get employees() {
    return this.form.controls.employees;
  }
  isSubmitLoading = false;

  @Output() formSubmitted: EventEmitter<boolean> = new EventEmitter();
  @Output() formCancelled: EventEmitter<void> = new EventEmitter();

  constructor(private fb: FormBuilder, private apollo: Apollo, private msg: NzMessageService) { }

  ngOnInit(): void {
    if (this.branch) {
      let location = null;
      if (this.branch.location) {
        location = {
          latitude: this.branch.location.latitude,
          longitude: this.branch.location.longitude,
          address: this.branch.location.address,
        }
      }
      this.form.setValue({
        type: this.branch.type,
        name: this.branch.name,
        description: this.branch.description,
        phoneNumbers: [],
        location: location,
        employees: []
      });
      if (this.branch.phoneNumbers && this.branch.phoneNumbers.length) {
        this.branch.phoneNumbers.forEach(phone => {
          this.phoneNumbers.push(new FormControl<string>(phone, { nonNullable: true, validators: [Validators.required, Validators.minLength(10)] },));
        })
      }
      if(this.branch.employees && this.branch.employees.length) {
        this.branch.employees.forEach(employee => {
          this.employees.push(this.fb.nonNullable.group({
            name: new FormControl<string>(employee.name, { nonNullable: true, validators: [Validators.required, Validators.minLength(5)] }),
            position: new FormControl<string>(employee.position, { nonNullable: true, validators: [Validators.required, Validators.minLength(2)] }),
            photo: new FormControl(employee.photo),
            description: new FormControl(employee.description, { nonNullable: true, validators: [Validators.minLength(10)] })
          }));
        });
      }
      return;
    }
  }

  addPhoneNumber(){
    this.phoneNumbers.push(new FormControl<string>('', { nonNullable: true, validators: [Validators.required, Validators.minLength(10)] },));
  }
  removePhoneNumber(index: number) {
    this.phoneNumbers.removeAt(index);
  }

  addEmployee() {
    const employeeForm = this.fb.nonNullable.group({
      name: ['', [Validators.required, Validators.minLength(5)]],
      position: ['',[Validators.required, Validators.minLength(2)]],
      photo: ['',],
      description: ['', [Validators.minLength(10)]],
    });
    this.employees.push(employeeForm)
  }

  removeEmployee(index: number) {
    this.employees.removeAt(index);
  }

  onSubmit(): void {
    this.isSubmitLoading = true;
    if (this.form.invalid) {
      this.isSubmitLoading = false;
      return;
    }
    const formInputs = this.form.getRawValue();
    if(!formInputs.location) {
      formInputs.location = null as any;
    }
    if(!formInputs.employees.length) {
      formInputs.employees = null as any;
    }
    // Add or edit Branch
    if (this.branch) {
      const editBranchSub = this.apollo.mutate({
        mutation: editBranchMutation,
        variables: {
          id: this.branch.id,
          branchData: formInputs
        }
      }).subscribe(result => {
        const branch = result.data?.editBranch;
        this.isSubmitLoading = false;
        this.formSubmitted.emit(true);
        this.msg.success('Branch edited')
        editBranchSub.unsubscribe();
      }, err => {
        this.isSubmitLoading = false;
        this.msg.error(err.message)
        console.error({err})
        editBranchSub.unsubscribe();
      })
      return;
    }

    const addBranchSub = this.apollo.mutate({
      mutation: addBranchMutation,
      variables: {
        branchData: formInputs,
      },
      update: (store, result) => {
        // Read the data from our cache for this query.
        const data = store.readQuery<any>({ query: branchesQuery });

        // Add our product from the mutation to the end.
        const branches = [...data.branches, result.data?.addBranch];

        // Write our data back to the cache.
        store.writeQuery({ query: branchesQuery, data: {branches} });
      }
    }).subscribe(result => {
      const branch = result.data?.addBranch;
      this.isSubmitLoading = false;
      this.formSubmitted.emit(true);
      this.msg.success('Branch added')
      addBranchSub.unsubscribe();
    }, err => {
      this.isSubmitLoading = false;
      this.msg.error(err.message)
      console.error({err});
      addBranchSub.unsubscribe();
    })
  }

  onCancel(): void{
    this.formCancelled.emit();
  }

}

interface BranchInput {
  type: string;
  name: string;
  description: string;
  phoneNumbers?: string[];
}

const addBranchMutation = gql<{ addBranch: Branch }, { branchData: BranchInput }>`
  mutation AddBranch($branchData: BranchInput!) {
    addBranch(branchData: $branchData) {
      id,
      type,
      name,
      description,
      phoneNumbers,
      location {
        latitude,
        longitude,
        address
      },
      employees{
        name,
        position,
        description,
        photo,
      },
      createdAt,
    }
  }
`
const editBranchMutation = gql<{ editBranch: Branch }, { id: number, branchData: BranchInput }>`
  mutation EditBranch($branchData: BranchInput!, $id: Int!) {
    editBranch(branchData: $branchData, id: $id) {
      id,
      type,
      name,
      description,
      phoneNumbers,
      location {
        latitude,
        longitude,
        address
      },
      employees{
        name,
        position,
        description,
        photo,
      },
      createdAt,
      updatedAt
    }
  }
`
