import {Component, NgZone, OnInit} from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { EMPTY, map, Observable, of } from 'rxjs';
import { Customer } from '../customer';
import {Router} from "@angular/router";
import { NzMessageService } from 'ng-zorro-antd/message';

export const customersQuery = gql<{customers: Customer[] }, void>`
	query {
    customers {
      id,
      name,
      companyName,
      phoneNumber,
      image,
      tin,
      createdAt,
      hasTelegramAccount
    }
	}
`
export const customersSearchQuery = gql<{customers: Customer[] }, { value: string }>`
	query SearchCustomers($value: String!) {
    customersSearch(value: $value){
      id,
      name,
      companyName,
      phoneNumber,
      image,
      tin,
      createdAt,
      hasTelegramAccount
    }
	}
`

export const sendMessageToCustomerMutation = gql<{ sendMessageToCustomer: { success: boolean, message: string } }, { customerId: number, message: string }>`
  mutation SendMessage($customerId: Int!, $message: String!) {
    sendMessageToCustomer(customerId: $customerId, message: $message) {
      success,
      message,
    }
  }
`
@Component({
  selector: 'app-customer-list',
  templateUrl: './customer-list.component.html',
  styleUrls: ['./customer-list.component.css']
})
export class CustomerListComponent implements OnInit {

  customers$: Observable<Customer[]> = EMPTY
  searchField: any;
  isAllCustomers: boolean = true;

  sendMessageFormVisible = false;
  customerToSendMessage: Customer | null = null;
  message: string | null = null;

  constructor(private apollo: Apollo, private router: Router, private ngZone:NgZone, private msg: NzMessageService,) { }

  ngOnInit(): void {
    this.getAllCustomers();
  }

  getAllCustomers(){
    this.customers$ = this.apollo.watchQuery({
      query: customersQuery
    }).valueChanges.pipe(
      map(result =>  {
        let customers = result.data?.customers;
        return [...customers].sort((a,b) => b.id - a.id) // shorthand method to sort by created date since the id is incremented
      }),
    );
  }

  searchCustomers() {
    if (!this.searchField && this.isAllCustomers) return;
    if(this.searchField) {
      this.customers$ = this.apollo.watchQuery({
        query: customersSearchQuery,
        variables: {
          value: this.searchField
        }
      }).valueChanges.pipe(
        map(result => {
          // @ts-ignore
          let customers = result.data?.customersSearch;
          return [...customers].sort((a, b) => b.id - a.id);
        }),
      );
      this.isAllCustomers = false;
    } else if (!this.isAllCustomers){
      this.getAllCustomers();
      this.isAllCustomers = true;
    }
  }

  navigateTo(customerId: number) {
    this.ngZone.run(() => this.router.navigate(['/customer', customerId])).then(() => {return});
  }

  sendMessage(customer: Customer) {
    this.customerToSendMessage = customer;
    this.sendMessageFormVisible = true;
  }

  cancelSendMessage() {
    this.customerToSendMessage = null;
    this.message = null;
    this.sendMessageFormVisible = false;
  }

  submitSendMessage() {
    if (this.customerToSendMessage && this.message) {
      const sendMessageToCustomerSub = this.apollo.mutate({
        mutation: sendMessageToCustomerMutation,
        variables: {
          customerId: this.customerToSendMessage.id,
          message: this.message,
        }
      }).subscribe({
        next: () => {
          this.cancelSendMessage();
          this.msg.success('Message sent')
          sendMessageToCustomerSub.unsubscribe();
        },
        error: (err) => {
        this.msg.error(err.message)
        console.error({err});
        sendMessageToCustomerSub.unsubscribe();
        },
      });
    }
  }
}
