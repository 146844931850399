import { Component, OnInit } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Observable, EMPTY, map } from 'rxjs';
import { Branch } from '../branch';


@Component({
  selector: 'app-branch-list',
  templateUrl: './branch-list.component.html',
  styleUrls: ['./branch-list.component.css']
})
export class BranchListComponent implements OnInit {

  branches$: Observable<Branch[]> = EMPTY;

  branchToEdit: Branch | null = null;
  branchFormVisible = false;

  branchToViewDetail: Branch | null = null;

  branchToDelete: Branch | null = null;
  deleteBranchModalVisible = false;

  constructor(private apollo: Apollo, private msg: NzMessageService) { }

  ngOnInit(): void {
    this.branches$ = this.apollo.watchQuery({
      query: branchesQuery
    }).valueChanges.pipe(
      map(result => {
        let branches = result.data?.branches; //.map(branch => Object.assign({}, branch, { createdAt: new Date(branch.createdAt) }))
        return [...branches].sort((a, b) => b.id - a.id) // shorthand method to sort by created date since the id is incremented
      })
    );
  }

  getTypeLabel(type: string): string {
    return type.replace(/[_]/g, ' ')
  }

  addBranch(): void {
    this.branchFormVisible = true;
  }

  editBranch(branch: Branch): void {
    this.branchToEdit = branch;
    this.branchFormVisible = true;
  }

  onFormCancelled(): void {
    this.branchFormVisible = false;
    setTimeout(() => {
      this.branchToEdit = null;
    },1000)
  }

  onFormSubmitted(success: boolean): void {
      //TODO: Refactor code to show or hide form based on result status
      this.branchFormVisible = false;
      setTimeout(() => {
        this.branchToEdit = null;
      },1000)

  }

  viewBranchDetail(branch: Branch): void {
    this.branchToViewDetail = branch;
  }

  closeBranchDetail(): void {
    this.branchToViewDetail = null;
  }

  deleteBranch(branch: Branch): void {
    this.branchToDelete = branch;
    this.deleteBranchModalVisible = true;
  }

  onDeleteCancelled(): void {
    this.deleteBranchModalVisible = false;
    this.branchToDelete = null;
  }

  onDeleteConfirmed(): void {
    if (this.branchToDelete) {
      const deleteBranchSub = this.apollo.mutate({
        mutation: deleteBranchMutation,
        variables: {
          id: this.branchToDelete.id
        }
      }).subscribe(result => {
        const branchId = result.data?.deleteBranch.id;

        const data = this.apollo.client.cache.readQuery({query: branchesQuery});
        if (data) {

          const index = data.branches.findIndex(branch => branch.id === branchId);
          if (index > -1) {
            const branches = [...data.branches];
            branches.splice(index, 1);
            // Write our data back to the cache.
            this.apollo.client.writeQuery({ query: branchesQuery, data: { branches } });
          }
        }
        this.deleteBranchModalVisible = false;
        this.branchToDelete = null;
        this.msg.success('Branch deleted')
        deleteBranchSub.unsubscribe();
      }, err => {
        // this.isSubmitLoading = false;
        this.msg.error(err.message)
        console.error({err});
        deleteBranchSub.unsubscribe();
      })
    }
  }

}

export const branchesQuery = gql<{branches: Branch[]}, void>`
  query {
    branches {
      id,
      type,
      name,
      description,
      phoneNumbers,
      location {
        latitude,
        longitude,
        address,
      },
      employees {
        name,
        position,
        description,
        photo,
      }
      createdAt,
    }
  }
`
const deleteBranchMutation = gql<{ deleteBranch: Branch }, { id: number }>`
  mutation DeleteBranch($id: Int!) {
    deleteBranch(id: $id) {
      id,
    }
  }
`
