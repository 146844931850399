import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Apollo, gql } from 'apollo-angular';
import { NzUploadChangeParam, NzUploadFile } from 'ng-zorro-antd/upload';
import { NzMessageService } from 'ng-zorro-antd/message';
import { EMPTY, map, Observable } from 'rxjs';
import { Category, Product } from 'src/app/products/product';
import { environment } from 'src/environments/environment';
import { productsQuery } from '../product-list/product-list.component';

interface ProductInput {
  name: string;
  description: string;
  code: string;
  price: number;
  quantity: number;
  image: string;
  categoryId: number;
  discountId?: number | null
  discountType?: string | null;
  amount?: number | null;
}

export const categoriesQuery = gql<{ categories: Category[] }, void>`
    query Categories {
        categories {
            id,
            name
        }
    }
`
const addProductMutation = gql<{addProduct: Product}, { productData: ProductInput }>`
  mutation addProduct($productData: ProductInput!) {
    addProduct(productData: $productData) {
      id,
      name,
      description,
      code,
      price,
      quantity,
      image,
      category {
          id,
          name
      },
      discount{
          id,
          discountType,
          amount
      }
    }
  }
`;
const editProductMutation = gql<{editProduct: Product}, { id: number, productData: ProductInput}>`
  mutation editProduct($id: Int!, $productData: ProductInput! ) {
    editProduct(id: $id, productData: $productData) {
      id,
      name,
      description,
      code,
      price,
      quantity,
      image,
      category {
          id,
          name
      },
      discount{
          id,
          discountType,
          amount
      }
    }
  }
`;


@Component({
  selector: 'app-product-form',
  templateUrl: './product-form.component.html',
  styleUrls: ['./product-form.component.css']
})
export class ProductFormComponent implements OnInit {

  @Input() product: Product | null = null;
  discountType: any;
  discountAmount: any;

  categories$: Observable<Category[]> = EMPTY;
  categories: Category[] =  [
    {
        id: 1,
        name: 'Mugs',
        image: undefined,
    },
    {
        id: 2,
        name: 'Sublimation Machines',
        image: undefined,
    }
  ];

  form = this.fb.nonNullable.group({
    categoryId: ['', [ Validators.required,]],
    image: ['', [Validators.required]],
    code: ['' , [Validators.required, Validators.minLength(4)]],
    name: ['', [Validators.required]],
    price: ['', [Validators.required, Validators.min(1)]],
    quantity: ['', [Validators.required, Validators.min(0)]],
    description: ['',]
  })
  isSubmitLoading = false;

  imageUploading = false;
  fileList: NzUploadFile[] = [];
  onFileRemoved: (file: NzUploadFile) => boolean | Observable<boolean> = () => {
    this.form.controls.image.setValue('');
    return true;
  }

  @Output() formSubmitted: EventEmitter<boolean> = new EventEmitter();
  @Output() formCancelled: EventEmitter<void> = new EventEmitter();

  constructor(private fb: FormBuilder, private apollo: Apollo, private msg: NzMessageService) { }

  ngOnInit(): void {
    this.categories$ = this.apollo.watchQuery({
      query: categoriesQuery
    }).valueChanges.pipe(map(result => result.data?.categories));

    if (this.product) {
      this.form.setValue({
        categoryId: ""+ this.product.category.id,
        image: this.product.image,
        code: this.product.code,
        name: this.product.name,
        price: "" + this.product.price,
        quantity: "" + this.product.quantity,
        description: this.product.description || '',
      });
      this.discountType = this.product.discount?.discountType;
      this.discountAmount = this.product.discount?.amount;
      this.fileList = [
        {
          uid: '1',
          name: this.product.name,
          status: 'done',
          url: this.product.image,
        }
      ]
    }
  }

  handleChange(e: NzUploadChangeParam): void {
    switch (e.file.status) {
      case 'uploading':
        this.imageUploading = true;
        break;
      case 'done':
        // Get this url from response.
        this.imageUploading = false;
        const response = e.file.response
        if (response && response.success) {
          this.form.controls.image.setValue(response.url);
        }
        break;
      case 'error':
        this.msg.error('Network error');
        this.imageUploading = false;
        break;
    }
  }

  onSubmit(): void {
    this.isSubmitLoading = true;
    if (this.form.invalid) {
      this.isSubmitLoading = false;
       return;
    }
    // Add or edit Product
    const controls = this.form.controls;
    // Edit Product
    if (this.product) {
      const editProductSub = this.apollo.mutate({
        mutation: editProductMutation,
        variables: {
          id: this.product.id,
          productData: {
            categoryId: +controls.categoryId.value,
            code: controls.code.value,
            description: controls.description.value,
            image: controls.image.value,
            name: controls.name.value,
            price: +controls.price.value,
            quantity: +controls.quantity.value,
            discountId: this.product.discount?.id ? this.product.discount?.id : null,
            discountType: this.discountType,
            amount: +this.discountAmount
          }
        },
      }).subscribe(result => {
        const product = result.data?.editProduct;
        this.isSubmitLoading = false;
        this.formSubmitted.emit(true);
        this.msg.success('Product edited')
        editProductSub.unsubscribe();
      }, err => {
        this.isSubmitLoading = false;
        this.msg.error(err.message)
        console.error({err});
        editProductSub.unsubscribe();
      })
      return
    }
    const addProductSub = this.apollo.mutate({
      mutation: addProductMutation,
      variables: {
        productData: {
          categoryId: +controls.categoryId.value,
          code: controls.code.value,
          description: controls.description.value,
          image: controls.image.value,
          name: controls.name.value,
          price: +controls.price.value,
          quantity: +controls.quantity.value,
          discountType: this.discountType,
          amount: +this.discountAmount
        }
      },
      update: (store, result) => {
        // Read the data from our cache for this query.
        const data = store.readQuery<any>({ query: productsQuery });

        // Add our product from the mutation to the end.
        const products = [...data.products, result.data?.addProduct];

        // Write our data back to the cache.
        store.writeQuery({ query: productsQuery, data: {products} });
      }
    }).subscribe(result => {
      const product = result.data?.addProduct;
      this.isSubmitLoading = false;
      this.formSubmitted.emit(true);
      this.msg.success('Product Added');
      addProductSub.unsubscribe();
    }, err => {
      this.isSubmitLoading = false;
      this.msg.error(err.message)
      console.error({err});
      addProductSub.unsubscribe();
    })



    // setTimeout(() => {
    //   this.isSubmitLoading = false;
    //   this.formSubmitted.emit(true);
    // }, 3000)
  }

  onCancel(): void {
    this.formCancelled.emit();
  }

  getUploadUrl(): string {
    return environment.baseUrl + 'upload'
  }

  getHeaders() {
    return {
      Source: 'products'
    }
  }
}
